// src/pages/profile/ProfilePage.js

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MainLayout from "../../layouts/MainLayout";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import axiosInstance from "../../lib/axiosInstance";
import { useAuthState } from "../../context/AuthContext";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";
import { Outlet } from "react-router-dom";
// import Tabs from "../../components/Tabs/Tabs";
import TabsFull from "../../components/Tabs/TabsFull";
import ImageWithFallback from "../../components/Image/ImageWithFallback";
import {
  CreditCardIcon,
  UserCircleIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import CarerEPSCardModal from "../../components/Modals/CarerEPSCardModal";

import { formatDateNoDayName } from "../../utils/dateFuncs";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";

const ProfilePage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const memberLanguage = i18n.language;
  const { setUser } = useAuthState();
  const [inProgress, setInProgress] = useState(false);
  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // Member
  const [member, setMember] = useState();
  // Loading
  const [loading, setLoading] = useState();

  // EPS Card modal
  const [isOpen, setIsOpen] = useState(false);

  // Password change state
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [serverPasswordMessage, setServerPasswordMessage] = useState({
    message: "",
    type: "",
  });

  const profileTabs = {
    events: {
      path: "/my-carereps/events",
      name: t("common:profile.events"),
    },
    bookmarks: {
      path: "/my-carereps/bookmarks",
      name: t("common:profile.bookmarks"),
    },
  };

  // State to manage the selected tab
  const [selectedTab, setSelectedTab] = useState(profileTabs.events);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  //////
  // Handle submit password
  const handleSubmitPassword = async (data) => {
    try {
      setSubmittingPassword(true);
      // set message while submitting
      manageServerMessage(setServerPasswordMessage, "Updating", "saving", 0);

      // Data contains old and new password
      const formData = {
        oldPassword: data.oldPassword,
        password: data.password,
      };

      const response = await axiosInstance.put(
        endPoint.MEMBER_PASSWORD_CHANGE,
        formData,
        {
          headers: {
            "Accept-Language": memberLanguage, // Set Accept-Language header
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        manageServerMessage(
          setServerPasswordMessage,
          response.data.message,
          "success"
        );
      } else {
        // Handle non-success status with proper message
        manageServerMessage(
          setServerPasswordMessage,
          response.data.message,
          "error",
          2000
        );
      }
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message ||
          t("errors:fetchError", { entity: t("common:entities.password") });
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(
        setServerPasswordMessage,
        errorMessage,
        "error",
        2000
      );
    } finally {
      setSubmittingPassword(false);
    }
  };

  // LOG OUT
  const handleUserLogout = async () => {
    setInProgress(true);
    try {
      const response = await axiosInstance.get(endPoint.LOGOUT, {
        withCredentials: true,
      });

      if (response.status === 200) {
        // Logout successful
        // Used to avoid re log in if user refreshes the page
        localStorage.setItem("isLoggedInCarerEPS", "false");
        setUser(null); // Set user to null in context
        manageServerMessage(setServerMessage, response.data.message, "success");
        navigate("/");
      } else {
        // Handle non-success status with proper message
        manageServerMessage(
          setServerMessage,
          response.data.message,
          "error",
          2000
        );
      }
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 2000);
    } finally {
      setInProgress(false);
    }
  };

  //////
  // Fetch member (user events)
  const fetchMember = async () => {
    try {
      const response = await axiosInstance.get(endPoint.MEMBER, {
        withCredentials: true,
      });

      // Items fetched
      const fetchedItem = response.data.data.member;

      setMember(fetchedItem);
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message ||
          t("errors:fetchError", { entity: t("common:entities.member") });
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 0);
    } finally {
      setLoading(false);
    }
  };

  // First load of member (on mount)
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      fetchMember();
    }
    // eslint-disable-next-line
  }, []);

  const handleCardModalOpen = () => {
    setIsOpen(true);
  };

  const handlePasswordChangeModalOpen = () => {
    setIsPasswordChangeOpen(true);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:navigationMenu.myCarereps")} -{" "}
          {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainLayout>
        <CarerEPSCardModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          member={member}
        />
        <ChangePasswordModal
          isOpen={isPasswordChangeOpen}
          setIsOpen={setIsPasswordChangeOpen}
          serverMessage={serverPasswordMessage}
          submitting={submittingPassword}
          handleOnSubmit={handleSubmitPassword}
        />
        {/* Main container (includes event info and summary column) */}
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-0 lg:mt-0">
          {/* Event Information Container */}
          <div className="px-3 lg:px-5 mb-8 flex-grow">
            {/* Navigation tabs */}
            <TabsFull
              tabs={profileTabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />

            <div className="mt-4 h-full">
              {/* Outlet for nested routes */}
              <Outlet />
            </div>
          </div>

          {/* Side Panel: Member information */}

          <div className="w-full lg:w-96 shrink-0 mb-8 px-4 lg:px-0">
            {/* Image Container */}
            <div
              className="relative overflow-hidden rounded-2xl flex-shrink-0 cursor-pointer shadow-md"
              onClick={handleCardModalOpen}>
              <ImageWithFallback
                src={"/assets/images/carereps_card.jpg"}
                className="object-cover w-full"
              />
              {/* Info container */}
              <div className="w-[80%] flex flex-col absolute top-[30%] sm:top-[28%] pl-4">
                <div className="mx-auto flex flex-col">
                  <span className="text-slate-900 font-semibold text-2xl md:text-3xl lg:text-2xl">
                    {member && member.lastName + member.firstName}
                  </span>

                  <div className="flex flex-col -space-y-1 md:space-y-0">
                    {member && member.membershipStartDate && (
                      <div>
                        <span className="text-slate-900 font-medium text-sm md:text-xl lg:text-base">
                          發咭日期 Issue Date:
                        </span>
                        <span className="text-slate-900 font-medium text-sm md:text-xl lg:text-base">
                          {" "}
                          {formatDateNoDayName(
                            member.membershipStartDate,
                            i18n.language
                          )}
                        </span>
                      </div>
                    )}
                    {member && member.membershipId && (
                      <div>
                        <span className="text-slate-900 font-medium text-sm md:text-xl lg:text-base">
                          易達咭編號 ID#:
                        </span>
                        <span className="text-slate-900 font-medium text-sm md:text-lg">
                          {" "}
                          {member.membershipId}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Container with member information */}
            <div className="lg:sticky lg:top-36 mt-6 overflow-hidden border border-slate-100 bg-slate-100/30 rounded-2xl pb-6">
              {member && (
                <Fragment>
                  {/* Account details */}
                  <div className="text-left px-6 py-6 font-semibold text-lg  capitalize">
                    {t("common:profile.accountDetails")}
                  </div>

                  <div className="px-6 gap-3 flex flex-col">
                    {/* Card */}
                    <div className="flex gap-3 items-center">
                      <CreditCardIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {member.membershipId}
                      </span>
                    </div>
                    {/* Name */}
                    <div className="flex gap-3 items-center">
                      <UserCircleIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {member.lastName + member.firstName}
                      </span>
                    </div>
                    {/* Phone */}

                    {/* Email */}
                    {member.email && (
                      <div className="flex gap-3 items-center">
                        <EnvelopeIcon className="w-7 h-7 text-slate-600" />
                        <span className="text-slate-600">{member.email}</span>
                      </div>
                    )}
                  </div>
                  {/* Separator */}
                  <div>
                    <hr className="h-px bg-slate-200 border-0 my-6"></hr>
                  </div>

                  {/* Member memberships */}
                  <div className="text-left px-6 font-semibold text-lg  capitalize">
                    {t("common:profile.orgMemberships")}
                  </div>

                  <ul className="flex flex-col gap-2 px-6 mt-4">
                    {member.organizations.map((org, index) => (
                      <li key={index} className="text-slate-600 flex flex-col">
                        <span className="text-slate-700 font-medium text-lg">
                          {org.name}
                        </span>
                        <span className="text-slate-500">
                          {org.membershipId}
                        </span>
                      </li>
                    ))}
                  </ul>
                  {/* Separator */}
                  <div>
                    <hr className="h-px bg-slate-200 border-0 my-6"></hr>
                  </div>

                  {/* Reset password */}
                  <div className="text-left px-6 font-semibold text-lg flex justify-between capitalize">
                    <span>{t("common:profile.resetPassword")}</span>
                    <PencilSquareIcon
                      className="w-7 h-7 text-slate-400 cursor-pointer hover:text-slate-600"
                      onClick={handlePasswordChangeModalOpen}
                    />
                  </div>

                  {/* Separator */}
                  <div>
                    <hr className="h-px bg-slate-200 border-0 my-6"></hr>
                  </div>

                  <div className="px-6">
                    <button
                      onClick={handleUserLogout}
                      className="transition capitalize duration-300 w-full cursor-pointer bg-red-200 hover:bg-red-200 text-red-700 py-1 px-4 rounded-full border-solid border-2 border-red-400/40 hover:border-red-300">
                      {t("common:profile.logout")}
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
};

export default ProfilePage;
