// src/pages/diclaimer/DisclaimerPage.js

import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import FullWidthLayout from "../../layouts/FullWidthLayout";
import { useTranslation } from "react-i18next";
import { endPoint } from "../../constants/endPoints";
import axiosInstance from "../../lib/axiosInstance";
import { manageServerMessage } from "../../utils/messageUtils";
import DisclaimerContent from "./DisclaimerContent";
import ReactGA from "react-ga4";

const DisclaimerPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // To keep track of errors when connecting to the API

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  //////
  // Fetch events function
  const fetchPage = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(endPoint.DISCLAIMER_PAGE);

      setPage(response.data.data.page);
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setErrorMessage, errorMessage, "error", 2000);
    } finally {
      setLoading(false);
    }
  };

  // First load of page (on mount only)
  useEffect(() => {
    if (!loading) {
      fetchPage();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="This is the privacy policy page" />
      </Helmet>
      <FullWidthLayout>
        <DisclaimerContent
          page={page}
          errorMessage={errorMessage}
          loading={loading}
        />
      </FullWidthLayout>
    </Fragment>
  );
};

export default DisclaimerPage;
