// src/context/EventFilterDataContext.js

import { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { endPoint } from "../constants/endPoints";
import axiosInstance from "../lib/axiosInstance";

// Carer Type Context
import { useCarerTypeState } from "./CarerTypeContext";

const EventFilterDataContext = createContext();

const EventFilterDataProvider = ({ children }) => {
  ///////
  // Carer Type context data (used for current event filter options)
  const { selectedCarerTypes } = useCarerTypeState();

  const { t, i18n } = useTranslation();

  const [eventFilterData, setEventFilterData] = useState(null);
  const [lastFetched, setLastFetched] = useState(0);

  const [fetchedData, setFetchedData] = useState(null);

  const [isLoadingEventFilterData, setIsLoadingEventFilterData] =
    useState(false);
  const [error, setError] = useState(null);
  const [eventFilterDataReady, setEventFilterDataReady] = useState(false);

  // State for user selections
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);

  // Current filter (collects all the selected filter options - to be used when submitting for new search)
  const [currentFilter, setCurrentFilter] = useState(null);
  // State to safe latest event "search" filter
  const [prevFilter, setPrevFilter] = useState(null);

  ///////
  // Functions to generate dropdown/radio btns for dates
  const getDateOptions = () => {
    // Initialize options array with the "All" option
    const options = [{ id: "all", name: t("common:dropdown.all") }];

    // Function to get the month name based on offset
    const getMonthName = (offset) => {
      const date = new Date();
      date.setMonth(date.getMonth() + offset);
      return date.toLocaleString(
        i18n.language === "tc" ? "zh-TW" : i18n.language,
        {
          month: "short",
        }
      );
    };

    // Add current, next, and the month after next to options
    for (let offset = 0; offset <= 2; offset++) {
      options.push({
        id: offset.toString(), // Use offset as id
        name: getMonthName(offset), // Get localized month name based on offset
      });
    }

    return options;
  };

  const dateOptions = getDateOptions();

  useEffect(() => {
    setSelectedDate(dateOptions[0]);
    // eslint-disable-next-line
  }, []);

  ///////
  // Event Mode options
  // const eventMode = [
  //   {
  //     id: "all",
  //     name: "All",
  //   },
  //   {
  //     id: "offline",
  //     name: "Offline",
  //   },
  //   {
  //     id: "online",
  //     name: "Online",
  //   },
  // ];
  const eventMode = [
    {
      id: "all",
      name: t("common:dropdown.all"),
    },
    {
      id: "offline",
      name: t("common:generic.offline"),
    },
    {
      id: "online",
      name: t("common:generic.online"),
    },
  ];

  useEffect(() => {
    setSelectedMode(eventMode[0]);
    // eslint-disable-next-line
  }, []);

  const getCurrentFilterOptions = () => {
    const filter = {};

    // Check if Category is selected and not equal to 'all'
    if (selectedCategory && selectedCategory.id !== "all") {
      filter.categoryId = selectedCategory.id;
    }

    // Check if District is selected and not equal to 'all'
    if (selectedLocation && selectedLocation.id !== "all") {
      filter.districtId = selectedLocation.id;
    }

    // Check if Organization is selected and not equal to 'all'
    if (selectedOrganization && selectedOrganization.id !== "all") {
      filter.organizationId = selectedOrganization.id;
    }

    // Check if Date is selected and not equal to 'all'
    if (selectedDate && selectedDate.id !== "all") {
      filter.date = selectedDate.id;
    }

    // Check if Mode is selected and not equal to 'all'
    if (selectedMode && selectedMode.id !== "all") {
      filter.mode = selectedMode.id;
    }

    // Check if Carer Type is selected and not null
    if (selectedCarerTypes && selectedCarerTypes.length > 0) {
      // Include carerType only when it's an array of values
      filter.carerTypeIds = selectedCarerTypes.map((type) => type.id).join(",");
    }

    return filter;
  };

  const clearCurrentFilter = () => {
    setCurrentFilter({});
    setSelectedCategory(eventFilterData.categories[0]);
    setSelectedOrganization(eventFilterData.organizations[0]);
    setSelectedLocation(eventFilterData.districts[0]);
    setSelectedDate(eventFilterData.dates[0]);
    setSelectedMode(eventFilterData.modes[0]);
  };

  // Function to update currentFilter when any of the fields is changed
  useEffect(() => {
    const newFilter = getCurrentFilterOptions();
    setCurrentFilter(newFilter);
    // eslint-disable-next-line
  }, [
    selectedCategory,
    selectedLocation,
    selectedOrganization,
    selectedDate,
    selectedMode,
  ]);

  const allOption = { id: "all", name: t("common:dropdown.all") };

  // Function to simplify and localize data
  const localizeData = (data, enFieldName, tcFieldName) =>
    data.map((item) => ({
      id: item.id,
      name: i18n.language === "en" ? item[enFieldName] : item[tcFieldName],
    }));

  // Fetch Event Filter Data manually
  const fetchEventFilterData = async () => {
    if (!eventFilterData || Date.now() - lastFetched > 3600000) {
      // refresh data if it does not exist or it is older than 1 hour
      setIsLoadingEventFilterData(true);
      try {
        const response = await axiosInstance.get(endPoint.EVENT_FILTERS);
        const { districts, categories, organizations } =
          response.data.data?.filterData;

        setFetchedData(response.data.data?.filterData);

        // Set all the data individually (category , location , organization)
        setSelectedCategory(selectedCategory || allOption);
        setSelectedLocation(selectedLocation || allOption);
        setSelectedOrganization(selectedOrganization || allOption);

        setCurrentFilter(getCurrentFilterOptions());
        setPrevFilter(getCurrentFilterOptions());

        // Localize organization names and add additional fields
        const localizedOrganizations = organizations.map((org) => ({
          id: org.id,
          name: i18n.language === "en" ? org.shortName : org.name, // Localize name
          shortName: org.shortName,
          englishName: org.englishName,
          description: org.description,
          logo: org.logo,
        }));

        setEventFilterData({
          districts: [
            allOption,
            ...localizeData(districts, "englishName", "name"),
          ],
          categories: [
            allOption,
            ...localizeData(categories, "englishName", "name"),
          ],
          // organizations: [
          //   allOption,
          //   ...localizeData(organizations, "shortName", "shortName"),

          // ],
          organizations: [allOption, ...localizedOrganizations],
          dates: dateOptions,
          modes: eventMode,
        });

        setLastFetched(Date.now());
      } catch (error) {
        console.error("Failed to fetch data:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //  AXIOS ERROR
          setError(error.response.statusText);
        } else if (error.request) {
          // The request was made but no response was received
          setError(t("errors:networkError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(t("errors:unexpectedError"));
        }
      } finally {
        setIsLoadingEventFilterData(false);
      }
    }
  };

  // Use effect to define when the current filter and the data have content already
  // After setting eventFilterData and initial selections:
  useEffect(() => {
    if (
      !isLoadingEventFilterData &&
      eventFilterData &&
      selectedCategory &&
      selectedLocation &&
      selectedOrganization &&
      selectedDate &&
      selectedMode &&
      currentFilter
    ) {
      setEventFilterDataReady(true);
    } else {
      setEventFilterDataReady(false);
    }
  }, [
    isLoadingEventFilterData,
    eventFilterData,
    selectedCategory,
    selectedLocation,
    selectedOrganization,
    selectedDate,
    selectedMode,
    currentFilter,
  ]);

  // const updateLocalizedSelections = (setter, localizedData) => {
  //   setter((prevSelected) => {
  //     if (!prevSelected) return null;
  //     const newSelection = localizedData.find((d) => d.id === prevSelected.id);
  //     return newSelection || prevSelected;
  //   });
  // };
  const updateLocalizedSelections = (
    setter,
    localizedData,
    fieldToMatch = "id"
  ) => {
    setter((prevSelected) => {
      if (!prevSelected) return null;
      const newSelection = localizedData.find(
        (d) => d[fieldToMatch] === prevSelected[fieldToMatch]
      );
      return newSelection || prevSelected;
    });
  };

  useEffect(() => {
    if (fetchedData) {
      const localizedDistricts = localizeData(
        fetchedData.districts,
        "englishName",
        "name"
      );
      const localizedCategories = localizeData(
        fetchedData.categories,
        "englishName",
        "name"
      );
      // const localizedOrganizations = localizeData(
      //   fetchedData.organizations,
      //   "shortName",
      //   "shortName"
      // );
      const localizedOrganizations = fetchedData.organizations.map((org) => ({
        id: org.id,
        name: i18n.language === "en" ? org.shortName : org.name,
        shortName: org.shortName,
        englishName: org.englishName,
        description: org.description,
        logo: org.logo,
      }));

      // Update your state with the newly localized data
      setEventFilterData({
        districts: [allOption, ...localizedDistricts], // Preserving the "All" option
        categories: [allOption, ...localizedCategories],
        organizations: [allOption, ...localizedOrganizations],
        dates: dateOptions,
        modes: eventMode,
      });

      // Update selected options to correct language
      updateLocalizedSelections(setSelectedLocation, localizedDistricts);
      updateLocalizedSelections(setSelectedCategory, localizedCategories);
      // updateLocalizedSelections(
      //   setSelectedOrganization,
      //   localizedOrganizations
      // );
      updateLocalizedSelections(
        setSelectedOrganization,
        localizedOrganizations,
        "id"
      );
      updateLocalizedSelections(setSelectedDate, dateOptions);
      updateLocalizedSelections(setSelectedMode, eventMode);
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <EventFilterDataContext.Provider
      value={{
        isLoadingEventFilterData,
        eventFilterData,
        currentFilter,
        fetchEventFilterData,
        eventFilterDataReady,
        error,
        selectedCategory,
        setSelectedCategory,
        selectedLocation,
        setSelectedLocation,
        selectedOrganization,
        setSelectedOrganization,
        selectedDate,
        setSelectedDate,
        selectedMode,
        setSelectedMode,
        clearCurrentFilter,
        prevFilter,
        setPrevFilter,
      }}>
      {children}
    </EventFilterDataContext.Provider>
  );
};

export { EventFilterDataContext, EventFilterDataProvider };
