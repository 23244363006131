import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { namespaces } from "./constants/i18nNamespaces";

(async () => {
  try {
    await i18n
      .use(HttpApi)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(
        {
          ns: namespaces,
          supportedLngs: ["en", "tc", "zh-TW", "zh-HK"],
          fallbackLng: "tc",
          preload: ["en", "tc"],
          whitelist: ["en", "tc"],
          debug: false,

          detection: {
            order: [
              "querystring",
              "cookie",
              "localStorage",
              "navigator",
              "htmlTag",
              "path",
              "subdomain",
            ],
            lookupCookie: "i18next",
            caches: ["cookie"],
            checkWhitelist: true,
          },

          // Specify backend loadPath for dynamic loading
          backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
          },
        },
        (err, t) => {
          if (err) return console.error(err);
          // console.log("i18next initialized");
        }
      );

    // Optional callback after initialization
  } catch (err) {
    console.error("Error loading translations:", err);
  }
})();

// Automatically adjust language if it starts with "zh-"
i18n.on("initialized", (options) => {
  const detectedLanguage = i18n.language;
  if (detectedLanguage.startsWith("zh-")) {
    i18n.changeLanguage("tc"); // or 'zh-TW'
  }
});

export default i18n;
