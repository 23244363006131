// src/components/Blocks/PageBannerBlock.js

import { useTranslation } from "react-i18next";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import ImageWithFallback from "../Image/ImageWithFallback";

const PageBannerBlock = ({ content, settings, imageLeft = true }) => {
  const { i18n } = useTranslation();
  // General settings
  const generalClasses = generateTailwindClasses(settings);

  const renderedContent = [];
  //
  if (content.subtitle) {
    const subtitleClasses = generateTailwindClasses(content.subtitle.settings);
    renderedContent.push(
      <div key="subtitle" className={`uppercase ${subtitleClasses}`}>
        {i18n.language === "en"
          ? content.subtitle.contentEnglish || content.subtitle.content
          : content.subtitle.content}
      </div>
    );
  }
  if (content.title) {
    const titleClasses = generateTailwindClasses(content.title.settings);
    renderedContent.push(
      <div key="title" className={`leading-tight ${titleClasses}`}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "en"
                ? content.title.contentEnglish || content.title.content
                : content.title.content,
          }}
        />
      </div>
    );
  }
  if (content.paragraph) {
    const paragraphClasses = generateTailwindClasses(
      content.paragraph.settings
    );
    renderedContent.push(
      <div key="paragraph" className={paragraphClasses}>
        {i18n.language === "en"
          ? content.paragraph.contentEnglish || content.paragraph.content
          : content.paragraph.content}
      </div>
    );
  }

  return (
    <div className={`mx-auto max-w-2xl lg:max-w-7xl ${generalClasses}`}>
      <div className={`flex flex-col lg:flex-row lg:w-full lg:gap-x-8`}>
        <div
          className={`flex flex-col w-12/12 lg:w-5/12 justify-end ${
            !imageLeft && "order-last"
          }`}>
          <ImageWithFallback
            src={content.image.url}
            alt={content.title.content}
            className={`${generateTailwindClasses(
              content.image.settings
            )} object-cover object-top`}
          />
        </div>
        <div
          className={`flex flex-col justify-start w-12/12 lg:w-7/12 px-2 lg:px-0 mt-8 lg:mt-0 ${
            imageLeft ? "lg:pr-12 lg:pl-8" : "lg:pl-12 lg:pr-8"
          }`}>
          {/* Render content if exists */}
          {renderedContent}
        </div>
      </div>
    </div>
  );
};

export default PageBannerBlock;
