// src/constants/blocks.js

const blocks = {
  SECTION: "section",
  TEXT: "text",
  TEXT_PARAGRAPH: "textParagraph",
  IMAGE_TEXT: "imageText",
  IMAGE: "image",
  BUTTON: "button",
  DIVIDER: "divider",
  DYNAMIC: "dynamic",
  HIGHLIGHTS_GRID: "highlightsGrid",
  IMAGE_HIGHLIGHTS: "imageHighlights",
  LOGO_GRID: "logoGrid",
  TEXT_BUTTON: "textButton",
  IMAGE_HIGHLIGHTS_WBUTTON: "imageHighlightsWButton",
  TEXT_PARAGRAPH_LOGO_GRID: "textParagraphLogoGrid",
  PAGES_BANNER: "pagesBanner",
  YOUTUBE: "youtube",
  IMAGE_SOLO: "imageSolo",
  PARAGRAPH: "paragraph",
};

export { blocks };
