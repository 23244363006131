// src/components/Icons/ClockOutlinedIcon.js

import React from "react";

const ClockOutlinedIcon = ({
  color = "#3C4146",
  size = "22",
  className = "",
  alt = "icon",
}) => (
  <svg
    className={className}
    role="img"
    aria-label={alt}
    width={size + "px"} // Ensure size is correctly applied
    height={size + "px"} // Same for height
    viewBox="0 0 46 46" // Adjust viewBox if necessary
    fill={color}
    focusable="false" // Prevent SVG from being focusable in IE
    style={{ outline: "none" }} // CSS to ensure it's not focusable in other browsers
    xmlns="http://www.w3.org/2000/svg">
    <path d="M22.9733 43.5505C11.6215 43.5505 2.39453 34.3236 2.39453 22.9718C2.39453 11.62 11.6215 2.39307 22.9733 2.39307C34.3251 2.39307 43.552 11.62 43.552 22.9718C43.552 34.3236 34.3251 43.5505 22.9733 43.5505ZM22.9733 5.26452C13.2103 5.26452 5.26598 13.2089 5.26598 22.9718C5.26598 32.7347 13.2103 40.6791 22.9733 40.6791C32.7362 40.6791 40.6806 32.7347 40.6806 22.9718C40.6806 13.2089 32.7362 5.26452 22.9733 5.26452Z" />
    <path d="M30.0746 30.4951C29.8257 30.4951 29.5769 30.4376 29.3472 30.2845L23.4128 26.743C21.9388 25.8625 20.8477 23.929 20.8477 22.2253V14.3766C20.8477 13.5918 21.4985 12.9409 22.2834 12.9409C23.0682 12.9409 23.7191 13.5918 23.7191 14.3766V22.2253C23.7191 22.9144 24.2934 23.929 24.8868 24.2736L30.8212 27.815C31.5103 28.217 31.7209 29.0976 31.3189 29.7868C31.0317 30.2462 30.5532 30.4951 30.0746 30.4951Z" />
  </svg>
);
export default ClockOutlinedIcon;
