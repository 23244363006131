// src/components/Modals/CarerSelectionModal.js

import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { animated, useTransition } from "react-spring";

import { useTranslation } from "react-i18next";
// import { manageServerMessage } from "../../utils/messageUtils";
// import { handleRedirect } from "../../utils/navigationUtils";
// import axiosInstance from "../../lib/axiosInstance";
// import { endPoint } from "../../constants/endPoints";
import { useNavigate } from "react-router-dom";
import { useCarerTypeState } from "../../context/CarerTypeContext";
import GraphicCheckbox from "../Checkboxes/GraphicCheckboxes";
import CarerLevelRadio from "../RadioButtons/CarerLevelRadio";
import { navigationURL } from "../../constants/navigation";

const CarerSelectionModal = ({
  isOpen,
  setIsOpen,
  classes = "w-full md:max-w-3xl lg:max-w-5xl",
  //   confirmSubmit,
  //   serverMessage,
  //   submitting,
}) => {
  // or the id of the session if eventType (registrationType) is section
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward

  const transitions = useTransition(page, {
    from: {
      opacity: 0,
      transform: `translateX(${direction === 1 ? "100%" : "-100%"})`,
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: {
      opacity: 0,
      transform: `translateX(${direction === 1 ? "-100%" : "100%"})`,
    },
    config: { tension: 300, friction: 28 },
  });

  const { carerTypeData, selectedCarerTypes, validateAndSetCarerTypes } =
    useCarerTypeState();

  const [localSelectedCarerTypes, setLocalSelectedCarerTypes] = useState([]);

  // Populate localSelectedCarerTypes when selectedCarerTypes is available
  useEffect(() => {
    if (selectedCarerTypes) {
      setLocalSelectedCarerTypes(selectedCarerTypes);
    }
  }, [selectedCarerTypes]);

  // Modal
  const cancelButtonRef = useRef(null);

  const handleCloseModal = () => {
    setIsOpen(false);

    const intervalId = setInterval(() => {
      setDirection(0);
      setPage(0);
      clearInterval(intervalId);
    }, 800);
  };

  const handleCheckboxChange = (id) => {
    if (localSelectedCarerTypes.includes(id)) {
      setLocalSelectedCarerTypes(
        localSelectedCarerTypes.filter((item) => item !== id)
      );
    } else {
      setLocalSelectedCarerTypes([...localSelectedCarerTypes, id]);
    }
  };

  const handleTriggerFilter = () => {
    validateAndSetCarerTypes(localSelectedCarerTypes);
    navigate(navigationURL.CARER_SUPPORT);
    setIsOpen(false);
  };

  const Page1 = ({ onNext }) => {
    return (
      <div className="p-12 flex flex-col h-full items-center justify-between overflow-auto">
        <div className="mb-8 text-center">
          <h2 className="text-xl font-medium mb-1">
            {t("common:carerModal.typeHeader")}
          </h2>
          <span className="text-sm text-slate-600">
            {t("common:carerModal.selectMultiple")}
          </span>
        </div>

        <div className="grid gap-5 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
          {carerTypeData &&
            carerTypeData.map((item, index) => (
              <GraphicCheckbox
                key={index}
                id={item.id}
                label={item.name}
                checked={localSelectedCarerTypes.includes(item.id)}
                onChange={() => handleCheckboxChange(item.id)}
                imageSrc={item.image}
              />
            ))}
        </div>

        <button
          disabled={localSelectedCarerTypes.length === 0}
          className={`
          ${
            localSelectedCarerTypes.length === 0
              ? "bg-slate-400"
              : "bg-primary hover:bg-primary-hover"
          }
          mt-10 transition capitalize duration-300 text-center text-white py-2 px-4 rounded-full
          `}
          onClick={onNext}>
          {t("common:carerModal.next")}
        </button>
      </div>
    );
  };

  const Page2 = ({ onPrevious, onNext }) => {
    return (
      <div className="p-12 flex flex-col h-full items-center justify-between overflow-auto">
        <div className="mb-8 text-center">
          <h2 className="text-xl font-medium mb-1">
            {t("common:carerModal.carerLevel")}
          </h2>
          <span className="text-sm text-slate-600">
            {t("common:carerModal.pickOne")}
          </span>
        </div>

        <CarerLevelRadio />

        <div className="flex flex-row gap-5">
          <button
            className="mt-10 capitalize transition duration-300 bg-slate-400 text-center hover:bg-slate-500 text-white py-2 px-4 rounded-full"
            onClick={onPrevious}>
            {t("common:carerModal.previous")}
          </button>
          <button
            className="mt-10 capitalize transition duration-300 text-center text-white py-2 px-4 rounded-full bg-primary hover:bg-primary-hover"
            onClick={handleTriggerFilter}>
            {t("common:buttons.filter")}
          </button>
        </div>
      </div>
    );
  };

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
    setDirection(1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => prevPage - 1);
    setDirection(-1);
  };

  const pages = [
    <Page1 onNext={handleNext} />,
    <Page2 onPrevious={handlePrevious} onNext={handleNext} />,
  ];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative h-[800px] transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 ${classes}`}>
                <div className="flex flex-col">
                  {transitions((props, index) => (
                    <animated.div style={props}>{pages[index]}</animated.div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CarerSelectionModal;
