// src/components/Modals/ForgotPasswordModal.js

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useTranslation } from "react-i18next";

const ForgotPasswordModal = ({
  isOpen,
  setIsOpen,
  classes = "w-full sm:max-w-2xl",
}) => {
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();

  // Modal
  const cancelButtonRef = useRef(null);

  const handleCloseModel = () => {
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 ${classes}`}>
                <div>
                  {/* Header */}
                  <h1 className="p-6 text-center text-primary bg-primary/10 font-medium text-lg capitalize">
                    {t("common:form.forgotPassword")}
                  </h1>
                  <hr className="h-px bg-slate-200/30 border-0"></hr>

                  {/* Info container */}
                  <div className="py-6 px-12 flex flex-col ">
                    <div className="flex flex-col gap-10 overflow-y-scroll">
                      <div>
                        <p className="text-slate-600 font-normal text-lg mt-2">
                          {t("common:form.forgotPasswordCopy")}
                        </p>
                      </div>
                      <div>
                        <span className="text-slate-900 font-semibold text-lg">
                          {t("common:footer.secretariat")}：
                        </span>
                        <div className="flex flex-col mt-4">
                          <span className="text-slate-600 font-normal text-lg">
                            {t("common:footer.email")}: carereps@bokss.org.hk
                          </span>
                          <span className="text-slate-600 font-normal text-lg">
                            {t("common:footer.telephone")}: +852 3188 1633
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ForgotPasswordModal;
