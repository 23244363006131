// src/components/CarerSupport/CarerFilterPopUp.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

// import { useAuthState } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import ImageWithFallback from "../Image/ImageWithFallback";
import { useCarerTypeState } from "../../context/CarerTypeContext";

// import ReservePopUpContentLogin from "./ReservePopUpContentLogin";
// import ReservePopUpContentSummary from "./ReservePopUpContentSummary";
// import ReservePopUpContentResult from "./ReservePopUpContentResult";

const CarerFilterPopUp = ({ isOpen, setIsOpen }) => {
  // id would correspond to either the event id for Single and full registration
  // or the id of the session if eventType (registrationType) is section
  const { t } = useTranslation();
  const { carerTypeData, selectedCarerTypes, validateAndSetCarerTypes } =
    useCarerTypeState();

  const [localSelectedCarerTypes, setLocalSelectedCarerTypes] = useState([]);

  const cancelButtonRef = useRef(null);

  // Handle checkbox change
  const handleCarerTypeChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setLocalSelectedCarerTypes([...localSelectedCarerTypes, value]);
    } else {
      setLocalSelectedCarerTypes(
        localSelectedCarerTypes.filter((type) => type !== value)
      );
    }
  };

  // Handle update filter
  const handleUpdateFilter = () => {
    validateAndSetCarerTypes(localSelectedCarerTypes);
    setIsOpen(false);
  };

  // Handle cancel click
  const handleCancelClick = () => {
    setIsOpen(false);
  };

  // Populate localSelectedCarerTypes when selectedCarerTypes is available
  useEffect(() => {
    if (selectedCarerTypes) {
      setLocalSelectedCarerTypes(selectedCarerTypes);
    }
  }, [selectedCarerTypes]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-xl md:max-w-3xl ">
                {/* Banner */}

                <ImageWithFallback
                  src={"/assets/images/carer_filter.jpg"}
                  alt={"Login image"}
                  className="w-full h-52 sm:h-64 lg:h-72 object-cover object-top"
                />

                <div className="bg-primary/10 capitalize p-6 text-slate-800 font-semibold text-lg text-left">
                  {t("common:carerFilter.editCarerType")}
                </div>

                {/* Separator */}
                <hr className="h-px bg-primary/20 border-0"></hr>

                <div className="grid md:grid-cols-2 gap-4 p-10 auto-rows-fr items-start space-y-3 sm:space-y-0">
                  {carerTypeData &&
                    carerTypeData.map((item, index) => (
                      <div key={index} className="flex items-center h-10">
                        <input
                          id={`carerType-${item.id}`}
                          name="carerTypes"
                          type="checkbox"
                          value={item.id}
                          checked={localSelectedCarerTypes.includes(item.id)}
                          onChange={handleCarerTypeChange}
                          className="h-6 w-6 text-primary focus:ring-primary border-gray-300 rounded"
                        />
                        <label
                          htmlFor={`carerType-${item.id}`}
                          className="ml-4 text-base text-slate-700 capitalize flex-1">
                          {item.name}
                        </label>
                      </div>
                    ))}
                </div>

                {/* Buttons */}

                {/* Update filter button */}
                <div className="flex flex-col lg:flex-row gap-4 p-3 sm:px-6 sm:py-6 lg:px-10">
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    ref={cancelButtonRef}
                    className="transition capitalize w-full duration-300 text-lg text-center py-3 px-4 rounded-full 
    bg-slate-400 hover:bg-slate-500 text-white border-primary hover:border-slate-500 cursor-pointer">
                    {t("common:form.cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={handleUpdateFilter}
                    className="transition capitalize w-full duration-300 text-lg text-center py-3 px-4 rounded-full 
        bg-primary hover:bg-primary-hover text-white border-primary hover:border-primary-hover cursor-pointer">
                    {t("common:form.updateChanges")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CarerFilterPopUp;
