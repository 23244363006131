// ./src/components/NavigationBar/MenuButton.js

import { NavLink } from "react-router-dom";
import React from "react";

const MenuButton = ({ url, title }) => {
  const commonStyles =
    "transition duration-300 px-7 h-full flex items-center capitalize flex-grow hover:bg-[#EDF2EE]";
  const selectedStyles = "bg-[#EDF2EE] text-green-700 ";

  return (
    <>
      <span className="flex flex-row flex-shrink-0 items-center">
        <NavLink
          to={url}
          className={({ isActive }) =>
            isActive ? `${selectedStyles} ${commonStyles}` : commonStyles
          }>
          {title}
        </NavLink>
      </span>
    </>
  );
};

export default MenuButton;
