// src/components/Blocks/TextParagraphBlock.js

import React from "react";
import { useTranslation } from "react-i18next";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const TextPragraphBlock = ({ content, settings }) => {
  const { i18n } = useTranslation();

  // Classes
  // General settings
  const generalClasses = generateTailwindClasses(settings);

  // Render conditional after checking existence of parts
  const renderedContent = [];
  if (content.subtitle) {
    const subtitleClasses = generateTailwindClasses(content.subtitle.settings);
    renderedContent.push(
      <div key="subtitle" className={`uppercase ${subtitleClasses}`}>
        {i18n.language === "en"
          ? content.subtitle.contentEnglish || content.subtitle.content
          : content.subtitle.content}
      </div>
    );
  }
  //
  if (content.title) {
    const titleClasses = generateTailwindClasses(content.title.settings);
    renderedContent.push(
      <div key="title" className={`leading-tight ${titleClasses}`}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "en"
                ? content.title.contentEnglish || content.title.content
                : content.title.content,
          }}
        />
      </div>
    );
  }
  if (content.paragraph) {
    const paragraphClasses = generateTailwindClasses(
      content.paragraph.settings
    );
    renderedContent.push(
      <div key="paragraph" className={paragraphClasses}>
        {i18n.language === "en"
          ? content.paragraph.contentEnglish || content.paragraph.content
          : content.paragraph.content}
      </div>
    );
  }

  return <div className={generalClasses}>{renderedContent}</div>;
};

export default TextPragraphBlock;
