// src/components/Blocks/ImageSoloBlock.js

import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import ImageWithFallback from "../Image/ImageWithFallback";

const ImageSoloBlock = ({ content, settings, imageLeft = true }) => {
  const { i18n } = useTranslation();

  // Coerce undefined, string, or array into an array
  const ensureArray = (data) => {
    if (!data) return []; // Handle undefined or null
    if (Array.isArray(data)) return data; // Handle arrays
    return [data]; // Wrap string or other values into an array
  };

  //   // Determine the appropriate content list based on language and the existence of contentEnglish
  //   const getContentList = (content, language) => {
  //     let selectedContent = content.content; // Default to the base content

  //     // Check if contentEnglish exists and is not effectively empty
  //     const isContentEnglishValid =
  //       language === "en" &&
  //       content.contentEnglish &&
  //       (content.contentEnglish.length > 1 ||
  //         (content.contentEnglish.length === 1 &&
  //           content.contentEnglish[0] !== ""));

  //     if (isContentEnglishValid) {
  //       selectedContent = content.contentEnglish;
  //     }

  //     return ensureArray(selectedContent);
  //   };

  //   const contentList = getContentList(content.list, i18n.language);

  // General settings
  const generalClasses = generateTailwindClasses(settings);

  return (
    <div className="px-4">
      <div
        className={`mx-auto max-w-2xl overflow-hidden lg:max-w-7xl ${generalClasses}`}>
        <ImageWithFallback
          src={content.url}
          alt={"image"}
          className={"w-full"}
          //className={generateTailwindClasses(content.settings)}
        />
      </div>
    </div>
  );
};

export default ImageSoloBlock;
