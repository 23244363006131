// src/pages/carer-support/discounts/DiscountsContent.js

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import DiscountCard from "../../../components/Cards/DiscountCard";
import Select from "../../../components/Select/Select";
import _ from "lodash";

const DiscountsContent = ({
  entities,
  loading,
  loadingMore,
  remainingItems,
  serverMessage,
  handleLoadMore,
  discountFilterDataReady,
  isLoadingDiscountFilterData,
  handleDiscountFilterClick,
  discountFilterData,
  currentFilter,
  selectedCategory,
  setSelectedCategory,
  selectedLocation,
  setSelectedLocation,
  prevFilter,
}) => {
  const { t } = useTranslation();

  //

  if (loading && !discountFilterDataReady) {
    return (
      <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && serverMessage && serverMessage.type === "error") {
    return (
      <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
        {serverMessage.message}
      </div>
    );
  }

  // if (!loading && entities && entities.length === 0) {
  //   return (
  //     <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
  //       {t("errors:notFoundError", { entity: t("common:entities.merchants") })}
  //     </div>
  //   );
  // }

  // if (!loading && entities && entities.length > 0) {
  return (
    <Fragment>
      {/* Filter bar container */}
      <div className="flex flex-col bg-gray-50 px-6 pt-4 pb-6 rounded-2xl ring-1 ring-inset ring-gray-200 gap-2">
        <div className="flex flex-col justify-between md:flex-row items-center">
          <div className="flex flex-col md:flex-row gap-8 items-center">
            <div className="flex flex-col md:flex-row items-center gap-4">
              <span className="text-slate-700">
                {t("common:generic.category")}
              </span>
              <Select
                data={discountFilterData ? discountFilterData.categories : null}
                selected={selectedCategory}
                setSelected={setSelectedCategory}
                label={""}
                className="flex flex-col md:w-56 lg:w-80"
              />{" "}
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <span className="text-slate-700">
                {t("common:generic.district")}
              </span>
              <Select
                data={discountFilterData ? discountFilterData.districts : null}
                selected={selectedLocation}
                setSelected={setSelectedLocation}
                label={""}
                className="flex flex-col md:w-56 lg:w-80"
              />
            </div>
          </div>
          <button
            type="buton"
            disabled={
              loading || loadingMore || _.isEqual(currentFilter, prevFilter)
            }
            onClick={handleDiscountFilterClick}
            className={`items-center rounded-full 
              ${
                _.isEqual(currentFilter, prevFilter)
                  ? "bg-primary-disabled"
                  : "cursor-pointer bg-primary hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-primary"
              }
              flex-shrink-0  px-9 py-3 md:py-0 md:h-9 text-sm font-semibold text-white shadow-sm `}>
            {t("common:buttons.filter")}
          </button>
        </div>
      </div>

      {!loading && entities && entities.length === 0 ? (
        <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
          {t("errors:notFoundError", {
            entity: t("common:entities.merchants"),
          })}
        </div>
      ) : (
        <Fragment>
          {/* Discount cards */}
          <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3 mt-4">
            {entities.map((entity) => (
              <DiscountCard
                key={entity.id}
                entity={entity}
                to={"/carer-support/discounts"}
              />
            ))}
          </div>
          {remainingItems > 0 && !loading && (
            <div className="text-center mt-8">
              <button
                type="buton"
                disabled={loadingMore}
                onClick={handleLoadMore}
                className="rounded-md cursor-pointer bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-primary">
                {t("common:buttons.loadMore")}
              </button>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
// };

export default DiscountsContent;
