// src/constants/endPoints.js
const endPoint = {
  EVENTS: "/public/events",
  EVENTS_CHECK_ACCESS: "/shared/events/check-access",
  EVENT_REGISTRATION: "/shared/events/:id/reserve-seat/:memberId",
  LOGIN: "/public/auth",
  CHECK_AUTH: "/public/auth/check-auth",
  EVENT_FILTERS: "/public/data/event-filters",
  CARER_TYPES: "/public/data/carer-types",
  LOGOUT: "/public/auth/logout",
  HOME_PAGE: "public/pages/home-page",
  ABOUT_PAGE: "public/pages/about-page",
  JOIN_CAREREPS_PAGE: "public/pages/join-carereps-page",
  DISCLAIMER_PAGE: "public/pages/disclaimer-page",
  ARTICLES: "/public/articles",
  ARTICLES_BOOKMARK: "/public/articles/bookmark",
  MEMBER_BOOKMARKS: "/public/articles/bookmarked",
  MEMBER_EVENTS: "public/events/registrations",
  MEMBER_PASSWORD_CHANGE: "public/members/change-password",
  MEMBER: "public/members",
  APPLICATION: "/public/applications",
  REGISTERED_EVENTS: "/public/events/registrations",
  DISCOUNTS: "/public/vendors",
  DISCOUNTS_FILTERS: "/public/data/event-filters/discounts",
  VALIDATE_NON_ACTIVE_MEMBER: "/public/new-old-member-account",
};

export { endPoint };
