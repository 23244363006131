// src/pages/home/EventsContent.js

import React from "react";
import { useTranslation } from "react-i18next";
import EventCard from "../../components/Cards/EventCard";
import EventsFilter from "../../components/Events/Filter";

const EventsContent = ({
  events,
  handleLoadMore,
  loading,
  loadingMore,
  eventFilterDataReady,
  errorMessage,
  remainingItems,
  eventFilterData,
  selectedCategory,
  setSelectedCategory,
  selectedOrganization,
  setSelectedOrganization,
  selectedLocation,
  setSelectedLocation,
  selectedDate,
  setSelectedDate,
  handleEventFilterClick,
  selectedMode,
  setSelectedMode,
  handleClearFilter,
  currentFilter,
  prevFilter,
}) => {
  const { t } = useTranslation();

  if (loading && !eventFilterDataReady) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && errorMessage) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {errorMessage}
      </div>
    );
  }

  // if (!loading && events && events.length === 0) {
  //   return (
  //     <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
  //       {t("errors:notFountError", { entity: t("common:entities.events") })}
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col flex-grow">
      <div className="mb-5">
        <EventsFilter
          loading={loading}
          eventFilterDataReady={eventFilterDataReady}
          eventFilterData={eventFilterData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleEventFilterClick={handleEventFilterClick}
          selectedMode={selectedMode}
          setSelectedMode={setSelectedMode}
          handleClearFilter={handleClearFilter}
          currentFilter={currentFilter}
          prevFilter={prevFilter}
        />
      </div>

      {!loading && events && events.length === 0 ? (
        <div className="flex flex-col text-center justify-center items-center h-full flex-grow text-gray-500">
          {t("errors:notFoundError", { entity: t("common:entities.events") })}
        </div>
      ) : (
        <>
          <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {events.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>

          {remainingItems > 0 && !loading && (
            <div className="text-center mt-8">
              <button
                type="buton"
                disabled={loadingMore}
                onClick={handleLoadMore}
                className="rounded-md cursor-pointer bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-primary">
                {t("common:buttons.loadMore")}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EventsContent;
