// src/components/Blocks/ImageHighlightsBlock.js

import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import ImageWithFallback from "../Image/ImageWithFallback";

const ImageHighlightsBlock = ({ content, settings, imageLeft = true }) => {
  const { i18n } = useTranslation();

  // Coerce undefined, string, or array into an array
  const ensureArray = (data) => {
    if (!data) return []; // Handle undefined or null
    if (Array.isArray(data)) return data; // Handle arrays
    return [data]; // Wrap string or other values into an array
  };

  // Determine the appropriate content list based on language and the existence of contentEnglish
  const getContentList = (content, language) => {
    let selectedContent = content.content; // Default to the base content

    // Check if contentEnglish exists and is not effectively empty
    const isContentEnglishValid =
      language === "en" &&
      content.contentEnglish &&
      (content.contentEnglish.length > 1 ||
        (content.contentEnglish.length === 1 &&
          content.contentEnglish[0] !== ""));

    if (isContentEnglishValid) {
      selectedContent = content.contentEnglish;
    }

    return ensureArray(selectedContent);
  };

  const contentList = getContentList(content.list, i18n.language);

  // General settings
  const generalClasses = generateTailwindClasses(settings);

  return (
    <div className={`mx-auto max-w-2xl lg:max-w-7xl ${generalClasses}`}>
      <div
        className={`lg:grid 
      ${
        content.image && content.image.url ? "lg:grid-cols-2" : "lg:grid-cols-1"
      }
      lg:gap-x-8 items-center`}>
        {content && content.image && content.image.url && (
          <div className={`w-full ${!imageLeft && "order-last"}`}>
            <ImageWithFallback
              src={content.image.url}
              alt={content.title.content}
              className={generateTailwindClasses(content.image.settings)}
            />
          </div>
        )}

        <div
          className={`px-2 lg:px-0 mt-8 lg:mt-0 ${
            content.image && content.image.url
              ? imageLeft
                ? "lg:pr-12 lg:pl-4"
                : "lg:pl-12 lg:pr-4"
              : "w-full"
          }`}>
          <div
            className={`uppercase ${generateTailwindClasses(
              content.subtitle.settings
            )}`}>
            {i18n.language === "en"
              ? content.subtitle.contentEnglish || content.subtitle.content
              : content.subtitle.content}
          </div>
          <div
            className={`leading-tight ${generateTailwindClasses(
              content.title.settings
            )}`}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en"
                    ? content.title.contentEnglish || content.title.content
                    : content.title.content,
              }}
            />
          </div>
          <div className={generateTailwindClasses(content.paragraph.settings)}>
            {i18n.language === "en"
              ? content.paragraph.contentEnglish || content.paragraph.content
              : content.paragraph.content}
          </div>
          {/* Render list if exists */}
          {content.list ? (
            <ul
              className={`gap-3 flex flex-col ${generateTailwindClasses(
                content.list.settings
              )}`}>
              {contentList.map((item, index) => (
                <li key={index} className="flex items-start -ml-0.5">
                  <CheckCircleIcon className="w-6 h-6 mr-2 text-primary shrink-0" />
                  {item}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ImageHighlightsBlock;
