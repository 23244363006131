// src/utils/timeFuncs.js

import { format } from "date-fns";
import { zhTW, enUS } from "date-fns/locale";
import moment from "moment-timezone";

const DEFAULT_TIMEZONE = process.env.REACT_APP_TIMEZONE;

const locales = {
  "en-US": enUS,
  "zh-TW": zhTW,
};

const mapLanguageCode = (code) => {
  if (code === "tc") {
    return "zh-TW";
  } else {
    return "en-US";
  }
};

export const formatEventDate = (dateString, languageCode) => {
  if (!dateString) {
    console.warn("dateString is not defined");
    return "";
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid dateString:", dateString);
    return "";
  }
  const locale = locales[mapLanguageCode(languageCode)];
  return format(date, "yyyy年M月d日 (E)", { locale: locale });
};

export const formatDateNoDayName = (dateString, languageCode) => {
  if (!dateString) {
    console.warn("dateString is not defined");
    return "";
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid dateString:", dateString);
    return "";
  }
  const locale = locales[mapLanguageCode(languageCode)];
  return format(date, "yyyy年M月d日", { locale: locale });
};

export const formatEventTime = (dateString, languageCode) => {
  if (!dateString) {
    console.warn("dateString is not defined");
    return "";
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.warn("Invalid dateString:", dateString);
    return "";
  }
  const locale = locales[mapLanguageCode(languageCode)];
  return format(date, "a h:mm", { locale: locale });
};

/**
 * Get the current date-time in a desired timezone and format.
 * @param {string} [timezone=DEFAULT_TIMEZONE] - The desired timezone.
 * @param {string} [format='YYYY-MM-DD HH:mm:ss'] - The desired date format.
 * @returns {string} - The formatted date string.
 */
export const getCurrentDateTimeInTimezone = (
  timezone = DEFAULT_TIMEZONE,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  return moment.tz(timezone).format(format);
};

/**
 * Get the difference in days or hours between a given date and the current date.
 * @param {string} date - The date string to compare (format: 'YYYY-MM-DD HH:mm:ss').
 * @param {function} t - The translation function for localization.
 * @param {string} [timezone='DEFAULT_TIMEZONE'] - The timezone to use for the comparison.
 * @param {string} [format='YYYY-MM-DD HH:mm:ss'] - The format of the date string.
 * @returns {string} - The formatted difference in days or hours.
 */
export const getDaysOrHoursDifference = (
  date,
  t,
  timezone = DEFAULT_TIMEZONE,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const currentDate = moment.tz(timezone);
  const targetDate = moment(date).tz(timezone);

  // Check if the targetDate is a valid date
  if (!targetDate.isValid()) {
    return "Invalid date";
  }

  const daysDifference = targetDate.diff(currentDate, "days");

  if (daysDifference < 1) {
    const hoursDifference = targetDate.diff(currentDate, "hours");
    if (hoursDifference === 0) {
      return "0"; // Date has passed
    }
    return `${hoursDifference} ${t("common:generic.hours")}`;
  }

  return `${daysDifference} ${t("common:generic.days")}`;
};
