// src/components/Image/ImageWithFallback.js

import React from "react";

const ImageWithFallback = ({ src, fallbackSrc, alt, ...props }) => {
  //
  const imagePlaceholder = "/assets/images/placeholder.jpg";
  const imageSrc = src || imagePlaceholder;
  const fallbackImage = fallbackSrc || imagePlaceholder;

  const handleImageError = (event) => {
    // If the image fails to load, set the src to the fallbackSrc
    event.target.onerror = null; // Prevents infinite loop if the fallback image also fails
    event.target.src = fallbackImage;
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      onError={handleImageError}
      {...props}
      loading="lazy"
    />
  );
};

export default ImageWithFallback;
