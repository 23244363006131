// src/layouts/ContentLayout.js

import React from "react";

const ContentLayout = ({ children }) => {
  return (
    <main className="flex flex-col w-full max-w-7xl m-auto flex-grow p-3 lg:p-5">
      {children}
    </main>
  );
};

export default ContentLayout;
