// src/components/Events/ReservePopUpPage.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";

import { useAuthState } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

import ReservePopUpContentLogin from "./ReservePopUpContentLogin";
import ReservePopUpContentSummary from "./ReservePopUpContentSummary";
import ReservePopUpContentResult from "./ReservePopUpContentResult";

const ReservePopUpPage = ({
  isOpen,
  setIsOpen,
  event,
  eventType,
  checkButtonMessage,
  registrationInfo,
  loadingRegistrationInfo,
  setRegistrationInfo,
  fallbackImage,
}) => {
  // useEffect(() => {
  //   console.log("--------------->", registrationInfo);
  // }, [registrationInfo]);
  //
  // id would correspond to either the event id for Single and full registration
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();
  const { user, setUser } = useAuthState();

  // This component manages login itself
  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });
  const [inProgress, setInProgress] = useState(false);

  //////
  // Logs member in
  const userLogin = async (loginData) => {
    setInProgress(true);
    try {
      const response = await axiosInstance.post(endPoint.LOGIN, loginData, {
        withCredentials: true,
      });

      if (response.data.status === "success") {
        // We will set the user in the child
        //setUser(response.data.data.user);
        localStorage.setItem("isLoggedInCarerEPS", "true");
        manageServerMessage(
          setServerMessage,
          response.data.message,
          "success",
          2000
        );
        return { success: true, user: response.data.data.user };
      } else {
        // Handle non-success status with proper message
        manageServerMessage(
          setServerMessage,
          response.data.message,
          "error",
          2000
        );
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 2000);
      return { success: false, message: errorMessage };
    } finally {
      setInProgress(false);
    }
  };

  const [registrationResult, setRegistrationResult] = useState(null);

  ///////
  // Member registration
  const memberRegistration = async () => {
    //
    setInProgress(true);
    const registrationType = eventType === "section" ? "section" : undefined;

    // console.log("data", event.id, user.id, registrationType);

    try {
      const url = `${endPoint.EVENT_REGISTRATION}`
        .replace(":id", event.id)
        .replace(":memberId", user.id);

      const response = await axiosInstance.post(
        url,
        null, // No req body data
        {
          params: {
            registrationType,
          },
          withCredentials: true,
        }
      );

      if (response.data.status === "success") {
        setRegistrationResult({ success: true });
        // Update setRegistrationInfo with the new entity
        const updatedRegistrationInfo = registrationInfo.map((item) => {
          if (item.id === event.id) {
            return { ...item, isAlreadyRegistered: true };
          } else {
            return item;
          }
        });
        // Set the state with the updated registration information
        setRegistrationInfo(updatedRegistrationInfo);
      } else {
        setRegistrationResult({
          success: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      setRegistrationResult({
        success: false,
        message: errorMessage,
      });
    } finally {
      setInProgress(false);
    }
  };

  const cancelButtonRef = useRef(null);

  // Function to check whether the user can register or not to the event OR session
  const getAttendanceStatus = () => {
    if (
      !loadingRegistrationInfo &&
      registrationInfo &&
      registrationInfo.length > 0 &&
      event
    ) {
      //
      if (eventType === "section") {
        const item = registrationInfo.find((sess) => sess.id === event.id);
        if (event) {
          return checkButtonMessage(item);
        } else {
          return null; // Session ID not found
        }
      } else {
        return checkButtonMessage(registrationInfo[0]);
      }
    }
    return null;
  };

  // // on mount
  useEffect(() => {
    // On close
    if (isOpen) {
      // Clear states
      setRegistrationResult(null);
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl ">
                {event ? (
                  !user ? ( //px-4 pb-4 pt-5 sm:p-6
                    <ReservePopUpContentLogin
                      user={user}
                      setUser={setUser}
                      setIsOpen={setIsOpen}
                      cancelButtonRef={cancelButtonRef}
                      userLogin={userLogin}
                      inProgress={inProgress}
                      serverMessage={serverMessage}
                    />
                  ) : !registrationResult &&
                    (!registrationInfo ||
                      (registrationInfo && getAttendanceStatus().isAllowed)) &&
                    !loadingRegistrationInfo ? (
                    <ReservePopUpContentSummary
                      setIsOpen={setIsOpen}
                      cancelButtonRef={cancelButtonRef}
                      event={event}
                      eventType={eventType}
                      inProgress={inProgress}
                      memberRegistration={memberRegistration}
                      fallbackImage={fallbackImage}
                    />
                  ) : registrationResult ||
                    (registrationInfo && !getAttendanceStatus().isAllowed) ? (
                    <ReservePopUpContentResult
                      event={event}
                      eventType={eventType}
                      registrationResult={registrationResult}
                      cancelButtonRef={cancelButtonRef}
                      registrationInfo={registrationInfo}
                      getAttendanceStatus={getAttendanceStatus}
                    />
                  ) : null
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReservePopUpPage;
