// ./src/components/Footer/Footer.js

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SmallCard from "../RadioButtons/SmallCard";
import { navigationURL } from "../../constants/navigation";

import CarerEPSHLogo from "../SVGAssets/CarerEPSHLogo";
import { Fragment } from "react";

/**
 * Footer component renders the bottom footer.
 *
 * @component
 */
const Footer = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.id);
  };

  const footerSections = [
    // {
    //   id: 0,
    //   title: "My CarerEPS",
    //   links: [
    //     {
    //       id: 3,
    //       title: t("common:navigationMenu.myCarereps"),
    //       url: navigationURL.MY_CAREREPS,
    //     },
    //     {
    //       id: 4,
    //       title: t("common:navigationMenu.joinCarerEPS"),
    //       url: navigationURL.JOIN_CAREREPS,
    //     },
    //   ],
    // },
    {
      id: 1,
      title: t("common:footer.carerepsPlatform"),
      links: [
        {
          id: 3,
          title: t("common:navigationMenu.myCarereps"),
          url: navigationURL.MY_CAREREPS,
        },
        {
          id: 4,
          title: t("common:navigationMenu.joinCarerEPS"),
          url: navigationURL.JOIN_CAREREPS,
        },
        {
          id: 9,
          title: t("common:navigationMenu.privacyPolicy"),
          url: navigationURL.DISCLAIMER,
        },
        {
          id: 5,
          title: t("common:navigationMenu.about"),
          url: navigationURL.ABOUT,
        },
      ],
    },
    {
      id: 2,
      title: t("common:footer.resources"),
      links: [
        {
          id: 2,
          title: t("common:navigationMenu.events"),
          url: navigationURL.EVENTS,
        },
        {
          id: 20,
          title: t("common:navigationMenu.knowledgeBase"),
          url: navigationURL.CARER_SUPPORT_KB,
        },
        {
          id: 21,
          title: t("common:carerTab.support"),
          url: navigationURL.CARER_SUPPORT_RESOURCES,
        },

        {
          id: 7,
          title: t("common:carerTab.discounts"),
          url: navigationURL.CARER_SUPPORT_DISCOUNTS,
        },
      ],
    },
    {
      id: 3,
      title: t("common:footer.social"),
      links: [
        {
          id: 12,
          title: "Facebook",
          url: "https://www.facebook.com/people/%E7%85%A7%E9%A1%A7%E8%80%85%E6%98%93%E9%81%94%E5%B9%B3%E5%8F%B0/100088404643477/",
          target: "_blank",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <footer className="w-full bg-[#eef7f1] border-t border-slate-200 py-16 px-6">
        <div className="max-w-7xl m-auto flex flex-col gap-4 lg:flex-row gap-x-10">
          {/* Separate div for the first column */}
          <div className="flex w-full flex-col gap-4 lg:flex-row lg:w-2/4  justify-between">
            <div className="lg:w-1/2">
              <CarerEPSHLogo
                className="h-auto w-56 "
                alt="CarerEPS Logo"
                color="green"
              />

              <h3 className="font-semibold mb-1 mt-4 text-xl text-[#1c6b38]">
                {t("common:footer.carerepsPlatform")}
              </h3>
              <p className="text-slate-700">
                {t("common:footer.carerepsSlogan")}
              </p>

              {/* Language selector */}

              <span className="flex capitalize mt-8 mb-2 text-slate-700">
                {t("common:generic.language")}
              </span>

              <SmallCard
                data={[
                  { id: "en", name: "English" },
                  { id: "tc", name: "繁體中文" },
                ]}
                selected={
                  i18n.language === "en"
                    ? { id: "en", name: "English" }
                    : { id: "tc", name: "繁體中文" }
                }
                setSelected={handleLanguageChange}
                className={"flex flex-col max-w-56"}
              />
            </div>
            <div className="lg:w-1/2">
              <h2 className="font-bold text-lg uppercase mb-1 text-primary">
                {t("common:footer.secretariat")}
              </h2>
              <h3 className="font-medium text-lg capitalize mb-6 text-slate-800">
                {t("common:footer.contactInfo")}
              </h3>
              <ul className="space-y-2">
                <li className="transition duration-200 text-gray-700 hover:text-gray-950">
                  <span className="font-semibold text-slate-800">
                    {t("common:footer.address")}:{" "}
                  </span>
                  <span className="font-normal text-slate-600">
                    {t("common:footer.addressContent")}
                  </span>
                </li>
                <li className="transition duration-200 text-gray-700 hover:text-gray-950">
                  <span className="font-semibold text-slate-800">
                    {t("common:footer.email")}:{" "}
                  </span>
                  <span className="font-normal text-slate-600">
                    info@tpbcss.org
                  </span>
                </li>
                <li className="transition duration-200 text-gray-700 hover:text-gray-950">
                  <span className="font-semibold text-slate-800">
                    {t("common:footer.telephone")}:{" "}
                  </span>
                  <span className="font-normal text-slate-600">
                    2656 6680 / 2899 8100
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Div wrapping the grid for the other columns */}
          <div className="flex-grow grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-10 mt-8 md:mt-0">
            {/* Dynamically rendered columns for links */}
            {footerSections.map((section) => (
              <div key={section.id} className="col-span-1 flex flex-col">
                <h3 className="font-semibold uppercase mb-6">
                  {section.title}
                </h3>
                <ul className="space-y-2">
                  {section.links.map((link) => (
                    <li
                      key={link.id}
                      className="transition capitalize duration-200 text-gray-700 hover:text-gray-950">
                      <Link to={link.url} target={link.target || "_self"}>
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </footer>
      <div className="bg-[#4DAB6E] h-10 flex-shrink-0"></div>
    </Fragment>
  );
};

export default Footer;
