// src/components/Blocks/LogoGridBlock.js

import React from "react";

import ImageWithFallback from "../Image/ImageWithFallback";
import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const LogoGridBlock = ({ content, settings }) => {
  // General settings
  const generalClasses = generateTailwindClasses(settings);

  // Render conditional after checking existence of parts
  const renderedContent = content.map((item, index) => {
    // Destructure content item
    const { image } = item;

    // Prepare classes for each content item
    const imageClasses = generateTailwindClasses(image.settings);

    return (
      <div key={index} className="">
        <div className="w-full ">
          <ImageWithFallback
            src={image.url}
            alt={image.alt}
            className={`object-cover object-center ${imageClasses}`}
          />
        </div>
      </div>
    );
  });

  return (
    <div className={generalClasses}>
      <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {renderedContent}
      </div>
    </div>
  );
};

export default LogoGridBlock;
