// src/components/Blocks/BlockRenderer.js

import TextBlock from "./TextBlock";
import ButtonBlock from "./ButtonBlock";
import TextPragraphBlock from "./TextParagraph";
import DividerBlock from "./DividerBlock";
import HighlightsBlock from "./HighlightsBlock";
import ImageHighlightsBlock from "./ImageHighlightsBlock";
import ImageHighlightsButtonBlock from "./ImageHighlightsButtonBlock";
import TextParagraphLogoGridBlock from "./TextParagraphLogoGridBlock";
import PageBannerBlock from "./PageBannerBlock";
import LogoGridBlock from "./LogoGridBlock";
import TextButtonBlock from "./TextButtonBlock";
import { useTranslation } from "react-i18next";

import { blocks } from "../../constants/blocks";
import YouTubeBlock from "./YouTubeBlock";
import ImageSoloBlock from "./ImageSoloBlock";
import ParagraphBlock from "./ParagraphBlock";

const BlockRenderer = ({ block, carerModal }) => {
  const { i18n } = useTranslation();
  switch (block.type) {
    case blocks.TEXT:
      return <TextBlock content={block.content} settings={block.settings} />;
    case blocks.BUTTON:
      return (
        <ButtonBlock
          link={block.link}
          content={
            i18n.language === "en"
              ? block.contentEnglish || block.content
              : block.content
          }
          settings={block.settings}
          carerModal={carerModal}
        />
      );
    case blocks.TEXT_PARAGRAPH:
      return (
        <TextPragraphBlock content={block.content} settings={block.settings} />
      );
    case blocks.DIVIDER:
      return <DividerBlock content={block.content} settings={block.settings} />;
    case blocks.HIGHLIGHTS_GRID:
      return (
        <HighlightsBlock content={block.content} settings={block.settings} />
      );
    case blocks.IMAGE_HIGHLIGHTS:
      return (
        <ImageHighlightsBlock
          content={block.content}
          settings={block.settings}
          imageLeft={block.imageLeft}
        />
      );
    case blocks.LOGO_GRID:
      return (
        <LogoGridBlock content={block.content} settings={block.settings} />
      );
    case blocks.TEXT_BUTTON:
      return (
        <TextButtonBlock content={block.content} settings={block.settings} />
      );

    case blocks.IMAGE_HIGHLIGHTS_WBUTTON:
      return (
        <ImageHighlightsButtonBlock
          content={block.content}
          settings={block.settings}
          imageLeft={block.imageLeft}
        />
      );
    case blocks.TEXT_PARAGRAPH_LOGO_GRID:
      return (
        <TextParagraphLogoGridBlock
          content={block.content}
          settings={block.settings}
          imageLeft={block.imageLeft}
        />
      );

    case blocks.PAGES_BANNER:
      return (
        <PageBannerBlock
          content={block.content}
          settings={block.settings}
          imageLeft={block.imageLeft}
        />
      );

    case blocks.YOUTUBE:
      return <YouTubeBlock content={block.content} settings={block.settings} />;

    case blocks.IMAGE_SOLO:
      return (
        <ImageSoloBlock content={block.content} settings={block.settings} />
      );

    case blocks.PARAGRAPH:
      return (
        <ParagraphBlock content={block.content} settings={block.settings} />
      );

    default:
      return <div>Unsupported block type</div>;
  }
};

export default BlockRenderer;
