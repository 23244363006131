// CarerSupportPage.js
import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MainNoContentLayout from "../../layouts/MainNoContentLayout";
import ContentLayout from "../../layouts/ContentLayout";
import CarerFilterPopUp from "../../components/CarerSupport/CarerFilterPopUp";
import CarerFilterBar from "../../components/CarerSupport/CarerFilterBar";
import Tabs from "../../components/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

function CarerSupportPage() {
  const { t } = useTranslation();
  // Carer Type Pop Up State
  const [isOpen, setIsOpen] = useState(false);

  const carerSupportTabs = {
    knowledge: {
      path: "/carer-support/knowledge-base",
      name: t("common:carerTab.knowledge"),
    },
    support: {
      path: "/carer-support/resources",
      name: t("common:carerTab.support"),
    },
    discounts: {
      path: "/carer-support/discounts",
      name: t("common:carerTab.discounts"),
    },
  };

  // State to manage the selected tab
  const [selectedTab, setSelectedTab] = useState(carerSupportTabs.knowledge);

  const handleCarerFilterButtonClick = () => {
    // Track opening of carer selection
    ReactGA.event({
      category: "Carer Type Selection",
      action: "Open Carer Selection Popup",
      label: "Select Carer Type",
    });

    setIsOpen(true);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:navigationMenu.carerSupport")} -{" "}
          {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainNoContentLayout>
        {/* Reserve Pop Up */}
        <CarerFilterPopUp isOpen={isOpen} setIsOpen={setIsOpen} />

        <CarerFilterBar
          handleCarerFilterButtonClick={handleCarerFilterButtonClick}
        />

        <ContentLayout>
          {/* Navigation tabs */}

          <Tabs
            tabs={carerSupportTabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />

          <div className="mt-4 h-full">
            {/* Outlet for nested routes */}
            <Outlet />
          </div>
        </ContentLayout>
      </MainNoContentLayout>
    </Fragment>
  );
}

export default CarerSupportPage;
