// src/pages/home/News.js

import React from "react";
import { Helmet } from "react-helmet-async";
import MainLayout from "../../layouts/MainLayout";

const NewsPage = () => {
  return (
    <>
      <Helmet>
        <title>News Page</title>
        <meta name="description" content="This is the news page" />
      </Helmet>
      <MainLayout>
        <h1>Welcome to the News Page</h1>
        <p>This is the main content of the News page.</p>
      </MainLayout>
    </>
  );
};

export default NewsPage;
