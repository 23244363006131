// src/components/Blocks/TextParagraphLogoGridBlock.js

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import ImageWithFallback from "../Image/ImageWithFallback";

const TextParagraphLogoGridBlock = ({ content, settings, gridLeft = true }) => {
  const { i18n } = useTranslation();

  // Coerce undefined, string, or array into an array
  const ensureArray = (data) => {
    if (!data) return []; // Handle undefined or null
    if (Array.isArray(data)) return data; // Handle arrays
    return [data]; // Wrap string or other values into an array
  };

  // Determine the appropriate content list based on language and the existence of contentEnglish
  const getContentList = (content, language) => {
    let selectedContent = content.content; // Default to the base content

    // Check if contentEnglish exists and is not effectively empty
    const isContentEnglishValid =
      language === "en" &&
      content.contentEnglish &&
      (content.contentEnglish.length > 1 ||
        (content.contentEnglish.length === 1 &&
          content.contentEnglish[0] !== ""));

    if (isContentEnglishValid) {
      selectedContent = content.contentEnglish;
    }

    return ensureArray(selectedContent);
  };

  const contentList = getContentList(content.list, i18n.language);

  // General settings
  const generalClasses = generateTailwindClasses(settings);

  const renderedContent = [];
  //
  if (content.subtitle) {
    const subtitleClasses = generateTailwindClasses(content.subtitle.settings);
    renderedContent.push(
      <div key="subtitle" className={`uppercase ${subtitleClasses}`}>
        {i18n.language === "en"
          ? content.subtitle.contentEnglish || content.subtitle.content
          : content.subtitle.content}
      </div>
    );
  }
  if (content.title) {
    const titleClasses = generateTailwindClasses(content.title.settings);
    renderedContent.push(
      <div key="title" className={`leading-tight ${titleClasses}`}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "en"
                ? content.title.contentEnglish || content.title.content
                : content.title.content,
          }}
        />
      </div>
    );
  }
  if (content.paragraph) {
    const paragraphClasses = generateTailwindClasses(
      content.paragraph.settings
    );
    renderedContent.push(
      <div key="paragraph" className={paragraphClasses}>
        {i18n.language === "en"
          ? content.paragraph.contentEnglish || content.paragraph.content
          : content.paragraph.content}
      </div>
    );
  }

  if (content.list) {
    const listClasses = generateTailwindClasses(content.list.settings);
    renderedContent.push(
      <ul
        key={"highlightsList"}
        className={`gap-3 flex flex-col ${listClasses}`}>
        {contentList.map((item, index) => (
          <li key={index} className="flex items-start -ml-0.5">
            <CheckCircleIcon className="w-6 h-6 mr-2 text-primary shrink-0" />
            {item}
          </li>
        ))}
      </ul>
    );
  }

  //
  if (content.button && content.button.content) {
    const buttonClasses = generateTailwindClasses(content.button.settings);
    renderedContent.push(
      <div key="button" className="flex w-full">
        <Link
          to={content.button.link}
          className={`transition duration-300 ${buttonClasses}`}>
          {i18n.language === "en"
            ? content.button.contentEnglish || content.button.content
            : content.button.content}
        </Link>
      </div>
    );
  }

  let renderedContentGrid;
  if (content.grid.content) {
    renderedContentGrid = content.grid.content.map((item, index) => {
      // Destructure content item
      const { image } = item;

      // Prepare classes for each content item
      const imageClasses = generateTailwindClasses(image.settings);

      return (
        <div key={index} className="">
          <div className="w-full ">
            <ImageWithFallback
              src={image.url}
              alt={image.alt}
              className={`object-cover object-center ${imageClasses}`}
            />
          </div>
        </div>
      );
    });
  }

  const imageGeneralClasses = generateTailwindClasses(content.grid.settings);

  return (
    <div className={`mx-auto max-w-2xl lg:max-w-7xl ${generalClasses}`}>
      <div className={`flex flex-col lg:flex-row lg:w-full lg:gap-x-8`}>
        {/** Grid Column */}
        <div
          className={`flex flex-col w-12/12 lg:w-5/12 ${
            gridLeft && "order-last"
          }`}>
          <div className={`grid gap-2 grid-cols-2 ${imageGeneralClasses}`}>
            {renderedContentGrid}
          </div>
        </div>
        {/** Text Column */}
        <div
          className={`flex flex-col justify-start w-12/12 lg:w-7/12 px-2 lg:px-0 mt-8 lg:mt-0 ${
            gridLeft ? "lg:pr-12 lg:pl-8" : "lg:pl-12 lg:pr-8"
          }`}>
          {/** Title Subtitle Paragraph */}

          {renderedContent}
        </div>
      </div>
    </div>
  );
};

export default TextParagraphLogoGridBlock;
