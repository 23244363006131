// src/components/Events/ReservePopUpContentResults.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";
import ImageWithFallback from "../Image/ImageWithFallback";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { navigationURL } from "../../constants/navigation";

const ReservePopUpContentResult = ({
  classes,
  event,
  eventType,
  registrationResult,
  registrationInfo,
  getAttendanceStatus,
}) => {
  // id would correspond to either the event id for Single and full registration
  // or the id of the session if eventType (registrationType) is section
  const { t } = useTranslation();
  let navigate = useNavigate();

  const handleBackToEvents = () => {
    // Navigate back to the last page
    navigate(navigationURL.EVENTS);
  };
  const handleGoToMyProfile = () => {
    // Navigate back to the last page
    navigate(navigationURL.MY_CAREREPS);
  };

  return (
    <Fragment>
      {/* Banner */}

      <ImageWithFallback
        src={event && event.image}
        alt={"Login image"}
        className="w-full h-52 sm:h-64 lg:h-72 object-cover object-top"
      />

      <div className="bg-primary/10 p-6 text-slate-800 text-center uppercase font-semibold">
        <Dialog.Title as="h3">
          {(registrationResult && !registrationResult.success) ||
          (!registrationResult &&
            registrationInfo &&
            !getAttendanceStatus().isAllowed)
            ? t("common:events.reservationDeclined")
            : t("common:events.reservationCompleted")}
        </Dialog.Title>
      </div>

      <hr className="h-px bg-primary/40 border-0"></hr>

      <div className="px-4 pb-4 pt-8 sm:p-6">
        {/* Message content */}
        <div className="p-6">
          <div
            className={`mx-auto flex h-20 mb-8 w-20 items-center justify-center rounded-full
          ${
            (registrationResult && !registrationResult.success) ||
            (!registrationResult &&
              registrationInfo &&
              !getAttendanceStatus().isAllowed)
              ? "bg-red-100 ring-1 ring-red-200"
              : "bg-green-100 ring-1 ring-green-200"
          }`}>
            {(registrationResult && !registrationResult.success) ||
            (!registrationResult &&
              registrationInfo &&
              !getAttendanceStatus().isAllowed) ? (
              <XMarkIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
            ) : (
              <CheckIcon
                className="h-8 w-8 text-green-600"
                aria-hidden="true"
              />
            )}
          </div>

          <div className="mt-3 text-center sm:mt-5">
            <div className="mt-2">
              <p className="text-2xl font-semibold text-gray-800">
                {registrationResult && !registrationResult.success
                  ? t("common:events.issueRegisteringMember")
                  : t("common:events.registrationSuccess")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px bg-primary/40 border-0"></hr>
      {/* Buttons */}
      <div className="bg-primary/5 px-4 py-6">
        <div className="flex gap-6 px-8 ">
          <button
            type="button"
            onClick={handleBackToEvents}
            className="transition w-full duration-300 text-lg text-center py-3 px-4 rounded-full 
    bg-primary hover:bg-primary-hover text-white border-primary hover:border-primary-hover">
            {t("common:links.backToEvents")}
          </button>
          <button
            type="button"
            onClick={handleGoToMyProfile}
            className="transition w-full duration-300 text-lg font-medium text-center py-3 px-4 rounded-full 
    text-primary border-2 hover:bg-primary hover:text-white border-primary">
            {t("common:links.goToMyProfile")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ReservePopUpContentResult;

// <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
//           <button
//             type="button"
//             className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
//             onClick={() => setIsOpen(false)}>
//             Deactivate
//           </button>
//           <button
//             type="button"
//             className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
//             onClick={() => setIsOpen(false)}
//             ref={cancelButtonRef}>
//             Cancel
//           </button>
//         </div>
