// src/components/SVGAssets/CarerEPSHLogo.js

import React from "react";

const CarerEPSHLogo = ({ color = "currentColor", className = "" }) => {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 878.4 254"
      style={{ enableBackground: "new 0 0 878.4 254" }}
      xmlSpace="preserve"
      fill={color} // This sets the color of the svg elements
    >
      <g>
        <g>
          <path
            d="M537.3,201.5h-26.6c-0.5,0-40.6-1.6-40.6-44.9s40-44.9,40.4-44.9h26.7v10.1h-26.6c-1.1,0-30.4,1.4-30.4,34.8
			s29.3,34.7,30.6,34.8h26.4L537.3,201.5L537.3,201.5z"
          />
          <polygon points="634.6,201.6 601.5,111.8 611.7,111.8 644.8,201.6" />
          <polygon points="589.4,135.1 565,201.6 575.4,201.6 594.6,148.6" />
          <path
            d="M680.8,201.6h-10.1v-44.4h50.5c2-0.4,11.1-3.3,11.1-17.7s-9.1-17.2-11.2-17.7h-50.6v-10.1l51.9,0.1
			c6.9,1,20.1,8.2,20.1,27.8s-13.1,26.7-20.1,27.8l-0.8,0.1h-40.8L680.8,201.6L680.8,201.6z"
          />
          <rect x="779.6" y="111.7" width="66.4" height="10.1" />
          <rect x="779.6" y="191.4" width="66.4" height="10.1" />
          <rect x="779.6" y="151.6" width="58.3" height="10.1" />
          <polygon points="731.7,201.6 709.8,164.4 717.7,159.6 741.9,201.6" />
          <path
            d="M476.4,82.3c-0.6,1.1-1.2,2.1-1.8,2.9c-0.6,0.9-1.2,1.7-2,2.6c-0.5,0.6-0.9,0.9-1.3,1.1c-0.4,0.2-0.9,0.1-1.3-0.2
			c-0.4-0.3-0.6-0.7-0.6-1.2s0.3-1,0.7-1.5c0.7-0.8,1.3-1.6,1.9-2.4c0.6-0.8,1.1-1.8,1.6-2.9c0.3-0.6,0.6-1,0.9-1.3
			c0.4-0.2,0.8-0.2,1.4,0.1c0.5,0.2,0.8,0.6,0.8,1C477,81,476.8,81.6,476.4,82.3L476.4,82.3z M481.4,75c0,1.2-0.2,2.1-0.7,2.6
			s-1.4,0.8-2.6,0.8h-4.4c-1.3,0-2.1-0.2-2.6-0.8c-0.4-0.5-0.7-1.4-0.7-2.6V55.5c0-1.2,0.2-2.1,0.7-2.6c0.5-0.5,1.3-0.8,2.6-0.8h4.4
			c1.2,0,2.1,0.2,2.6,0.8c0.5,0.5,0.7,1.4,0.7,2.6V75z M478.4,55.8c0-0.5-0.2-0.7-0.5-0.7H474c-0.3,0-0.5,0.2-0.5,0.7v7.7h4.9
			L478.4,55.8L478.4,55.8z M478.4,66.4h-4.9v8.2c0,0.2,0.1,0.4,0.1,0.5s0.2,0.2,0.3,0.2h3.9c0.4,0,0.5-0.2,0.5-0.6V66.4L478.4,66.4z
			 M481.2,82.8c-0.4-0.7-0.5-1.2-0.5-1.6s0.4-0.7,0.9-1c0.5-0.2,1-0.3,1.3-0.2c0.4,0.1,0.7,0.6,1,1.3c0.4,0.8,0.7,1.6,1,2.4
			c0.3,0.8,0.7,1.7,1,2.6c0.3,0.8,0.4,1.4,0.2,1.8c-0.1,0.4-0.5,0.7-1.2,0.8c-0.5,0.1-1,0-1.2-0.2s-0.6-0.8-0.8-1.6
			c-0.3-0.9-0.6-1.7-0.8-2.3C481.9,84.2,481.6,83.5,481.2,82.8L481.2,82.8z M493.7,55.3c-0.4,1.2-0.8,2.4-1.2,3.3
			c-0.4,1-0.9,1.9-1.5,2.7c-0.6,0.8-1.3,1.6-2.2,2.3c-0.9,0.7-1.9,1.5-3.1,2.3c-0.9,0.5-1.5,0.7-1.9,0.6s-0.7-0.3-0.9-0.6
			c-0.6-1-0.3-1.8,1-2.6c1-0.5,1.9-1.1,2.6-1.6c0.7-0.6,1.4-1.2,1.9-1.8c0.5-0.6,1-1.3,1.4-2.1s0.7-1.6,1-2.5h-5
			c-1.2,0-1.7-0.5-1.7-1.5c0-0.5,0.1-0.9,0.5-1.1c0.3-0.3,0.7-0.4,1.3-0.4h16.1c1.2,0,2.1,0.3,2.8,1c0.6,0.6,0.9,1.5,0.9,2.6
			c-0.1,1.1-0.1,2.2-0.2,3.3c-0.1,1-0.3,2.1-0.5,3c-0.1,0.6-0.3,1.1-0.5,1.5c-0.2,0.4-0.5,0.8-0.9,1.1s-0.9,0.5-1.4,0.7
			c-0.6,0.1-1.3,0.2-2.1,0.2c-0.8,0-1.6-0.1-2.6-0.2c-1-0.1-2-0.3-3.3-0.6c-0.8-0.2-1.4-0.5-1.7-0.8c-0.3-0.3-0.4-0.8-0.2-1.4
			c0.1-0.5,0.4-0.9,0.8-1c0.4-0.1,1-0.1,1.9,0.1c0.9,0.2,1.8,0.4,2.6,0.6s1.5,0.3,2.1,0.3c1,0,1.8-0.5,2.1-1.3s0.6-2.4,0.8-4.5
			c0-0.5-0.1-0.8-0.3-1.1s-0.5-0.4-0.9-0.4H493.7L493.7,55.3z M504.7,75.1c0,1.2-0.2,2.1-0.7,2.6c-0.5,0.5-1.4,0.8-2.6,0.8h-14.2
			c-1.3,0-2.1-0.3-2.6-0.8c-0.5-0.5-0.7-1.4-0.7-2.7v-4.9c0-1.2,0.2-2.1,0.7-2.6c0.5-0.5,1.3-0.8,2.6-0.8h14.1
			c1.3,0,2.2,0.3,2.7,0.8c0.5,0.5,0.8,1.4,0.8,2.7V75.1L504.7,75.1z M501.7,70.5c0-0.5-0.2-0.7-0.5-0.7h-13.8
			c-0.3,0-0.5,0.2-0.5,0.7v4.3c0,0.2,0,0.4,0.1,0.5s0.2,0.2,0.3,0.2h13.8c0.4,0,0.5-0.2,0.5-0.7V70.5z M490.7,82.5
			c-0.3-0.5-0.4-1-0.4-1.4s0.3-0.8,0.8-1c0.8-0.5,1.6-0.2,2.3,1c0.4,0.7,0.9,1.5,1.4,2.4c0.5,0.9,1,1.9,1.4,3c0.2,0.5,0.3,1,0.1,1.3
			s-0.5,0.7-1.1,0.9c-0.4,0.1-0.7,0.1-1-0.1c-0.3-0.2-0.7-0.7-1-1.5c-0.4-0.9-0.8-1.8-1.3-2.6C491.5,83.7,491.1,83,490.7,82.5
			L490.7,82.5z M500.2,82.2c-0.5-0.6-0.7-1.1-0.6-1.5c0-0.4,0.3-0.8,0.7-1.1c0.8-0.6,1.5-0.3,2.3,0.7c0.8,1,1.5,1.9,2,2.7
			c0.6,0.8,1.2,1.7,1.8,2.8c0.4,0.6,0.6,1.1,0.6,1.5s-0.2,0.8-0.6,1.1c-0.5,0.3-0.9,0.4-1.4,0.2s-0.9-0.7-1.4-1.5
			c-0.5-0.8-1-1.5-1.6-2.4S500.9,83.1,500.2,82.2L500.2,82.2z"
          />
          <path
            d="M568.1,52.9c0.3,0.4,0.5,0.7,0.6,1c0.1,0.3,0.1,0.5,0,0.8h3.1c1.1,0,1.9,0.2,2.4,0.6c0.5,0.4,0.7,1.2,0.7,2.5v2.2
			c0,2.1-1,3.1-3.1,3.1h-2c0.3,0.4,0.5,0.7,0.8,1c0.2,0.3,0.5,0.6,0.7,1c0.4,0.6,0.5,1.1,0.5,1.5h2.6c0.5,0,0.8,0.1,1,0.3
			c0.2,0.2,0.4,0.6,0.4,1.2s-0.1,0.9-0.4,1.1c-0.2,0.2-0.6,0.3-1,0.3h-3.3v2.6h2.9c0.5,0,0.8,0.1,1,0.3c0.2,0.2,0.4,0.6,0.4,1.1
			c0,1-0.5,1.5-1.4,1.5h-2.9v2.6h2.9c0.5,0,0.8,0.1,1,0.3c0.2,0.2,0.4,0.6,0.4,1.1c0,1-0.5,1.4-1.4,1.4h-2.9v2.6h3.8
			c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.6,0.4,1.2c0,0.5-0.1,0.8-0.4,1c-0.3,0.2-0.6,0.3-1.1,0.3h-10.3v1.6c0,0.5-0.1,0.9-0.3,1.1
			c-0.2,0.2-0.6,0.3-1.1,0.3c-0.5,0-0.8-0.1-1-0.4c-0.2-0.2-0.3-0.6-0.3-1.1V74.5c-0.6,0.8-1.2,1-1.8,0.6c-0.1,2.3-0.4,4.3-0.7,6.1
			s-0.7,3.4-1.1,4.8c-0.3,0.8-0.6,1.3-1,1.5s-0.8,0.3-1.4,0.1c-0.5-0.1-0.7-0.4-0.8-0.9c-0.1-0.5,0-1.1,0.2-1.8
			c0.3-1,0.6-2.1,0.9-3.3s0.5-2.4,0.6-3.7c0.2-1.4,0.2-2.9,0.3-4.5s0.1-3.6,0.1-5.7v-10c0-1.2,0.2-2,0.6-2.5
			c0.4-0.4,1.2-0.7,2.5-0.7h5.6c-0.3-0.3-0.6-0.6-0.9-1s-0.6-0.7-0.9-1c-0.5-0.5-0.7-0.9-0.7-1.3c0-0.4,0.1-0.7,0.5-1
			c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.9,0.2,1.3,0.7c0.4,0.4,0.8,0.8,1.1,1.1S567.7,52.4,568.1,52.9L568.1,52.9z M559.9,68.1
			c0,0.7,0,1.4,0,2.1c0,0.7,0,1.3-0.1,2c0.8-1.1,1.5-2.4,2-3.6c0.5-1.3,1-2.8,1.5-4.6c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.2-0.4
			h-3.7V68.1L559.9,68.1z M559.9,60.2h11.4c0.3,0,0.5-0.2,0.5-0.5v-1.7c0-0.3-0.2-0.5-0.5-0.5h-10.8c-0.3,0-0.5,0.2-0.5,0.7V60.2
			L559.9,60.2z M564.4,69.3c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2V72h3.8v-2.6L564.4,69.3L564.4,69.3z M564.2,77.4h3.8v-2.6h-3.8
			V77.4z M564.2,82.8h3.8v-2.6h-3.8V82.8z M565.9,64.6c-0.1,0.3-0.2,0.6-0.3,1c-0.1,0.3-0.2,0.6-0.3,0.9h3.3c-0.3-0.4-0.5-0.7-0.7-1
			c-0.2-0.3-0.4-0.6-0.7-1c-0.5-0.6-0.6-1.1-0.3-1.5h-1c0.1,0.2,0.2,0.5,0.2,0.7C566,64,566,64.3,565.9,64.6L565.9,64.6z M582.5,82
			c0.4,0.3,0.6,0.7,0.6,1c0,0.4-0.2,0.8-0.6,1.3c-0.7,0.9-1.4,1.7-2.1,2.3c-0.7,0.6-1.5,1.3-2.5,1.9c-0.7,0.4-1.2,0.6-1.5,0.5
			c-0.4-0.1-0.7-0.3-0.9-0.7c-0.5-0.8-0.2-1.5,0.8-2.2c0.8-0.6,1.6-1.2,2.2-1.8s1.2-1.3,1.7-1.9c0.4-0.5,0.8-0.8,1.2-0.8
			C581.8,81.5,582.2,81.7,582.5,82L582.5,82z M592,78.3c0,1.1-0.3,1.9-0.8,2.4c-0.5,0.5-1.4,0.7-2.5,0.7h-8.2c-1.2,0-2-0.2-2.5-0.6
			c-0.5-0.4-0.7-1.2-0.7-2.4V62c0-1.2,0.2-2,0.7-2.4c0.5-0.4,1.3-0.6,2.5-0.6h1.8c0.5-1.2,0.9-2.4,1.1-3.5h-6c-0.5,0-0.8-0.1-1-0.3
			c-0.2-0.2-0.3-0.6-0.3-1.2c0-0.5,0.1-0.9,0.3-1.1c0.2-0.2,0.6-0.3,1-0.3H592c0.5,0,0.9,0.1,1.1,0.4s0.3,0.6,0.3,1.1
			c0,1-0.5,1.4-1.3,1.4h-5.6c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.5-0.4,1.1-0.6,1.7h3.4c1.2,0,2,0.2,2.5,0.7c0.5,0.4,0.8,1.2,0.8,2.4
			V78.3L592,78.3z M580.2,65.5h8.8v-3.2c0-0.5-0.3-0.7-0.9-0.7h-7c-0.6,0-0.9,0.2-0.9,0.7L580.2,65.5L580.2,65.5z M580.2,71.9h8.8
			v-3.8h-8.8V71.9z M589,74.6h-8.8v3.3c0,0.4,0.3,0.6,0.8,0.6h7c0.6,0,0.9-0.2,0.9-0.6L589,74.6L589,74.6z M593.4,88.4
			c-0.7,0.8-1.4,0.8-2.2-0.1c-0.6-0.6-1.3-1.3-1.9-1.8c-0.6-0.6-1.3-1.2-2.1-1.9c-0.5-0.5-0.9-0.9-1-1.3s-0.1-0.8,0.2-1.1
			c0.7-0.8,1.5-0.9,2.4-0.2c0.9,0.7,1.8,1.5,2.5,2.1c0.7,0.7,1.3,1.3,1.8,1.9s0.7,1,0.8,1.3C593.9,87.7,593.7,88,593.4,88.4
			L593.4,88.4z"
          />
          <path
            d="M659.9,66.6c-0.9,0.6-1.9,1.2-2.8,1.8c-1,0.6-2,1.1-3,1.7h14.3c1.2,0,2.1,0.3,2.6,0.8c0.5,0.5,0.7,1.4,0.7,2.7v12
			c0,1.2-0.2,2.1-0.7,2.7c-0.5,0.5-1.3,0.8-2.6,0.8h-19.9c-1.3,0-2.1-0.3-2.6-0.8c-0.5-0.5-0.7-1.5-0.7-2.7v-11
			c-0.9,0.4-1.8,0.8-2.6,1.1c-0.8,0.4-1.7,0.7-2.5,1c-1,0.4-1.7,0.6-2.1,0.5c-0.4-0.1-0.7-0.4-1-1c-0.2-0.7-0.1-1.2,0.1-1.5
			c0.2-0.3,0.8-0.6,1.6-0.9c1-0.3,2.2-0.8,3.4-1.3c1.3-0.5,2.6-1.1,3.9-1.7c1.3-0.6,2.7-1.3,4.1-2c1.4-0.7,2.7-1.5,4-2.2h-15.6
			c-0.5,0-0.9-0.1-1.2-0.4c-0.2-0.2-0.3-0.6-0.3-1.1c0-0.4,0.1-0.8,0.4-1c0.3-0.3,0.6-0.4,1.1-0.4h15.1v-5.4h-11.4
			c-0.6,0-1-0.1-1.2-0.4c-0.3-0.3-0.4-0.6-0.4-1.1s0.2-0.8,0.5-1c0.3-0.2,0.7-0.4,1.2-0.4h11.4v-2.6c0-1.2,0.5-1.7,1.5-1.7
			c0.5,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.7,0.5,1.3v2.6h9.7c0.5,0,0.8,0.1,1.2,0.4s0.5,0.5,0.6,0.9c0.7-0.6,1.3-1.1,1.9-1.6
			s1.1-1,1.5-1.5c0.5-0.5,1-0.8,1.5-0.9c0.5-0.1,0.9,0.1,1.3,0.6c0.3,0.4,0.4,0.8,0.3,1.2s-0.4,0.8-0.7,1.1
			c-1.3,1.3-2.8,2.6-4.5,3.9c-1.7,1.4-3.5,2.7-5.5,4.1h10.1c0.6,0,1,0.1,1.3,0.3c0.3,0.2,0.4,0.6,0.4,1.1c0,0.6-0.1,1-0.4,1.2
			c-0.3,0.2-0.7,0.3-1.3,0.3L659.9,66.6L659.9,66.6z M668.5,73.7c0-0.5-0.2-0.7-0.6-0.7h-19.1c-0.4,0-0.5,0.2-0.5,0.7v3.9h20.2V73.7
			L668.5,73.7z M668.5,80.5h-20.2v4.7c0,0.5,0.2,0.8,0.5,0.8H668c0.4,0,0.6-0.3,0.6-0.8L668.5,80.5L668.5,80.5z M658.9,63.7
			c1.4-0.9,2.6-1.8,3.9-2.7c1.2-0.9,2.4-1.8,3.5-2.6h-9.4v5.4L658.9,63.7L658.9,63.7z"
          />
          <path
            d="M760.5,56.3c0.5,0,0.9,0.2,1.2,0.5c0.2,0.3,0.4,0.7,0.4,1.1c0,0.4-0.1,0.8-0.3,1.1s-0.6,0.5-1.2,0.5h-21.5
			c-0.1,0.8-0.3,1.5-0.5,2.3c-0.2,0.7-0.4,1.5-0.5,2.2h16.2c0.8,0,1.5,0.1,2.1,0.4s1,0.6,1.3,1s0.5,0.9,0.5,1.4
			c0.1,0.5,0,1.1-0.2,1.6c-0.4,1.1-0.9,2.1-1.4,3c-0.5,1-1.1,1.9-1.8,2.8s-1.4,1.8-2.2,2.7c-0.8,0.9-1.7,1.7-2.7,2.6
			c1,0.6,1.9,1.3,2.9,1.9c1,0.6,2,1.2,3,1.7c1,0.5,2,1,2.9,1.5c0.9,0.5,1.8,0.8,2.6,1.2c0.8,0.4,1.3,0.8,1.5,1.1
			c0.2,0.4,0.1,0.8-0.1,1.2c-0.3,0.5-0.6,0.8-1,0.9c-0.4,0.1-1,0-1.7-0.4c-2-0.8-4.1-1.7-6.3-2.9c-2.2-1.1-4.3-2.5-6.4-4
			c-2,1.4-4.2,2.8-6.6,3.9c-2.5,1.2-5.2,2.2-8.3,3c-0.7,0.2-1.3,0.2-1.7-0.1s-0.6-0.6-0.7-1.1c-0.1-0.6,0.1-1,0.3-1.2
			c0.3-0.2,0.8-0.4,1.6-0.6c1.3-0.4,2.5-0.8,3.6-1.2c1.1-0.4,2.2-0.9,3.2-1.3c1-0.5,2-1,3-1.6c1-0.6,1.9-1.2,2.9-1.9
			c-1.7-1.5-3.2-3.2-4.5-4.9c-1.3-1.7-2.5-3.7-3.7-6c-0.6,1.6-1.2,3.2-1.8,4.6s-1.3,2.8-2,4.1c-0.7,1.3-1.5,2.6-2.4,3.8
			c-0.9,1.2-1.8,2.4-2.8,3.6c-0.5,0.6-1,1-1.4,1.1s-0.9,0-1.5-0.4c-0.3-0.3-0.5-0.7-0.5-1.1s0.3-1,0.8-1.6c1-1.2,2-2.4,2.9-3.7
			s1.7-2.5,2.4-3.8c0.7-1.3,1.4-2.6,2-4.1s1.2-2.9,1.6-4.5c0.4-1.3,0.8-2.5,1.1-3.8s0.6-2.4,0.8-3.7h-9.7c-0.6,0-1-0.2-1.2-0.5
			c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.7,0.3-1.1c0.2-0.3,0.6-0.5,1.2-0.5h10.3c0.1-0.6,0.2-1.3,0.2-1.9c0.1-0.7,0.2-1.4,0.2-2
			c0.1-0.9,0.3-1.5,0.7-1.8c0.3-0.3,0.8-0.4,1.3-0.4c0.6,0.1,1,0.3,1.2,0.6s0.2,0.9,0.2,1.6c-0.2,1.4-0.3,2.6-0.5,3.9L760.5,56.3
			L760.5,56.3z M739.2,67.2c1,2,2.2,3.9,3.4,5.6s2.8,3.4,4.6,4.9c1.9-1.5,3.4-3,4.5-4.6c1.2-1.5,2.1-3.1,2.8-4.6
			c0.3-0.6,0.4-1,0.2-1.1c-0.2-0.1-0.6-0.2-1.3-0.2L739.2,67.2L739.2,67.2z"
          />
          <path
            d="M818.9,70.6c0.4,0.5,0.7,0.9,1,1.3c0.3,0.4,0.5,0.8,0.8,1.2c0.1,0.2,0.2,0.4,0.2,0.5s0.1,0.3,0.1,0.4h5.3v-4.6h-15
			c-0.7,0-1.1-0.1-1.3-0.4c-0.2-0.3-0.4-0.6-0.4-1.1c0-0.5,0.1-0.9,0.4-1.2s0.7-0.3,1.3-0.3h15v-2.3h-12.9c-0.7,0-1.1-0.1-1.3-0.4
			c-0.2-0.3-0.4-0.6-0.4-1.1c0-0.5,0.1-0.9,0.4-1.2s0.7-0.3,1.3-0.3h12.9v-2.2h-14.2c-0.7,0-1.1-0.1-1.3-0.4
			c-0.2-0.3-0.4-0.6-0.4-1.1c0-0.5,0.1-0.9,0.4-1.2s0.7-0.3,1.3-0.3h7.5c-0.3-0.4-0.5-0.8-0.8-1.1c-0.2-0.4-0.5-0.7-0.8-1.1
			c-0.6-0.8-0.5-1.4,0.2-2c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1.2,0.7s0.7,0.9,1,1.3c0.3,0.4,0.5,0.8,0.8,1.2
			c0.3,0.7,0.5,1.2,0.3,1.5h10.8c0.5-0.6,0.8-1.2,1.1-1.8c0.3-0.6,0.6-1.2,0.9-1.8c0.3-0.5,0.6-0.9,0.9-1c0.3-0.1,0.8-0.1,1.2,0.1
			c0.4,0.2,0.7,0.5,0.8,0.9c0.1,0.4,0.1,0.8-0.2,1.4c-0.2,0.6-0.6,1.3-1.1,2.2h6.5c0.7,0,1.1,0.1,1.4,0.3s0.4,0.6,0.4,1.2
			c0,0.5-0.1,0.9-0.4,1.1s-0.7,0.3-1.4,0.3h-14.1v2.2h12.9c0.7,0,1.1,0.1,1.4,0.3s0.4,0.6,0.4,1.2s-0.1,0.9-0.4,1.1
			s-0.7,0.3-1.4,0.3h-12.9v2.3h14.9c0.7,0,1.1,0.1,1.4,0.3s0.4,0.6,0.4,1.2s-0.1,0.9-0.4,1.1s-0.7,0.3-1.4,0.3h-14.9V74h5.6
			c0.4-0.5,0.7-1.1,1-1.6l0.8-1.7c0.3-0.5,0.6-0.9,0.9-1c0.3-0.1,0.8-0.1,1.2,0.1c0.4,0.2,0.7,0.5,0.8,0.9s0.1,0.8-0.2,1.4
			c-0.2,0.5-0.5,1.2-0.9,1.9h6.5c0.7,0,1.1,0.1,1.4,0.3s0.4,0.6,0.4,1.2s-0.1,0.9-0.4,1.1c-0.3,0.2-0.7,0.3-1.4,0.3h-34.7
			c-0.7,0-1.1-0.1-1.3-0.4c-0.2-0.2-0.4-0.6-0.4-1.1c0-0.5,0.1-0.9,0.4-1.2c0.2-0.2,0.7-0.3,1.3-0.3h7.1c-0.2-0.3-0.4-0.5-0.6-0.8
			s-0.4-0.5-0.6-0.8c-0.6-0.8-0.5-1.4,0.2-2c0.3-0.3,0.7-0.4,1.1-0.4C818.1,69.9,818.5,70.1,818.9,70.6L818.9,70.6z M843,85.8
			c0,1.2-0.3,2-0.8,2.5s-1.4,0.7-2.6,0.7h-23.5c-1.2,0-2.1-0.2-2.6-0.7s-0.8-1.3-0.8-2.5v-3.7c0-1.2,0.3-2.1,0.8-2.6
			s1.4-0.7,2.6-0.7h23.5c1.2,0,2.1,0.2,2.6,0.7s0.8,1.3,0.8,2.6V85.8z M839.8,82.3c0-0.4-0.2-0.5-0.7-0.5h-22.6
			c-0.5,0-0.7,0.2-0.7,0.5v3.4c0,0.3,0.2,0.5,0.7,0.5h22.6c0.5,0,0.7-0.2,0.7-0.5V82.3z"
          />
        </g>
        <g>
          <path
            d="M108.8,115c-25.1,0-45.5-20.4-45.5-45.5S83.7,24,108.8,24s45.5,20.4,45.5,45.5S133.9,115,108.8,115z M108.8,32.1
			c-20.6,0-37.4,16.8-37.4,37.4s16.8,37.4,37.4,37.4s37.4-16.8,37.4-37.4S129.4,32.1,108.8,32.1z"
          />
          <path
            d="M329.8,115c-25.1,0-45.5-20.4-45.5-45.5S304.7,24,329.8,24c25.1,0,45.5,20.4,45.5,45.5S354.9,115,329.8,115z M329.8,32.1
			c-20.6,0-37.4,16.8-37.4,37.4s16.8,37.4,37.4,37.4c20.6,0,37.4-16.8,37.4-37.4S350.4,32.1,329.8,32.1z"
          />
          <path
            d="M214,134.5c-25.1,0-45.5-20.4-45.5-45.5s20.4-45.5,45.5-45.5s45.5,20.4,45.5,45.5S239,134.5,214,134.5z M214,51.6
			c-20.6,0-37.4,16.8-37.4,37.4s16.8,37.4,37.4,37.4s37.4-16.8,37.4-37.4S234.6,51.6,214,51.6z"
          />
          <path
            d="M39.4,198.4l-7.9-1.9l18.1-75.1h39.1l0.5,3.5c0.1,0.8,2.9,18.8,19.6,18.8s19.5-18.1,19.6-18.8l0.5-3.5h39.1l6.1,25.4
			l-7.9,1.9l-4.6-19.2h-25.9c-2.4,9.2-10.1,22.3-26.9,22.3s-24.5-13.1-26.9-22.3H56L39.4,198.4L39.4,198.4z"
          />
          <path
            d="M399.1,198.4l-16.6-68.9h-25.9c-2.4,9.2-10.1,22.3-26.9,22.3s-24.5-13.1-26.9-22.3H277l-4.6,19.2l-7.9-1.9l6.1-25.4h39.1
			l0.5,3.5c0.1,0.8,2.9,18.8,19.6,18.8s19.5-18.1,19.6-18.8l0.5-3.5H389l18.1,75.1L399.1,198.4L399.1,198.4z"
          />
          <path
            d="M227.3,197.7c3.5-3.7,7.3-5.5,12.1-4.7c6.9,1.2,11.2,8.4,9.3,15.4c-1.3,4.9-4.1,8.9-7.9,12c-3.9,3.2-8,6-12,9
			c-0.7,0.5-1.5,0.9-2.4,0.2c-4.8-3.8-9.8-7.3-14.4-11.3c-3.3-2.9-5.4-6.7-6.1-11.1c-0.9-5.4,1.6-10.6,6.3-13.1
			c4.4-2.4,10.1-1.5,14.1,2.3C226.6,196.8,226.9,197.2,227.3,197.7L227.3,197.7z"
          />
          <path
            d="M281.8,181.9c3.5-3.7,7.3-5.5,12.1-4.7c6.9,1.2,11.2,8.4,9.3,15.4c-1.3,4.9-4.1,8.9-7.9,12c-3.9,3.2-8,6-12,9
			c-0.7,0.5-1.5,0.9-2.4,0.2c-4.8-3.8-9.8-7.3-14.4-11.3c-3.3-2.9-5.4-6.7-6.1-11.1c-0.9-5.4,1.6-10.6,6.3-13.1
			c4.4-2.4,10.1-1.5,14.1,2.3C281.1,180.9,281.4,181.4,281.8,181.9L281.8,181.9z"
          />
          <path
            d="M144.6,220.7l-7.9-1.9l18.1-75.1h39.1l0.5,3.5c0.1,0.8,2.9,18.8,19.6,18.8s19.5-18.1,19.6-18.8l0.5-3.5h39.1l6.2,25.6
			l-7.9,1.9l-4.7-19.4h-25.9c-2.4,9.2-10.1,22.3-26.9,22.3s-24.5-13.1-26.9-22.3h-25.9L144.6,220.7L144.6,220.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CarerEPSHLogo;
