// ./src/components/Cards/EventCardProfile.js

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  formatEventDate,
  formatEventTime,
  getCurrentDateTimeInTimezone,
} from "../../utils/dateFuncs";

import ImageWithFallback from "../Image/ImageWithFallback";
import { getBubblePosition } from "./Functions";
import CardBubble from "./CardBubble";

import {
  CheckIcon,
  XMarkIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

import CalendarOutlinedIcon from "../Icons/CalendarOutlinedIcon";
import ClockOutlinedIcon from "../Icons/ClockOutlinedIcon";
import LocationOutlinedIcon from "../Icons/LocationOutlinedIcon";

import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

// event -> contains the event data
// location -> where the eventcard is located (Home page, Events page, User Profile page, ...)

const EventCardProfile = ({ registration, location, options = {} }) => {
  //
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const clockIcon = "/assets/icons/clock_outlined_icon.svg";
  const locationIcon = "/assets/icons/location_outlined_icon.svg";

  const defaultOptions = {
    layout: "horizontal", // Default layout
    showOnlineEventBtn: false, // Default visibility for online event button
  };
  const finalOptions = { ...defaultOptions, ...options };

  const [isMultisession, setIsMultisession] = useState(false); // Track if event is multisession

  // event entity
  const [event, setEvent] = useState();
  // remaining time to pay
  const [timeToPay, setTimeToPay] = useState();

  const calculateTimeRemaining = (regDeadline) => {
    // Parse registration date and deadline
    const registrationDeadline = new Date(regDeadline);

    // Get the current date and time
    const currentDate = new Date(getCurrentDateTimeInTimezone());

    // Check if the current time has passed the registration deadline
    if (currentDate > registrationDeadline) {
      // If the current time is past the registration deadline, return 0 hours remaining
      return { hours: 0, minutes: 0 };
    } else {
      // Calculate the remaining time in milliseconds
      const remainingTime = registrationDeadline - currentDate;

      // Convert remaining time to hours
      let remainingHours = Math.floor(remainingTime / (1000 * 60 * 60));

      // Initialize remaining minutes to 0
      let remainingMinutes = 0;

      // If there is less than one hour remaining, calculate the fraction of the hour as minutes
      if (remainingHours < 1) {
        // Calculate remaining minutes
        remainingMinutes = Math.ceil(remainingTime / (1000 * 60));
      }

      // Return an object containing both hours and minutes
      return { hours: remainingHours, minutes: remainingMinutes };
    }
  };

  useEffect(() => {
    if (registration) {
      // Check if event is a multisession event
      setEvent(registration.event);
      registration.event.isMultisession
        ? setIsMultisession(true)
        : setIsMultisession(false);

      if (registration.event.isPaid && registration.registrationDeadline) {
        const time = calculateTimeRemaining(registration.registrationDeadline);
        setTimeToPay(time);
      }
    }
  }, [registration]);

  const onCardClick = (event, eventId) => {
    event.preventDefault();
    // clickEventTracker("event", location, eventId);
    // Navigate to the new route
    navigate(`/events/${eventId}`);
  };

  const handleOnlineEventClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(url, "_blank");
  };

  const isDarkMode = document.body.classList.contains("dark");

  if (registration && event) {
    return (
      <Fragment>
        <article className="shadow-sm border rounded-2xl duration-300 hover:shadow-none overflow-hidden dark:bg-gray-900">
          <Link
            className="flex flex-col h-full"
            to={`/events/${event.id}`}
            onClick={(e) => onCardClick(e, event.id)}>
            {/* Image and Bubbles */}
            <div className="relative h-48 overflow-hidden flex-shrink-0 -z-10 ">
              {registration.bubbles &&
                registration.bubbles.length > 0 &&
                registration.bubbles.map((bubble, index) => (
                  <div
                    key={index}
                    className={`absolute z-10 ${getBubblePosition(
                      bubble.position
                    )}`}>
                    <CardBubble
                      bgColor={bubble.bgColor}
                      text={bubble.text}
                      icon={bubble.icon}
                    />
                  </div>
                ))}

              <ImageWithFallback
                src={event.image}
                alt={event.name}
                className="object-cover object-center h-full w-full"
              />
            </div>

            {/* Card content */}
            <div className="flex flex-col h-full">
              <div className="p-4 flex flex-col h-full justify-between">
                {/* Title and Description */}
                <div className="flex flex-col">
                  <h3 className="text-xl font-medium text-slate-800 dark:text-white line-clamp-2">
                    {event.name}
                  </h3>
                  <p className="text-gray-400 text-base mt-1 dark:text-gray-400 line-clamp-2">
                    {event.shortDescription}
                  </p>
                </div>

                {/* Details */}
                <div className="flex flex-col">
                  <div className="flex items-center mb-1 mt-3">
                    <CalendarOutlinedIcon
                      color={isDarkMode ? "#868b93" : "#5d6674"}
                      size="22"
                      className="text-black"
                      alt="Calendar Icon"
                    />

                    <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                      {event.startDate &&
                        formatEventDate(event.startDate, i18n.language)}
                    </p>
                  </div>
                  <div className="flex items-center mb-1">
                    <ClockOutlinedIcon
                      color={isDarkMode ? "#868b93" : "#5d6674"}
                      size="22"
                      className="text-black"
                      alt="Clock Icon"
                    />
                    <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                      {formatEventTime(event.startDate, i18n.language)} -
                      {formatEventTime(event.endDate, i18n.language)}
                    </p>
                  </div>
                  <div className="flex items-center mb-1">
                    <CurrencyDollarIcon
                      className="w-[22px] h-[22px] text-[#5d6674]"
                      alt="Location Icon"
                    />

                    <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                      {event.isPaid ? (
                        <Fragment>
                          <span className="text-slate-600">{event.price}</span>
                          <span className="text-slate-600"> HKD</span>
                        </Fragment>
                      ) : (
                        t("common:generic.free")
                      )}
                    </p>
                  </div>
                  {event.district && (
                    <div className="flex items-center">
                      <LocationOutlinedIcon
                        color={isDarkMode ? "#868b93" : "#5d6674"}
                        size="22"
                        className="text-black"
                        alt="Location Icon"
                      />

                      <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                        {i18n.language !== "en"
                          ? event.district.name
                          : event.district.englishName || ""}
                      </p>
                    </div>
                  )}

                  {event.isOnline && (
                    <div className="flex items-center">
                      <ComputerDesktopIcon className="w-[22px] h-[22px] text-[#5d6674]" />

                      {registration.status !== "approved" ? (
                        <p className="ml-3 text-base text-gray-700 capitalize line-clamp-1 dark:text-gray-300 ">
                          {t("common:events.onlineEvent")}
                        </p>
                      ) : (
                        event.onlineURL && (
                          <p
                            className="ml-3 text-base text-gray-700 capitalize line-clamp-1 dark:text-gray-300 underline"
                            onClick={(e) =>
                              handleOnlineEventClick(e, event.onlineURL)
                            }>
                            {t("common:events.onlineEventLink")}
                          </p>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`p-4 border-t 
              ${
                registration.status === "approved"
                  ? "bg-green-50 border-green-100"
                  : registration.status === "pending"
                  ? "bg-slate-50 border-slate-100"
                  : "bg-red-50 border-red-100"
              }`}>
                {event.isPaid && registration.status !== "approved" ? (
                  <div className="flex items-center gap-3">
                    {registration.status === "pending" ? (
                      <span className="w-8 h-8 bg-slate-400 border border-slate-400 rounded-full flex justify-center items-center">
                        <EllipsisHorizontalIcon className="w-5 h-5 text-white " />
                      </span>
                    ) : (
                      <span className="w-8 h-8 bg-red-500 border border-red-600 rounded-full flex justify-center items-center">
                        <XMarkIcon className="w-5 h-5 text-white " />
                      </span>
                    )}

                    <div className="flex text-slate-600 capitalize font-medium flex-grow">
                      {registration.status === "pending" ? (
                        <div className="flex flex-row justify-between flex-grow">
                          <span className="flex">payment pending</span>
                          <span className="flex">
                            {timeToPay.hours !== 0
                              ? timeToPay.hours + " hours left"
                              : timeToPay.minutes !== 0
                              ? timeToPay.minutes + " minutes left"
                              : "Expired"}
                          </span>
                        </div>
                      ) : (
                        <span>payment expired</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center gap-3">
                    <span className="w-8 h-8 bg-green-500 border border-green-600 rounded-full flex justify-center items-center">
                      <CheckIcon className="w-5 h-5 text-white" />
                    </span>
                    <span className="flex text-slate-600 capitalize font-medium ">
                      successfully registered
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Link>
        </article>
      </Fragment>
    );
  }
};

export default EventCardProfile;
