// src/pages/home/EventDetailsContent.js

import {
  BellAlertIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  MapPinIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigationNames, navigationURL } from "../../constants/navigation";
import { getPageNameFromURL } from "../../utils/navigationUtils";
import { Link, useNavigate } from "react-router-dom";
import ImageWithFallback from "../../components/Image/ImageWithFallback";
import {
  formatEventDate,
  formatEventTime,
  getCurrentDateTimeInTimezone,
} from "../../utils/dateFuncs";
import Session from "../../components/Events/Session";
import ReservePopUpPage from "../../components/Events/ReservePopUpPage";
import ReactGA from "react-ga4";

// const formatTextWithLineBreaksAndParagraphs = (text) => {
//   return text.split("\n").map((paragraph, index) => (
//     <p key={index} className="mb-3">
//       {paragraph}
//     </p>
//   ));
// };
const formatTextWithLineBreaksAndParagraphs = (text) => {
  // Function to safely escape HTML except for anchor tags
  const escapeHtmlExceptAnchors = (inputText) => {
    const div = document.createElement("div");
    div.textContent = inputText;
    return div.innerHTML.replace(/&lt;(\/?a.*?)&gt;/g, "<$1>");
  };

  // Split the text by line breaks and map to paragraphs
  return text.split("\n").map((paragraph, index) => {
    // Check if the paragraph contains an anchor tag
    if (/<a href=".*?".*?>.*?<\/a>/.test(paragraph)) {
      // If so, dangerously set its inner HTML to allow anchor tags
      return (
        <p
          key={index}
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: escapeHtmlExceptAnchors(paragraph),
          }}
        />
      );
    } else {
      // If not, render the paragraph as text
      return (
        <p key={index} className="mb-3">
          {paragraph}
        </p>
      );
    }
  });
};

const EventDetailsContent = ({
  event,
  loading,
  errorMessage,
  registrationInfo,
  loadingRegistrationInfo,
  user,
  setRegistrationInfo,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Reserve Pop Up State
  const [isOpen, setIsOpen] = useState(false);

  // Based on event info we determine the type of event
  // isMultisession = false - Single Event
  // isMultisession = true && allowsIndividualRegistration = false - Multisession Full Registration
  // isMultisession = true && allowsIndividualRegistration = true - Multisession Section Registration
  const [eventType, setEventType] = useState(null);
  // Single and full registration agree button
  const [isAgreeCBChecked, setIsAgreeCBChecked] = useState(false);
  // For the reservation popup we need to keep track of the event the user is registering to
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    if (!loading && event) {
      if (!event.isMultiSession) {
        setEventType("single");
      } else {
        setEventType(event.allowsIndividualRegistration ? "section" : "full");
      }
    }
    // eslint-disable-next-line
  }, [event]);

  // Process registrationInfo to allow or not registration to events

  const eventsPageName = getPageNameFromURL(
    navigationURL.EVENTS,
    navigationURL,
    navigationNames,
    t,
    "common:navigationMenu."
  );

  // Navigate to events on click
  const handleNavigateToEvents = () => {
    navigate(navigationURL.EVENTS);
  };

  const handleReserveSeatClick = (passedEvent) => {
    // Track clicking of event reservation button
    ReactGA.event({
      category: "Event Reservation",
      action: "Open Reservation Popup",
      label: `Event ID: ${passedEvent.id}`,
    });

    setSelectedEvent(passedEvent);
    setIsOpen(true);
  };

  if (loading) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && !event) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("errors:notFoundError", { entity: t("common:entities.events") })}
      </div>
    );
  }

  const checkIsClosed = (regEndDate) => {
    let isClosed = false;

    // if event is already not open return true
    if (event && event.status !== "open") {
      return true;
    }

    // Check if event is still open
    const currentDate = new Date(getCurrentDateTimeInTimezone());

    const registrationEndDate = new Date(regEndDate);

    // Dates to milliseconds since the Unix epoch
    const currentTimestamp = currentDate.getTime();
    const registrationEndTimestamp = registrationEndDate.getTime();

    // Compare timestamps
    if (currentTimestamp > registrationEndTimestamp) {
      isClosed = true;
    }
    // console.log(isClosed, currentDate, registrationEndDate);
    return isClosed;
  };

  const checkButtonMessage = (item) => {
    const msgAlreadyRegistered = t("common:events.alreadyRegistered");
    const msgOverlapping = t("common:events.overlapping");

    if (item.isAlreadyRegistered || item.isOverlapping) {
      const msg = item.isAlreadyRegistered
        ? msgAlreadyRegistered
        : msgOverlapping;
      return {
        isAllowed: false,
        message: msg,
      };
    } else {
      return { isAllowed: true };
    }
  };

  return (
    <div className="flex flex-col flex-grow ">
      {/* Reserve Pop Up */}
      <ReservePopUpPage
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        event={selectedEvent}
        eventType={eventType}
        checkButtonMessage={checkButtonMessage}
        registrationInfo={registrationInfo}
        loadingRegistrationInfo={loadingRegistrationInfo}
        setRegistrationInfo={setRegistrationInfo}
        fallbackImage={event.image}
      />
      {/* Navigation */}
      <div className="flex px-1 py-2">
        <button
          type="button"
          className="uppercase text-slate-500 font-medium flex gap-3 items-center"
          onClick={handleNavigateToEvents}>
          <ChevronLeftIcon className="w-6 h-6 text-slate-500 " />
          {eventsPageName}
        </button>
      </div>

      {/* Banner */}
      <div className="flex overflow-hidden rounded-2xl mt-4 h-52 sm:h-80 lg:h-96">
        <ImageWithFallback
          src={event.image}
          alt={event.name}
          className="object-cover object-center h-full w-full"
        />
      </div>

      {/* Main container (includes event info and summary column) */}
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-6 lg:mt-12">
        {/* Event Information Container */}
        <div className="px-3 lg:px-5 mb-8 flex-grow">
          {/* Category/ies */}
          <div className="flex flex-row gap-3">
            {event.categories.map((cat, index) => (
              <div
                key={index}
                className="uppercase text-slate-500 font-semibold tracking-wide">
                {i18n.language !== "en" ? cat.name : cat.englishName}
              </div>
            ))}
          </div>
          {/* Event Name */}
          <div className="font-bold text-4xl mt-1">{event.name}</div>
          {/* Event Description */}
          <div className="leading-7 mt-8 text-lg text-slate-600 whitespace-pre-line prose">
            {event.description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: event.description,
                }}></div>
            ) : (
              ""
            )}
          </div>

          {/* Sessions */}
          {eventType &&
            eventType !== "single" &&
            event.sessions.map((session, index) => (
              <Session
                key={index}
                event={event}
                session={session}
                handleReserveSeatClick={handleReserveSeatClick}
                type={eventType}
                loadingRegistrationInfo={loadingRegistrationInfo}
                registrationInfo={registrationInfo}
                checkButtonMessage={checkButtonMessage}
                user={user}
              />
            ))}

          {/* Separator */}
          <div>
            <hr className="h-px bg-slate-200 border-0 my-8"></hr>
          </div>

          {/* Organizer */}
          <div className="flex flex-col">
            <div className="uppercase text-slate-500 font-semibold tracking-wide">
              {t("common:events.organizer")}
            </div>
            {/* Organization name */}
            <div className="font-semibold text-3xl mt-1">
              {event.organization ? event.organization.name : ""}
            </div>
            <span className="text-slate-600 mt-2 text-lg">
              {event.organization ? event.organization.description : ""}
            </span>
          </div>

          {eventType === "single" && (
            <Fragment>
              {/* Separator */}
              <div>
                <hr className="h-px bg-slate-200 border-0 my-8"></hr>
              </div>
              {/* Location */}
              <div className="flex flex-col">
                <div className="uppercase text-slate-500 font-semibold tracking-wide">
                  {t("common:events.location")}
                </div>
                {!event.isOnline ? (
                  <Fragment>
                    {/* Location Address */}
                    <div className="font-semibold text-3xl mt-1">
                      {event.district
                        ? i18n.language !== "en"
                          ? event.district.name
                          : event.district.englishName
                        : ""}
                    </div>
                    <span className="text-slate-600 mt-2 text-lg">
                      {event.address ? event.address : ""}
                    </span>

                    {event.googleMapsEmbedURL && (
                      <iframe
                        title="map"
                        src={event.googleMapsEmbedURL}
                        className="w-full h-[500px] rounded-2xl  mt-8"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                    )}
                  </Fragment>
                ) : (
                  <div className="font-semibold text-3xl mt-1 capitalize">
                    {t("common:generic.online")}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>

        {/* Event Summary container */}
        <div className="w-full lg:w-96 shrink-0 mb-8">
          <div className="lg:sticky lg:top-28 border border-slate-200 shadow-sm rounded-2xl overflow-hidden">
            {/* Event Summary Header */}
            <div className="text-center py-6 font-semibold text-lg bg-white capitalize">
              {t("common:events.eventSummary")}
            </div>
            {/* Separator */}
            <hr className="h-px bg-slate-200 border-0"></hr>

            {eventType && eventType !== "section" ? (
              <Fragment>
                {/* Section Registration sections breakdown */}
                {eventType === "full" && (
                  <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
                    <div className="flex flex-col gap-11">
                      {event.sessions.map((session, index) => (
                        <div
                          key={index}
                          className="items-start inline-flex gap-5">
                          <span className="min-w-11 mt-1 text-center bg-primary/10 text-primary ring-1 ring-inset ring-primary/50 rounded-full py-0.5 px-2.5 text-xs font-medium">
                            S{index + 1}
                          </span>
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-3 items-center">
                              <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
                              <span>
                                {formatEventDate(
                                  session.startDate,
                                  i18n.language
                                )}
                              </span>
                            </div>

                            <div className="flex gap-3 items-center">
                              <ClockIcon className="w-7 h-7 text-slate-600" />
                              <span className="text-slate-600">
                                {formatEventTime(
                                  session.startDate,
                                  i18n.language
                                ) +
                                  " - " +
                                  formatEventTime(
                                    session.endDate,
                                    i18n.language
                                  )}
                              </span>
                            </div>

                            {/* District */}
                            {!session.isOnline ? (
                              (session.district || session.address) && (
                                <div className="flex gap-3 items-center">
                                  <MapPinIcon className="w-7 h-7 text-slate-600" />
                                  <span className="text-slate-800 font-semibold">
                                    {session.district
                                      ? i18n.language !== "en"
                                        ? session.district.name
                                        : session.district.englishName
                                      : ""}
                                  </span>
                                </div>
                              )
                            ) : (
                              <div className="flex gap-5 items-start">
                                <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
                                <div>
                                  <span className="text-slate-700 font-semibold text-lg capitalize">
                                    {t("common:events.onlineEvent")}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Participants */}
                    <div className="flex gap-5 items-center mt-8">
                      <UserGroupIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-700 font-semibold">
                          {event.maxSeats - event.remainingSeats}
                        </span>
                        <span className="text-slate-500">
                          /{event.maxSeats}
                        </span>
                        <span className="text-slate-600 capitalize">
                          {" "}
                          {t("common:generic.seats")}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {/****************** */}

                {/* Single event main details */}

                {eventType === "single" && (
                  <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
                    {/* Day */}
                    <div className="flex gap-5 items-center">
                      <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {formatEventDate(event.startDate, i18n.language)}
                      </span>
                    </div>

                    {/* Time */}
                    <div className="flex gap-5 items-center">
                      <ClockIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {formatEventTime(event.startDate, i18n.language) +
                          " - " +
                          formatEventTime(event.endDate, i18n.language)}
                      </span>
                    </div>

                    {/* Participants */}
                    <div className="flex gap-5 items-center">
                      <UserGroupIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-700 font-semibold">
                          {event.maxSeats - event.remainingSeats}
                        </span>
                        <span className="text-slate-500">
                          /{event.maxSeats}
                        </span>
                        <span className="text-slate-600 capitalize">
                          {" "}
                          {t("common:generic.seats")}
                        </span>
                      </div>
                    </div>

                    {/* Location */}
                    {!event.isOnline ? (
                      event.district && (
                        <div className="flex gap-5 items-center">
                          <MapPinIcon className="w-7 h-7 text-slate-600" />
                          <span className="text-slate-600">
                            {event.district
                              ? i18n.language !== "en"
                                ? event.district.name
                                : event.district.englishName
                              : ""}
                          </span>
                        </div>
                      )
                    ) : (
                      <div className="flex gap-5 items-center">
                        <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
                        <span className="text-slate-600 capitalize">
                          {t("common:events.onlineEvent")}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* Separator */}
                <hr className="h-px bg-slate-200 border-0"></hr>

                {/* Event Fee Header */}
                <div className="flex gap-3 text-center justify-center py-6 font-semibold text-lg bg-white">
                  <CurrencyDollarIcon className="w-7 h-7  text-slate-600" />
                  <span className="capitalize">
                    {t("common:events.eventFee")}
                  </span>
                </div>

                {/* Separator */}
                <hr className="h-px bg-slate-200 border-0"></hr>

                {/* Payment information */}
                <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
                  <div className="flex gap-5 items-center justify-between">
                    <span className="text-slate-800 font-semibold capitalize">
                      {t("common:events.carerEPSMembers")}
                    </span>
                    <div>
                      {event.isPaid && event.price ? (
                        <Fragment>
                          <span className="text-slate-600">{event.price}</span>
                          <span className="text-slate-600"> HKD</span>
                        </Fragment>
                      ) : (
                        <span className="text-slate-600">
                          {t("common:generic.free")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {event.isPaid &&
                (event.paymentNote || event.offlinePaymentTimeLimit) ? (
                  <Fragment>
                    {/* Separator */}
                    <hr className="h-px bg-slate-200 border-0"></hr>

                    {/* Payment Instructions Header */}
                    {!event.paymentNote && !event.offlinePaymentTimeLimit}
                    <div className="flex gap-3 text-center justify-center py-6 font-semibold text-lg bg-white capitalize">
                      <BellAlertIcon className="w-7 h-7 text-slate-600 shrink-0" />
                      {t("common:events.paymentInstructions")}
                    </div>

                    {/* Separator */}
                    <hr className="h-px bg-slate-200 border-0"></hr>

                    {/* Single event main details */}
                    <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
                      {/* Day */}
                      <div className="flex flex-col gap-5 items-center justify-between">
                        {event.paymentNote && (
                          <div className="text-slate-600 font-normal">
                            {formatTextWithLineBreaksAndParagraphs(
                              event.paymentNote
                            )}
                          </div>
                        )}

                        {event.offlinePaymentTimeLimit && (
                          <p className="text-slate-800 font-bold">
                            {t("common:events.paymentDeadline", {
                              hours: event.offlinePaymentTimeLimit,
                            })}
                          </p>
                        )}
                      </div>
                    </div>
                  </Fragment>
                ) : null}

                {/* Separator */}
                <hr className="h-px bg-slate-200 border-0"></hr>
                {/* Single and full agreement and button */}
                <div
                  className={`p-6 flex flex-col transition-all duration-300 ${
                    ((isAgreeCBChecked &&
                      !loadingRegistrationInfo &&
                      registrationInfo &&
                      checkButtonMessage(registrationInfo[0]).isAllowed) ||
                      (!user && isAgreeCBChecked)) &&
                    !checkIsClosed(event.registrationEndDate)
                      ? "bg-primary/10"
                      : "bg-slate-100"
                  }`}>
                  {/* Disclaimer checkbox */}
                  {((!loadingRegistrationInfo &&
                    registrationInfo &&
                    checkButtonMessage(registrationInfo[0]).isAllowed ===
                      true) ||
                    !user) &&
                  !checkIsClosed(event.registrationEndDate) ? (
                    <div className="flex">
                      <div className="flex h-6 items-center">
                        <input
                          id="enableAgree"
                          onChange={(e) =>
                            setIsAgreeCBChecked(e.target.checked)
                          }
                          checked={isAgreeCBChecked}
                          name="enableAgree"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="enableAgree"
                          className="text-base text-gray-500 leading-6">
                          {t("common:events.agreeToTerms")}{" "}
                          <Link to="/" className="underline font-semibold">
                            {t("common:events.termsAndConditions")}
                          </Link>
                        </label>
                      </div>
                    </div>
                  ) : checkIsClosed(event.registrationEndDate) ? (
                    <div className="text-center flex flex-col">
                      <ExclamationTriangleIcon className="w-7 h-7 text-slate-600 mx-auto mb-2" />
                      <span className="text-center text-slate-700 font-semibold">
                        {t("common:events.closed")}
                      </span>
                    </div>
                  ) : (
                    !loadingRegistrationInfo &&
                    registrationInfo && (
                      <div className="text-center flex flex-col">
                        <ExclamationTriangleIcon className="w-7 h-7 text-slate-600 mx-auto mb-2" />
                        <span className="text-center text-slate-700 font-semibold">
                          {checkButtonMessage(registrationInfo[0]).message}
                        </span>
                      </div>
                    )
                  )}

                  {/* Reserve button */}
                  <div className="mt-8">
                    <button
                      type="button"
                      disabled={
                        checkIsClosed(event.registrationEndDate) ||
                        !isAgreeCBChecked ||
                        (!loadingRegistrationInfo &&
                          registrationInfo &&
                          checkButtonMessage(registrationInfo[0]).isAllowed ===
                            false)
                      }
                      onClick={() => handleReserveSeatClick(event)}
                      className={`transition w-full duration-300 text-lg text-center py-3 px-4 rounded-full 
                      ${
                        (isAgreeCBChecked &&
                          !loadingRegistrationInfo &&
                          registrationInfo &&
                          checkButtonMessage(registrationInfo[0]).isAllowed) ||
                        (isAgreeCBChecked && !user)
                          ? "bg-primary hover:bg-primary-hover text-white border-primary hover:border-primary-hover"
                          : "bg-slate-300 text-slate-500"
                      }
                      `}>
                      {t("common:events.reserveSeat")}
                    </button>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {/* Section Registration sections breakdown */}
                <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
                  <div className="flex flex-col gap-11">
                    {event.sessions.map((session, index) => (
                      <div
                        key={index}
                        className="items-start inline-flex gap-5">
                        <span className="min-w-11 mt-1 text-center bg-primary/10 text-primary ring-1 ring-inset ring-primary/50 rounded-full py-0.5 px-2.5 text-xs font-medium">
                          S{index + 1}
                        </span>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-3 items-center">
                            <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
                            <span>
                              {formatEventDate(
                                session.startDate,
                                i18n.language
                              )}
                            </span>
                          </div>

                          <div className="flex gap-3 items-center">
                            <ClockIcon className="w-7 h-7 text-slate-600" />
                            <span className="text-slate-600">
                              {formatEventTime(
                                session.startDate,
                                i18n.language
                              ) +
                                " - " +
                                formatEventTime(session.endDate, i18n.language)}
                            </span>
                          </div>

                          {/* District */}
                          {!session.isOnline ? (
                            (session.district || session.address) && (
                              <div className="flex gap-3 items-center">
                                <MapPinIcon className="w-7 h-7 text-slate-600" />
                                <span className="text-slate-800 font-semibold">
                                  {session.district
                                    ? i18n.language !== "en"
                                      ? session.district.name
                                      : session.district.englishName
                                    : ""}
                                </span>
                              </div>
                            )
                          ) : (
                            <div className="flex gap-5 items-start">
                              <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
                              <div>
                                <span className="text-slate-700 font-semibold text-lg capitalize">
                                  {t("common:events.onlineEvent")}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsContent;
