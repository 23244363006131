// src/context/DiscountFilterDataContext.js

import { createContext, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { endPoint } from "../constants/endPoints";
import axiosInstance from "../lib/axiosInstance";

// Carer Type Context
import { useCarerTypeState } from "./CarerTypeContext";

const DiscountFilterDataContext = createContext();

const DiscountFilterDataProvider = ({ children }) => {
  ///////
  // Carer Type context data (used for current event filter options)
  const { selectedCarerTypes } = useCarerTypeState();

  const { t, i18n } = useTranslation();

  const [discountFilterData, setDiscountFilterData] = useState(null);
  const [lastFetched, setLastFetched] = useState(0);

  const [fetchedData, setFetchedData] = useState(null);

  const [isLoadingDiscountFilterData, setIsLoadingDiscountFilterData] =
    useState(false);
  const [error, setError] = useState(null);
  const [discountFilterDataReady, setDiscountFilterDataReady] = useState(false);

  // State for user selections
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // Current filter (collects all the selected filter options - to be used when submitting for new search)
  const [currentFilter, setCurrentFilter] = useState(null);
  // State to safe latest event "search" filter
  const [prevFilter, setPrevFilter] = useState(null);

  const getCurrentFilterOptions = () => {
    const filter = {};

    // Check if Category is selected and not equal to 'all'
    if (selectedCategory && selectedCategory.id !== "all") {
      filter.categoryId = selectedCategory.id;
    }

    // Check if District is selected and not equal to 'all'
    if (selectedLocation && selectedLocation.id !== "all") {
      filter.districtId = selectedLocation.id;
    }

    // Check if Carer Type is selected and not null
    if (selectedCarerTypes && selectedCarerTypes.length > 0) {
      // Include carerType only when it's an array of values
      filter.carerTypeIds = selectedCarerTypes.map((type) => type.id).join(",");
    }

    return filter;
  };

  const clearCurrentFilter = () => {
    setCurrentFilter({});
    setSelectedCategory(discountFilterData.categories[0]);

    setSelectedLocation(discountFilterData.districts[0]);
  };

  // Function to update currentFilter when any of the fields is changed
  useEffect(() => {
    const newFilter = getCurrentFilterOptions();
    setCurrentFilter(newFilter);
    // eslint-disable-next-line
  }, [selectedCategory, selectedLocation]);

  const allOption = { id: "all", name: t("common:dropdown.all") };

  // Function to simplify and localize data
  const localizeData = (data, enFieldName, tcFieldName) =>
    data.map((item) => ({
      id: item.id,
      name: i18n.language === "en" ? item[enFieldName] : item[tcFieldName],
    }));

  // Fetch Event Filter Data manually
  const fetchDiscountFilterData = async () => {
    if (!discountFilterData || Date.now() - lastFetched > 3600000) {
      // refresh data if it does not exist or it is older than 1 hour
      setIsLoadingDiscountFilterData(true);
      try {
        const response = await axiosInstance.get(endPoint.DISCOUNTS_FILTERS);
        const { districts, categories, organizations } =
          response.data.data?.filterData;

        setFetchedData(response.data.data?.filterData);

        // Set all the data individually (category , location )
        setSelectedCategory(selectedCategory || allOption);
        setSelectedLocation(selectedLocation || allOption);

        setCurrentFilter(getCurrentFilterOptions());
        setPrevFilter(getCurrentFilterOptions());

        setDiscountFilterData({
          districts: [
            allOption,
            ...localizeData(districts, "englishName", "name"),
          ],
          categories: [
            allOption,
            ...localizeData(categories, "englishName", "name"),
          ],
        });

        setLastFetched(Date.now());
      } catch (error) {
        console.error("Failed to fetch data:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //  AXIOS ERROR
          setError(error.response.statusText);
        } else if (error.request) {
          // The request was made but no response was received
          setError(t("errors:networkError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(t("errors:unexpectedError"));
        }
      } finally {
        setIsLoadingDiscountFilterData(false);
      }
    }
  };

  // Use effect to define when the current filter and the data have content already
  // After setting discountFilterData and initial selections:
  useEffect(() => {
    if (
      !isLoadingDiscountFilterData &&
      discountFilterData &&
      selectedCategory &&
      selectedLocation &&
      currentFilter
    ) {
      setDiscountFilterDataReady(true);
    } else {
      setDiscountFilterDataReady(false);
    }
  }, [
    isLoadingDiscountFilterData,
    discountFilterData,
    selectedCategory,
    selectedLocation,
    currentFilter,
  ]);

  const updateLocalizedSelections = (
    setter,
    localizedData,
    fieldToMatch = "id"
  ) => {
    setter((prevSelected) => {
      if (!prevSelected) return null;
      const newSelection = localizedData.find(
        (d) => d[fieldToMatch] === prevSelected[fieldToMatch]
      );
      return newSelection || prevSelected;
    });
  };

  useEffect(() => {
    if (fetchedData) {
      const localizedDistricts = localizeData(
        fetchedData.districts,
        "englishName",
        "name"
      );
      const localizedCategories = localizeData(
        fetchedData.categories,
        "englishName",
        "name"
      );

      // Update your state with the newly localized data
      setDiscountFilterData({
        districts: [allOption, ...localizedDistricts], // Preserving the "All" option
        categories: [allOption, ...localizedCategories],
      });

      // Update selected options to correct language
      updateLocalizedSelections(setSelectedLocation, localizedDistricts);
      updateLocalizedSelections(setSelectedCategory, localizedCategories);
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <DiscountFilterDataContext.Provider
      value={{
        isLoadingDiscountFilterData,
        discountFilterData,
        currentFilter,
        fetchDiscountFilterData,
        discountFilterDataReady,
        error,
        selectedCategory,
        setSelectedCategory,
        selectedLocation,
        setSelectedLocation,
        clearCurrentFilter,
        prevFilter,
        setPrevFilter,
      }}>
      {isLoadingDiscountFilterData ? (
        <div className="flex flex-col text-center justify-center -mt-10 items-center h-screen w-full text-gray-500">
          {t("common:loading")}
        </div>
      ) : (
        children
      )}
    </DiscountFilterDataContext.Provider>
  );
};

const useDiscountFilterState = () => {
  const context = useContext(DiscountFilterDataContext);
  if (context === undefined) {
    throw new Error(
      "useDiscountFilterDataContext must be used within a DiscountFilterDataProvider"
    );
  }
  return context;
};

export { DiscountFilterDataProvider, useDiscountFilterState };
