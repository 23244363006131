import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({
  classes = "",
  tabs,
  selectedTab,
  handleTabChange,
  statusCounts,
  loadingStatusCounts,
}) => {
  const navigate = useNavigate();
  // Handle tab selection
  const handleTabClick = (tab) => {
    handleTabChange(tab);
    navigate(tab);
  };

  const sharedClasses =
    "flex whitespace-nowrap py-2 px-7 text-md font-medium capitalize rounded-full cursor-pointer";

  return (
    <div className={classes}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full capitalize rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
          value={selectedTab}
          onChange={(e) => handleTabClick(e.target.value)}>
          {Object.keys(tabs).map((tabKey) => (
            <option key={tabKey} value={tabs[tabKey].path}>
              {tabs[tabKey].name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex overflow-hidden">
        <div className="hidden sm:inline-flex bg-slate-100/50 ring-1 ring-inset ring-slate-100/90 rounded-full p-2 overflow-x-auto">
          <nav className="flex space-x-2" aria-label="Tabs">
            {Object.keys(tabs).map((tabKey) => (
              <NavLink
                key={tabKey}
                to={tabs[tabKey].path}
                className={({ isActive }) =>
                  isActive
                    ? `${sharedClasses} text-slate-900 bg-white ring-1 ring-slate-100 shadow-sm`
                    : `${sharedClasses} border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 transition-all duration-300 hover:bg-white hover:ring-1 hover:ring-slate-100 hover:shadow-sm`
                }
                aria-current={
                  selectedTab === tabs[tabKey].path ? "page" : undefined
                }>
                {tabs[tabKey].name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Tabs;
