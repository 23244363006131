// src/components/Icons/LocationOutlinedIcon.js

import React from "react";

const LocationOutlinedIcon = ({
  color = "#3C4146",
  size = "22",
  className = "",
  alt = "icon",
}) => (
  <svg
    className={className}
    role="img"
    aria-label={alt}
    width={size + "px"} // Ensure size is correctly applied
    height={size + "px"} // Same for height
    viewBox="0 0 46 46" // Adjust viewBox if necessary
    fill={color}
    focusable="false" // Prevent SVG from being focusable in IE
    style={{ outline: "none" }} // CSS to ensure it's not focusable in other browsers
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23,27.1c-4.1,0-7.4-3.3-7.4-7.4s3.3-7.4,7.4-7.4s7.4,3.3,7.4,7.4C30.4,23.8,27.1,27.1,23,27.1z M23,15.2
    c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C27.5,17.2,25.5,15.2,23,15.2z"
    />
    <path
      d="M23,43.6c-2.8,0-5.7-1.1-7.9-3.2C9.4,34.9,3.2,26.3,5.6,15.9C7.7,6.6,15.9,2.4,23,2.4c0,0,0,0,0,0
    c7.1,0,15.3,4.2,17.4,13.6c2.3,10.3-3.9,19-9.5,24.4C28.7,42.5,25.8,43.6,23,43.6z M23,5.3c-5.6,0-12.7,3-14.6,11.3
    c-2.1,9,3.6,16.8,8.7,21.7c3.3,3.2,8.5,3.2,11.8,0c5.1-4.9,10.8-12.7,8.7-21.7C35.7,8.2,28.6,5.3,23,5.3z"
    />
  </svg>
);
export default LocationOutlinedIcon;
