// src/components/Blocks/YouTubeBlock.js

import React from "react";

// Extract the video ID from the YouTube URL
const extractVideoId = (url) => {
  // Check if the URL is in the format https://www.youtube.com/watch?v=ID
  if (url.includes("youtube.com")) {
    const videoId = url.split("v=")[1];
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      return videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }
  // Check if the URL is in the format https://youtu.be/ID
  else if (url.includes("youtu.be")) {
    const parts = url.split("/");
    let videoId = parts[parts.length - 1];
    const questionMarkPosition = videoId.indexOf("?");
    if (questionMarkPosition !== -1) {
      return videoId.substring(0, questionMarkPosition);
    }
    return videoId;
  }
  // If the URL format is not recognized, return null or an appropriate error message
  else {
    return null;
  }
};

const YouTubeBlock = ({ content, settings }) => {
  // Attempt to extract a video ID from the content URL
  const videoId = content.url ? extractVideoId(content.url) : null;

  // Classes
  // General settings
  //   const generalClasses = generateTailwindClasses(settings);

  // Render conditional after checking existence of parts
  const renderedContent = [];
  if (videoId) {
    renderedContent.push(
      <div
        key="youtube-video"
        className="overflow-hidden pb-[56.25%] relative h-0 rounded-2xl">
        <iframe
          className="left-0 top-0 h-full w-full absolute"
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    );
  }
  // Not applying the generalClasses to the video because it was not appearing
  return <div className={"px-4"}>{renderedContent}</div>;
};

export default YouTubeBlock;
