// src/AppRouter.js

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { navigationURL } from "./constants/navigation";

import { NavigationProvider } from "./context/NavigationContext";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

import HomePage from "./pages/home/HomePage";
import EventsPage from "./pages/events/EventsPage";
import NewsPage from "./pages/news/News";
import AboutPage from "./pages/about/AboutPage";
import CarerSupportPage from "./pages/carer-support/CarerSupportPage";
import SupportingServicesPage from "./pages/carer-support/supporting-services/SupportingServicesPage";
import DiscountsPage from "./pages/carer-support/discounts/DiscountsPage";
import KnowledgeBasePage from "./pages/carer-support/knowledge-base/KnowledgeBasePage";
import LoginPage from "./pages/login/LoginPage";
import JoinPage from "./pages/join-carereps/JoinPage";
import ProfilePage from "./pages/profile/ProfilePage";
import EventDetailsPage from "./pages/events/EventDetailsPage";
import KnowledgeBaseDetailsPage from "./pages/carer-support/knowledge-base/KnowledgeBaseDetailsPage";
import DisclaimerPage from "./pages/disclaimer/DisclaimerPage";
import RegistrationPage from "./pages/registration/RegistrationPage";
import SupportingServicesDetailsPage from "./pages/carer-support/supporting-services/SupportingServicesDetailsPage";
import ScrollToTop from "./components/Utils/ScrollToTop";
import RegistrationSuccessPage from "./pages/registration/RegistrationSuccessPage";
import ProfileEventsPage from "./pages/profile/ProfileEventsPage";
import ProfileBookmarksPage from "./pages/profile/ProfileBookmarksPage";
import DiscountsDetailsPage from "./pages/carer-support/discounts/DiscountsDetailsPage";
import OldMembersLoginPage from "./pages/old-members-login/OldMembersLoginPage";
const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <NavigationProvider>
        <Routes>
          <Route path={navigationURL.HOME} element={<HomePage />} />
          <Route path={navigationURL.EVENTS} element={<EventsPage />} />
          <Route path={navigationURL.EVENT} element={<EventDetailsPage />} />
          <Route path={navigationURL.NEWS} element={<NewsPage />} />
          <Route path={navigationURL.ABOUT} element={<AboutPage />} />
          <Route path={navigationURL.DISCLAIMER} element={<DisclaimerPage />} />
          <Route
            path={navigationURL.CAREREPS_REGISTRATION}
            element={<RegistrationPage />}
          />
          <Route
            path={navigationURL.CAREREPS_REGISTRATION_SUCCESS}
            element={<RegistrationSuccessPage />}
          />
          <Route path={navigationURL.LOGIN} element={<LoginPage />} />
          <Route
            path={navigationURL.OLD_MEMBERS_LOGIN}
            element={<OldMembersLoginPage />}
          />

          <Route path={navigationURL.JOIN_CAREREPS} element={<JoinPage />} />
          {/* Carer Support Route */}
          <Route
            path={navigationURL.CARER_SUPPORT}
            element={<CarerSupportPage />}>
            <Route
              index
              element={<Navigate to={navigationURL.KNOWLEDGE_BASE} replace />}
            />
            <Route
              path={navigationURL.KNOWLEDGE_BASE}
              element={<KnowledgeBasePage />}
            />

            <Route
              path={navigationURL.RESOURCES}
              element={<SupportingServicesPage />}
            />
            <Route path={navigationURL.DISCOUNTS} element={<DiscountsPage />} />
          </Route>
          {/* Article Knowledge route (outside of carer support layout) */}
          <Route
            path={navigationURL.CARER_SUPPORT_KB + "/:id"}
            element={<KnowledgeBaseDetailsPage />}
          />
          {/* Article support route (outside of carer support layout) */}
          <Route
            path={navigationURL.CARER_SUPPORT_RESOURCES + "/:id"}
            element={<SupportingServicesDetailsPage />}
          />
          {/* Discount route (outside of carer support layout) */}
          <Route
            path={navigationURL.CARER_SUPPORT_DISCOUNTS + "/:id"}
            element={<DiscountsDetailsPage />}
          />
          <Route element={<ProtectedRoute />}>
            {/** <Route path="/my-carereps" element={<ProfilePage />} />*/}

            {/* My carer eps */}
            <Route path="/my-carereps" element={<ProfilePage />}>
              <Route
                index
                element={<Navigate to="/my-carereps/events" replace />}
              />
              <Route
                path="/my-carereps/events"
                element={<ProfileEventsPage />}
              />

              <Route
                path="/my-carereps/bookmarks"
                element={<ProfileBookmarksPage />}
              />
            </Route>
          </Route>
        </Routes>
      </NavigationProvider>
    </Router>
  );
};

export default AppRouter;
