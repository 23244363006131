import * as Yup from "yup";

export const getUserValidations = (t) => ({
  phoneValidation: Yup.string()
    .required(t("validations:required"))
    .matches(/^(2|3|5|6|7|8|9)\d{7}$/, t("validations:invalidPhone")),

  membershipId: Yup.string()
    .required(t("validations:carerEPSIdRequired"))
    .max(20, t("validations:invalidCarerEPSId")),

  emailValidation: Yup.string()
    .email(t("validations:invalidEmail"))
    .required(t("validations:required")),

  passwordValidation: Yup.string()
    .required(t("validations:required"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&_]{6,20}$/,
      t("validations:passwordRequirements")
    ),
});
