// src/layouts/ClearLayout.js

import React from "react";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const ClearLayout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col h-screen justify-stretch">
        {/* Main content area */}
        <main className="flex flex-col w-full m-auto flex-grow">
          {children}
        </main>
        <div>
          {/* Scroll to top button */}
          <ScrollToTopButton />
        </div>
      </div>
    </>
  );
};

export default ClearLayout;
