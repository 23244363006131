// src/layouts/MainNoContentLayout.js

import React, { Fragment } from "react";
import NavigationMenu from "../components/NavigationBar/NavigationMenu";
import Footer from "../components/Footer/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const MainNoContentLayout = ({ children }) => {
  return (
    <Fragment>
      <div className="flex flex-col h-screen justify-stretch">
        {/* Navigation Menu */}
        <NavigationMenu />
        {children}
        <div>
          {/* Footer */}
          <Footer />
          {/* Scroll to top button */}
          <ScrollToTopButton />
        </div>
      </div>
    </Fragment>
  );
};

export default MainNoContentLayout;
