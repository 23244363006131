// src/layouts/FullWidthLayout.js

import React, { Fragment } from "react";
import NavigationMenu from "../components/NavigationBar/NavigationMenu";
import Footer from "../components/Footer/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const FullWidthLayout = ({ children }) => {
  return (
    <Fragment>
      <div className="flex flex-col h-screen justify-stretch">
        {/* Navigation Menu */}
        <NavigationMenu />
        {/* Main content area */}
        <main className="flex flex-col w-full flex-grow">{children}</main>
        <div>
          {/* Footer */}
          <Footer />
          {/* Scroll to top button */}
          <ScrollToTopButton />
        </div>
      </div>
    </Fragment>
  );
};

export default FullWidthLayout;
