// src/components/Icons/CalendarOutlinedIcon.js

import React from "react";

const CalendarOutlinedIcon = ({
  color = "#3C4146",
  size = "22",
  className = "",
  alt = "icon",
}) => (
  <svg
    className={className}
    role="img"
    aria-label={alt}
    width={size + "px"} // Ensure size is correctly applied
    height={size + "px"} // Same for height
    viewBox="0 0 46 46" // Adjust viewBox if necessary
    fill={color}
    focusable="false" // Prevent SVG from being focusable in IE
    style={{ outline: "none" }} // CSS to ensure it's not focusable in other browsers
    xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3146 11.0074C14.5298 11.0074 13.8789 10.3566 13.8789 9.5717V3.82879C13.8789 3.04393 14.5298 2.39307 15.3146 2.39307C16.0995 2.39307 16.7504 3.04393 16.7504 3.82879V9.5717C16.7504 10.3566 16.0995 11.0074 15.3146 11.0074Z" />
    <path d="M30.6271 11.0074C29.8423 11.0074 29.1914 10.3566 29.1914 9.5717V3.82879C29.1914 3.04393 29.8423 2.39307 30.6271 2.39307C31.412 2.39307 32.0629 3.04393 32.0629 3.82879V9.5717C32.0629 10.3566 31.412 11.0074 30.6271 11.0074Z" />
    <path d="M16.2698 27.7574C16.0209 27.7574 15.772 27.7 15.5423 27.6043C15.2935 27.5086 15.102 27.3746 14.9106 27.2023C14.566 26.8386 14.3555 26.36 14.3555 25.8431C14.3555 25.5943 14.4129 25.3454 14.5086 25.1157C14.6043 24.886 14.7383 24.6754 14.9106 24.484C15.102 24.3117 15.2935 24.1777 15.5423 24.082C16.2315 23.7948 17.0929 23.948 17.6289 24.484C17.9735 24.8477 18.1841 25.3454 18.1841 25.8431C18.1841 25.958 18.1649 26.092 18.1458 26.226C18.1266 26.3409 18.0884 26.4557 18.0309 26.5706C17.9926 26.6854 17.9352 26.8003 17.8586 26.9151C17.8012 27.0109 17.7055 27.1066 17.6289 27.2023C17.2652 27.5468 16.7675 27.7574 16.2698 27.7574Z" />
    <path d="M22.9729 27.7567C22.724 27.7567 22.4752 27.6993 22.2455 27.6035C21.9966 27.5078 21.8052 27.3738 21.6137 27.2015C21.2692 26.8378 21.0586 26.3592 21.0586 25.8424C21.0586 25.5935 21.116 25.3446 21.2117 25.1149C21.3075 24.8852 21.4415 24.6747 21.6137 24.4832C21.8052 24.3109 21.9966 24.1769 22.2455 24.0812C22.9346 23.7749 23.796 23.9472 24.332 24.4832C24.6766 24.8469 24.8872 25.3446 24.8872 25.8424C24.8872 25.9572 24.8681 26.0912 24.8489 26.2252C24.8298 26.3401 24.7915 26.455 24.734 26.5698C24.6958 26.6847 24.6383 26.7995 24.5618 26.9144C24.5043 27.0101 24.4086 27.1058 24.332 27.2015C23.9683 27.5461 23.4706 27.7567 22.9729 27.7567Z" />
    <path d="M29.6721 27.7567C29.4233 27.7567 29.1744 27.6993 28.9447 27.6035C28.6958 27.5078 28.5044 27.3738 28.313 27.2015C28.2364 27.1058 28.1598 27.0101 28.0832 26.9144C28.0067 26.7995 27.9492 26.6847 27.911 26.5698C27.8535 26.455 27.8152 26.3401 27.7961 26.2252C27.777 26.0912 27.7578 25.9572 27.7578 25.8424C27.7578 25.3446 27.9684 24.8469 28.313 24.4832C28.5044 24.3109 28.6958 24.1769 28.9447 24.0812C29.653 23.7749 30.4953 23.9472 31.0313 24.4832C31.3758 24.8469 31.5864 25.3446 31.5864 25.8424C31.5864 25.9572 31.5673 26.0912 31.5481 26.2252C31.529 26.3401 31.4907 26.455 31.4333 26.5698C31.395 26.6847 31.3376 26.7995 31.261 26.9144C31.2036 27.0101 31.1078 27.1058 31.0313 27.2015C30.6675 27.5461 30.1698 27.7567 29.6721 27.7567Z" />
    <path d="M16.2698 34.4575C16.0209 34.4575 15.772 34.4001 15.5423 34.3044C15.3126 34.2087 15.102 34.0746 14.9106 33.9023C14.566 33.5386 14.3555 33.0409 14.3555 32.5432C14.3555 32.2943 14.4129 32.0455 14.5086 31.8157C14.6043 31.5669 14.7383 31.3563 14.9106 31.1841C15.6189 30.4758 16.9206 30.4758 17.6289 31.1841C17.9735 31.5478 18.1841 32.0455 18.1841 32.5432C18.1841 33.0409 17.9735 33.5386 17.6289 33.9023C17.2652 34.2469 16.7675 34.4575 16.2698 34.4575Z" />
    <path d="M22.9729 34.4575C22.4752 34.4575 21.9775 34.2469 21.6137 33.9023C21.2692 33.5386 21.0586 33.0409 21.0586 32.5432C21.0586 32.2943 21.116 32.0455 21.2117 31.8157C21.3075 31.5669 21.4415 31.3563 21.6137 31.1841C22.322 30.4758 23.6238 30.4758 24.332 31.1841C24.5043 31.3563 24.6383 31.5669 24.734 31.8157C24.8298 32.0455 24.8872 32.2943 24.8872 32.5432C24.8872 33.0409 24.6766 33.5386 24.332 33.9023C23.9683 34.2469 23.4706 34.4575 22.9729 34.4575Z" />
    <path d="M29.6721 34.4577C29.1744 34.4577 28.6767 34.2472 28.313 33.9026C28.1407 33.7303 28.0067 33.5197 27.911 33.2709C27.8152 33.0412 27.7578 32.7923 27.7578 32.5434C27.7578 32.2946 27.8152 32.0457 27.911 31.816C28.0067 31.5671 28.1407 31.3566 28.313 31.1843C28.7533 30.744 29.4233 30.5334 30.0358 30.6674C30.1698 30.6866 30.2847 30.7248 30.3996 30.7823C30.5144 30.8205 30.6293 30.878 30.7441 30.9546C30.8398 31.012 30.9356 31.1077 31.0313 31.1843C31.3758 31.548 31.5864 32.0457 31.5864 32.5434C31.5864 33.0412 31.3758 33.5389 31.0313 33.9026C30.6675 34.2472 30.1698 34.4577 29.6721 34.4577Z" />
    <path d="M39.2445 18.8363H6.70135C5.91649 18.8363 5.26562 18.1854 5.26562 17.4006C5.26562 16.6157 5.91649 15.9648 6.70135 15.9648H39.2445C40.0293 15.9648 40.6802 16.6157 40.6802 17.4006C40.6802 18.1854 40.0293 18.8363 39.2445 18.8363Z" />
    <path d="M30.6302 43.5502H15.3158C8.32863 43.5502 4.30859 39.5302 4.30859 32.543V16.2714C4.30859 9.28419 8.32863 5.26416 15.3158 5.26416H30.6302C37.6174 5.26416 41.6375 9.28419 41.6375 16.2714V32.543C41.6375 39.5302 37.6174 43.5502 30.6302 43.5502ZM15.3158 8.13561C9.84093 8.13561 7.18005 10.7965 7.18005 16.2714V32.543C7.18005 38.0179 9.84093 40.6787 15.3158 40.6787H30.6302C36.1051 40.6787 38.766 38.0179 38.766 32.543V16.2714C38.766 10.7965 36.1051 8.13561 30.6302 8.13561H15.3158Z" />
  </svg>
);
export default CalendarOutlinedIcon;
