// src/pages/home/RegistrationSuccessContent.js

import React from "react";
import ImageWithFallback from "../../components/Image/ImageWithFallback";
import { Link } from "react-router-dom";

const RegistrationSuccessContent = ({ data = null }) => {
  // console.log(data);
  return (
    <main>
      {/* Header */}
      <div className="text-center justify-center flex flex-col gap-2 max-w-2xl mx-auto mt-12">
        <div className="uppercase text-sm font-bold text-slate-400">
          報名表格已提交
          {/** registration form submitted*/}
        </div>
        <div className="leading-tight text-3xl font-bold text-black">
          <h1>
            {/*Thank you! You are one step closer to becoming a CarerEPS member!*/}
            多謝！你已經接近成為CarerEPS會員！
          </h1>
        </div>
      </div>
      <div className="my-12">
        <ImageWithFallback
          src={"/assets/images/application_success.jpg"}
          className="w-[50%] mx-auto"
        />
      </div>
      <div
        className="text-start justify-center flex flex-col text-lg
      gap-8 max-w-3xl mx-auto mt-12 text-slate-700">
        <p>我們已收到你的申請，我們正處理你的申請，請耐心等候。</p>

        <p>
          同時，你可以閱讀照顧者資源部分嘅多個文章，支援服務,
          社區優惠或者查看由我們的機構嘅即將舉行嘅活動。
        </p>

        <div className="flex justify-center my-8">
          <Link
            to={"/carer-support"}
            //   onClick={confirmSubmit}
            className="transition capitalize duration-300 text-base text-center py-3 px-8 rounded-full 
                       bg-primary hover:bg-primary-hover cursor-pointer text-white border-primary hover:border-primary-hover">
            前往照顧者資源頁面
          </Link>
        </div>
      </div>
    </main>
  );
};

export default RegistrationSuccessContent;
