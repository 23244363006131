// src/components/ProtectedRoute/ProtectedRoute.js

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthState } from "../../context/AuthContext";

// Optional: Import a loading indicator component
// import LoadingIndicator from './components/LoadingIndicator';<LoadingIndicator />

const ProtectedRoute = () => {
  const { user, isLoading } = useAuthState();

  if (isLoading) {
    // Show a loading indicator or return null to wait without rendering anything
    return null; // Or return null;
  }

  return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
