// src/components/Events/Session.js

import {
  BellAlertIcon,
  CalendarDaysIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  MapPinIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageWithFallback from "../../components/Image/ImageWithFallback";
import {
  formatEventDate,
  formatEventTime,
  getDaysOrHoursDifference,
} from "../../utils/dateFuncs";
import { Link } from "react-router-dom";

const formatTextWithLineBreaksAndParagraphs = (text) => {
  return text.split("\n").map((paragraph, index) => (
    <p key={index} className="mb-3">
      {paragraph}
    </p>
  ));
};

// session (data)
// handleReserveSeatClick trigger to reserve a seat on this event
// type (session event type) full/section

const Session = ({
  event = {},
  session,
  handleReserveSeatClick,
  type,
  loadingRegistrationInfo,
  registrationInfo,
  checkButtonMessage,
  user,
  classes,
}) => {
  const { t, i18n } = useTranslation();
  // Terms agree checkbox
  const [isAgreeCBChecked, setIsAgreeCBChecked] = useState(false);

  // State to track change in session registration ALLOW
  const [sessionAttendanceStatus, setSessionAttendanceStatus] = useState();

  //console.log("atts", sessionAttendanceStatus);

  // console.log(
  //   "frist",
  //   (sessionAttendanceStatus &&
  //     sessionAttendanceStatus.isAllowed &&
  //     !isAgreeCBChecked) ||
  //     (!sessionAttendanceStatus && !isAgreeCBChecked)
  // );
  // console.log("seconds");

  // Function to check whether the user can register or not to the session
  const getSessionAttendanceStatus = () => {
    if (
      !loadingRegistrationInfo &&
      registrationInfo &&
      registrationInfo.length > 0
    ) {
      const item = registrationInfo.find((sess) => sess.id === session.id);
      if (session) {
        return checkButtonMessage(item);
      } else {
        return null; // Session ID not found
      }
    }
    return null;
  };

  useEffect(() => {
    setSessionAttendanceStatus(getSessionAttendanceStatus());
    // console.log("atten", getSessionAttendanceStatus());
    // eslint-disable-next-line
  }, [registrationInfo]);

  console.log(sessionAttendanceStatus, isAgreeCBChecked);

  return (
    <div className="mt-10 lg:mt-16">
      {/* Session image */}
      {session.image && (
        <div className="flex overflow-hidden rounded-2xl mt-4">
          <ImageWithFallback
            src={session.image}
            alt={session.name}
            className="w-full h-52 sm:h-80 lg:h-96 object-cover object-center"
          />
        </div>
      )}

      {/* Session name */}
      <div className="mt-6 text-3xl font-semibold">{session.name}</div>

      {/* Session Date / Time */}
      <div className="flex flex-row gap-8 items-center mt-4">
        {/* Day */}
        <div className="flex gap-5 items-center">
          <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
          <span className="text-slate-600">
            {formatEventDate(session.startDate, i18n.language)}
          </span>
        </div>
        {/* Time */}
        <div className="flex gap-5 items-center">
          <ClockIcon className="w-7 h-7 text-slate-600" />
          <span className="text-slate-600">
            {formatEventTime(session.startDate, i18n.language) +
              " - " +
              formatEventTime(session.endDate, i18n.language)}
          </span>
        </div>
      </div>

      {/* Session Description */}
      <div className="leading-7 mt-8 text-lg text-slate-600 whitespace-pre-line prose">
        {session.description
          ? formatTextWithLineBreaksAndParagraphs(session.description)
          : null}
      </div>

      {/* Icons listing */}
      <div className="flex flex-col gap-3 mt-7">
        {type === "section" && (
          <Fragment>
            {/* Participants */}
            <div className="flex gap-5 items-center ">
              <UserGroupIcon className="w-7 h-7 text-slate-600" />
              <div>
                <span className="text-slate-700 font-semibold">
                  {session.maxSeats - session.remainingSeats}
                </span>
                <span className="text-slate-500">/{session.maxSeats}</span>
                <span className="text-slate-600 capitalize">
                  {" "}
                  {t("common:generic.seats")}
                </span>
              </div>
            </div>

            {/* Fee */}
            <div className="flex gap-5 items-center">
              <CurrencyDollarIcon className="w-7 h-7 text-slate-600" />
              <div>
                {session.isPaid ? (
                  <Fragment>
                    <span className="text-slate-700 font-normal">
                      {session.price}
                    </span>
                    <span className="text-slate-600"> HKD</span>
                  </Fragment>
                ) : (
                  <span className="text-slate-600 uppercase">
                    {t("common:generic.free")}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        )}

        {/* Location / District */}
        {!session.isOnline ? (
          session.district || session.address ? (
            <div className="flex gap-5 items-start">
              <MapPinIcon className="w-7 h-7 text-slate-600" />
              <div>
                <div className="flex flex-col">
                  <span className="text-slate-700 font-semibold text-lg">
                    {session.district
                      ? i18n.language !== "en"
                        ? session.district.name
                        : session.district.englishName
                      : ""}
                  </span>
                  <span className="text-slate-600">{session.address}</span>
                </div>
              </div>
            </div>
          ) : null
        ) : (
          <div className="flex gap-5 items-start">
            <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
            <div>
              <span className="text-slate-700 font-semibold text-lg capitalize">
                {t("common:events.onlineEvent")}
              </span>
            </div>
          </div>
        )}

        {type === "section" && (
          <Fragment>
            {/* Payment information */}
            {session.isPaid && session.paymentNote && (
              <div className="flex gap-5 items-start">
                <BellAlertIcon className="w-7 h-7 text-slate-600 shrink-0" />
                <div className="flex flex-col gap-2">
                  <span className="text-slate-700 font-semibold text-lg capitalize">
                    {t("common:events.paymentInstructions")}
                  </span>
                  <span className="text-slate-600">{session.paymentNote}</span>
                  {session.offlinePaymentTimeLimit && (
                    <p className="text-slate-800 font-bold">
                      {t("common:events.paymentDeadline", {
                        hours: session.offlinePaymentTimeLimit,
                      })}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>

      {type === "section" && (
        <Fragment>
          {/* Session Registration Section */}
          <div className="mt-8 border bg-slate-200 rounded-2xl overflow-hidden">
            {/* Top section Notification */}
            <div
              className={`text-center py-3  ${
                (!loadingRegistrationInfo &&
                  registrationInfo &&
                  sessionAttendanceStatus &&
                  sessionAttendanceStatus.isAllowed) ||
                //   getSessionAttendanceStatus().isAllowed
                !user
                  ? "bg-primary/10"
                  : ""
              }`}>
              {/*
               * Date has passed, no longer open for registration
               * Upcoming deadline, how many days left for registration
               * If the member is logged in but is not allowed to register:
               ** You are already registered
               ** You have overlapping events
               */}
              <div className="flex items-center gap-3 justify-center">
                {(!loadingRegistrationInfo &&
                  registrationInfo &&
                  sessionAttendanceStatus &&
                  sessionAttendanceStatus.isAllowed) ||
                //   getSessionAttendanceStatus().isAllowed
                !user ? (
                  <Fragment>
                    <ClockIcon className="w-7 h-7 text-primary" />
                    <span className="text-primary font-semibold">
                      {t("common:events.registrationClosesIn")}{" "}
                      {getDaysOrHoursDifference(session.registrationEndDate, t)}
                      , {t("common:events.joinNowBeforeTooLate")}
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ExclamationTriangleIcon className="w-7 h-7 text-slate-600" />
                    <span className="text-slate-600 font-semibold">
                      {/* Show message */}
                      {
                        !loadingRegistrationInfo &&
                          registrationInfo &&
                          sessionAttendanceStatus &&
                          sessionAttendanceStatus.message
                        //getSessionAttendanceStatus().message
                      }
                    </span>
                  </Fragment>
                )}
              </div>
            </div>

            <hr className="h-px bg-primary/20 border-0"></hr>

            {/* Agreement and button */}
            <div
              className={`p-6 flex flex-col transition-all duration-300 ${
                (!loadingRegistrationInfo &&
                  registrationInfo &&
                  sessionAttendanceStatus &&
                  sessionAttendanceStatus.isAllowed &&
                  !isAgreeCBChecked) ||
                (!user && !isAgreeCBChecked) ||
                (!loadingRegistrationInfo &&
                  registrationInfo &&
                  sessionAttendanceStatus &&
                  !sessionAttendanceStatus.isAllowed &&
                  !isAgreeCBChecked)
                  ? "bg-slate-100"
                  : "bg-primary/10"
              }`}>
              {((!loadingRegistrationInfo &&
                registrationInfo &&
                sessionAttendanceStatus &&
                sessionAttendanceStatus.isAllowed) ||
                //getSessionAttendanceStatus().isAllowed
                !user) && (
                <div className="flex">
                  <div className="flex h-6 items-center">
                    <input
                      id={`enableAgree-${session.id}`}
                      onChange={(e) => setIsAgreeCBChecked(e.target.checked)}
                      checked={isAgreeCBChecked}
                      name="enableAgree"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor={`enableAgree-${session.id}`}
                      className="text-base text-gray-500 leading-6">
                      {t("common:events.agreeToTerms")}{" "}
                      <Link to="/" className="underline font-semibold">
                        {t("common:events.termsAndConditions")}
                      </Link>
                    </label>
                  </div>
                </div>
              )}
              <div
                className={
                  (!loadingRegistrationInfo &&
                    registrationInfo &&
                    sessionAttendanceStatus &&
                    sessionAttendanceStatus.isAllowed) ||
                  //getSessionAttendanceStatus().isAllowed
                  !user
                    ? "mt-8"
                    : null
                }>
                <button
                  type="button"
                  disabled={
                    (!loadingRegistrationInfo &&
                      registrationInfo &&
                      sessionAttendanceStatus &&
                      sessionAttendanceStatus.isAllowed &&
                      !isAgreeCBChecked) ||
                    (!user && !isAgreeCBChecked) ||
                    (!loadingRegistrationInfo &&
                      registrationInfo &&
                      sessionAttendanceStatus &&
                      !sessionAttendanceStatus.isAllowed &&
                      !isAgreeCBChecked)
                  }
                  onClick={() => handleReserveSeatClick(session)}
                  className={`transition w-full duration-300 text-lg text-center py-3 px-4 rounded-full 
            ${
              (!loadingRegistrationInfo &&
                registrationInfo &&
                sessionAttendanceStatus &&
                sessionAttendanceStatus.isAllowed &&
                !isAgreeCBChecked) ||
              (!user && !isAgreeCBChecked) ||
              (!loadingRegistrationInfo &&
                registrationInfo &&
                sessionAttendanceStatus &&
                !sessionAttendanceStatus.isAllowed &&
                !isAgreeCBChecked)
                ? "bg-slate-300 text-slate-500"
                : "bg-primary hover:bg-primary-hover text-white border-primary hover:border-primary-hover"
            }
            `}>
                  {t("common:events.reserveSeat")}
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Session;
