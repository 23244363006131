// src/pages/carer-support/KnowledgeBasePage.js

import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { endPoint } from "../../../constants/endPoints";
import axiosInstance from "../../../lib/axiosInstance";
import KnowledgeBaseContent from "./KnowledgeBaseContent";
import { manageServerMessage } from "../../../utils/messageUtils";
import { useCarerTypeState } from "../../../context/CarerTypeContext";
import ReactGA from "react-ga4";

const KnowledgeBasePage = () => {
  const { selectedCarerTypes } = useCarerTypeState();
  const { t, i18n } = useTranslation();
  const memberLanguage = i18n.language;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  //
  const [entity, setEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });

  const itemsPerPage = 10; // Number of items to fetch per request

  //////
  // Fetch entity (knowledge)
  const fetchEntity = async (isNewSearch = false) => {
    // Convert selectedCarerTypes to a comma-separated list if it is not empty
    const carerTypeIdsParam =
      selectedCarerTypes.length > 0 ? selectedCarerTypes.join(",") : undefined;

    try {
      const response = await axiosInstance.get(endPoint.ARTICLES, {
        params: {
          limit: itemsPerPage,
          page: currentPage,
          sort: "DESC",
          order: "publishDate",
          articleType: "knowledge",
          lang: memberLanguage,
          // Add carerTypeIds to the query parameters only if it is defined
          ...(carerTypeIdsParam && { carerTypeIds: carerTypeIdsParam }),
        },
        withCredentials: true,
      });

      // Items fetched
      const fetchedItems = response.data.data.articles;
      const totalItems = response.data.data.pagination.filteredItemsCount;
      const totalPagesItems = response.data.data.pagination.totalPages;

      if (isNewSearch) {
        setEntity(fetchedItems);
      } else {
        // Append when fetching more
        setEntity((prevEntity) => {
          // Filter out any entities that are already in the state
          const uniqueEntity = fetchedItems.filter(
            (entityItem) =>
              !prevEntity.some((prevItem) => prevItem.id === entityItem.id)
          );
          return [...prevEntity, ...uniqueEntity];
        });
      }

      // // set prev filter to assess whether data needs to be fetch next time
      // setPrevFilter(filter);

      // Set the total items (independent of how many have been fetched)
      setTotalItemsCount(totalItems);
      // Set the total number of pages
      setTotalPages(totalPagesItems);
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message ||
          t("errors:fetchError", { entity: t("common:entities.articles") });
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 0);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // First load of events (on mount and when carertype changes)
  useEffect(() => {
    if (!loading && !loadingMore) {
      setLoading(true);

      // On the first fetch or when filter is changed we will not append to existing articles but
      // Replace so we will pass a value to know this is the case
      fetchEntity(true);
    }
    // eslint-disable-next-line
  }, [selectedCarerTypes]);

  // Load more events
  useEffect(() => {
    if (!loadingMore && !loading && currentPage !== 1) {
      // if filter has been changed inbetween loading more, we shall perhaps search based on the new filter?
      // if (!_.isEqual(currentFilter, prevFilter)) {
      //   setLoading(true);
      //   fetchEntity();
      // } else {
      setLoadingMore(true);
      fetchEntity();
      // }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  // Calculate remaining items to load based on current state

  const remainingItems = entity
    ? totalItemsCount - entity.length
    : totalItemsCount;

  // Function to load more items
  const handleLoadMore = () => {
    if (currentPage < totalPages && !loadingMore) {
      setCurrentPage((prevPage) => prevPage + 1); // Increase page count to fetch more items
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:navigationMenu.knowledgeBase")} -{" "}
          {process.env.REACT_APP_TITLE}
        </title>
        <meta name="description" content="This is the Knowledge Base page" />
      </Helmet>

      <KnowledgeBaseContent
        entities={entity}
        loading={loading}
        loadingMore={loadingMore}
        remainingItems={remainingItems}
        serverMessage={serverMessage}
        handleLoadMore={handleLoadMore}
      />
    </Fragment>
  );
};

export default KnowledgeBasePage;
