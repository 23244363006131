// src/pages/home/EventDetailsPage.js

import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import MainLayout from "../../layouts/MainLayout";
import EventDetailsContent from "./EventDetailsContent";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { useAuthState } from "../../context/AuthContext";
import ReactGA from "react-ga4";
import { manageServerMessage } from "../../utils/messageUtils";

const EventDetailsPage = () => {
  const { id } = useParams(); // id of the event from the URL
  const { t } = useTranslation();
  const { user } = useAuthState();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [event, setEvent] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingRegistrationInfo, setLoadingRegistrationInfo] = useState(true);

  // State for members where we collect their ability to register to the event/s on this page
  const [registrationInfo, setRegistrationInfo] = useState(null);

  const fetchRegistrationInfo = async () => {
    ///////
    // We will send back the event / session info
    setLoadingRegistrationInfo(true);
    try {
      //
      // If member is logged in we will fetch even registration information
      // to assess whether there are overlapping events or she is already registered to the events
      if (user && event && Object.keys(event).length > 0) {
        // Determine event type
        const eventType = !event.isMultiSession
          ? "single"
          : event.allowsIndividualRegistration
          ? "section"
          : "full";

        let eventData = [];
        if (eventType === "single") {
          // Single event
          eventData.push({
            id: event.id,
            startDate: event.startDate,
            endDate: event.endDate,
          });
        } else if (event.sessions && event.sessions.length > 0) {
          event.sessions.forEach((session) => {
            eventData.push({
              id: session.id,
              startDate: session.startDate,
              endDate: session.endDate,
            });
          });
        }

        const registrationResponse = await axiosInstance.post(
          `${endPoint.EVENTS_CHECK_ACCESS}`,
          {
            eventData,
            eventType,
          },
          { withCredentials: true }
        );
        // Registration response is an array with event or events [id, allow, message]
        if (
          registrationResponse.data.data &&
          registrationResponse.data.data.length > 0
        ) {
          setRegistrationInfo(registrationResponse.data.data);
        } else {
          console.error("Registration data is not available in the response");
          return;
        }
      }
    } catch (error) {
      console.log(error);
      // console.error("Error fetching events:", error);
      let errorMsg = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMsg = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMsg = t("errors:networkError");
      }
      manageServerMessage(setErrorMessage, errorMsg, "error", 2000);
    } finally {
      setLoadingRegistrationInfo(false);
    }
  };

  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endPoint.EVENTS}/${id}`, {
        withCredentials: true,
      });

      // console.log("server response event fetch:", response.data.data.event);
      const eventResponse = response.data.data.event;
      // Set event state
      setEvent(eventResponse);
    } catch (error) {
      // console.error("Error fetching events:", error);
      let errorMsg = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMsg = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMsg = t("errors:networkError");
      }
      manageServerMessage(setErrorMessage, errorMsg, "error", 2000);
    } finally {
      setLoading(false);
    }
  };

  // fetch event on mount of event id exists
  useEffect(() => {
    if (id) {
      fetchEvent();
    }
    // eslint-disable-next-line
  }, []);

  // fetch registration when event is retrieved
  // this will only run if the user is logged in
  // and will provide additional information regarding
  // the member shall be allowed to register to the event/s
  useEffect(() => {
    if (event) {
      fetchRegistrationInfo();
    }
    // eslint-disable-next-line
  }, [event, user]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {event ? event.name : ""} - {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainLayout>
        <EventDetailsContent
          event={event}
          loading={loading}
          errorMessage={errorMessage}
          registrationInfo={registrationInfo}
          loadingRegistrationInfo={loadingRegistrationInfo}
          user={user}
          setRegistrationInfo={setRegistrationInfo}
        />
      </MainLayout>
    </Fragment>
  );
};

export default EventDetailsPage;
