// src/utils/generateBlockTWClasses.js

const generateTailwindClasses = (settings = {}) => {
  const { layout, style } = settings;
  const classes = [];

  //
  // Style properties
  // Check each style property and add corresponding class if value is defined
  if (style) {
    // Font size
    if (style.fontSize) {
      classes.push(`text-${style.fontSize}`);
    }
    // Font weight
    if (style.fontWeight) {
      classes.push(`font-${style.fontWeight}`);
    }
    // Color
    if (style.color) {
      classes.push(`text-${style.color}`);
    }
    // Color Hover
    if (style.colorHover) {
      classes.push(`hover:text-${style.colorHover}`);
    }
    // Background color
    if (style.backgroundColor) {
      classes.push(`bg-${style.backgroundColor}`);
    }
    // Background color hover
    if (style.backgroundColorHover) {
      classes.push(`hover:bg-${style.backgroundColorHover}`);
    }
    // Rounded corners
    if (style.roundedCorners) {
      classes.push(`rounded-${style.roundedCorners}`);
    }
    // Text Align
    if (style.textAlign) {
      classes.push(`text-${style.textAlign}`);
    }
    // Height
    if (style.height) {
      classes.push(`h-${style.height}`);
    }
    // Height Small of the object (small screens)
    if (style.heightLG) {
      classes.push(`lg:h-${style.heightLG}`);
    }
    // Width
    if (style.width) {
      classes.push(`w-${style.width}`);
    }
  }

  if (layout) {
    // Horizontal centering of the object
    if (layout.align) {
      classes.push(`justify-${layout.align}`);
    }
    // Height of the object
    if (layout.height) {
      classes.push(`lg:h-${layout.height}`);
    }
    // Height LG of the object (lg screens)
    if (layout.heightLG) {
      classes.push(`h-${layout.heightLG}`);
    }
    // Max Width of the object
    if (layout.maxWidth) {
      classes.push(`max-w-${layout.maxWidth}`);
    }
    // Width of the object
    if (layout.width) {
      classes.push(`w-${layout.width}`);
    }

    // Width of the object (lg screens)
    if (layout.widthLG) {
      classes.push(`lg:w-${layout.widthLG}`);
    }
    // Margin X
    if (layout.marginX) {
      classes.push(`mx-${layout.marginX}`);
    }
    // Margin Y
    if (layout.marginY) {
      classes.push(`my-${layout.marginY}`);
    }
    // Margin Top
    if (layout.marginTop) {
      classes.push(`mt-${layout.marginTop}`);
    }
    // Margin Top LG
    if (layout.marginTopLG) {
      classes.push(`lg:mt-${layout.marginTopLG}`);
    }
    // Margin Bottom
    if (layout.marginBottom) {
      classes.push(`mb-${layout.marginBottom}`);
    }
    // Margin Bottom LG
    if (layout.marginBottomLG) {
      classes.push(`lg:mb-${layout.marginBottomLG}`);
    }
    // Padding X
    if (layout.paddingX) {
      classes.push(`px-${layout.paddingX}`);
    }
    // Padding Y
    if (layout.paddingY) {
      classes.push(`py-${layout.paddingY}`);
    }
    // Padding X LG
    if (layout.paddingXLG) {
      classes.push(`lg:px-${layout.paddingXLG}`);
    }
    // Padding Y LG
    if (layout.paddingYLG) {
      classes.push(`lg:py-${layout.paddingYLG}`);
    }
  }

  return classes.join(" ");
};

export default generateTailwindClasses;
