// src/components/Events/Filter.js

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Select from "../Select/Select";
import SmallCard from "../RadioButtons/SmallCard";
import { XCircleIcon } from "@heroicons/react/20/solid";

const EventsFilter = ({
  loading,
  eventFilterDataReady,
  eventFilterData,
  selectedCategory,
  setSelectedCategory,
  selectedOrganization,
  setSelectedOrganization,
  selectedLocation,
  setSelectedLocation,
  selectedDate,
  setSelectedDate,
  handleEventFilterClick,
  selectedMode,
  setSelectedMode,
  handleClearFilter,
  currentFilter,
  prevFilter,
}) => {
  const { t } = useTranslation();

  // Load message before the filter data is ready
  if (!eventFilterDataReady) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  return (
    <Fragment>
      <div className="flex flex-col bg-gray-50 px-6 pt-4 pb-6 rounded-2xl ring-1 ring-inset ring-gray-200 gap-2">
        <div className="flex flex-col justify-between md:flex-row gap-4">
          <Select
            data={eventFilterData.categories}
            selected={selectedCategory}
            setSelected={setSelectedCategory}
            label={t("common:generic.category")}
            className="flex flex-col md:w-56 lg:w-80"
          />
          <Select
            data={eventFilterData.districts}
            selected={selectedLocation}
            setSelected={setSelectedLocation}
            label={t("common:generic.district")}
            className="flex flex-col md:w-56 lg:w-80"
          />
          <Select
            data={eventFilterData.organizations}
            selected={selectedOrganization}
            setSelected={setSelectedOrganization}
            label={t("common:generic.organization")}
            className="flex flex-col md:w-56 lg:w-80"
          />
          <SmallCard
            data={eventFilterData.modes}
            selected={selectedMode}
            setSelected={setSelectedMode}
            label={t("common:events.eventMode")}
            className={"flex flex-col min-w-64"}
          />
        </div>

        <div className="flex flex-col md:flex-row gap-4 md:justify-between md:items-end">
          <SmallCard
            data={eventFilterData.dates}
            selected={selectedDate}
            setSelected={setSelectedDate}
            label={t("common:generic.dates")}
            className={"flex flex-col"}
          />

          <div className="flex flex-col-reverse md:flex-row gap-5">
            {Object.keys(currentFilter).length > 0 && (
              <button
                type="buton"
                disabled={loading}
                onClick={handleClearFilter}
                className="inline-flex items-center justify-center gap-x-1.5 rounded-full cursor-pointer flex-shrink-0 bg-slate-400 pl-2.5 pr-6 py-2 md:py-3 md:h-9 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-slate-400">
                <XCircleIcon
                  className="-ml-1 mr-1 h-7 w-7"
                  aria-hidden="true"
                />
                {t("common:buttons.clearFilter")}
              </button>
            )}
            <button
              type="buton"
              disabled={loading || _.isEqual(currentFilter, prevFilter)}
              onClick={handleEventFilterClick}
              className={`items-center rounded-full 
              ${
                _.isEqual(currentFilter, prevFilter)
                  ? "bg-primary-disabled"
                  : "cursor-pointer bg-primary hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-primary"
              }
              flex-shrink-0  px-9 py-3 md:py-0 md:h-9 text-sm font-semibold text-white shadow-sm `}>
              {t("common:buttons.filter")}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventsFilter;
