// src/pages/home/LoginContent.js

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  ArrowLeftCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { useNavigationState } from "../../context/NavigationContext";
import { navigationURL } from "../../constants/navigation";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { getUserValidations } from "../../utils/validations/userValidations";
import ForgotPasswordModal from "../../components/Modals/ForgotPasswordModal";

const LoginContent = ({ userLogin, inProgress, serverMessage }) => {
  //
  const { lastPage } = useNavigationState();
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { phoneValidation, passwordValidation } = getUserValidations(t); // Get validations with members language

  // Construct Yup schema using individual field validations
  const loginValidationSchema = Yup.object({
    phone: phoneValidation,
    password: passwordValidation,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(loginValidationSchema) });

  const onSubmit = async (data) => {
    // Only submit data if no current request is already inProgress
    if (!inProgress) {
      await userLogin(data);
      // Login logic here
    }
  };

  const handleBackNavigation = (e) => {
    // Prevent the default Link behavior
    e.preventDefault();
    if (lastPage) {
      // Navigate back to the last page
      navigate(lastPage);
    } else {
      // Navigate to the home page as a fallback
      navigate("/");
    }
  };

  const handleOpenForgotPassword = () => {
    setIsOpen(true);
  };

  return (
    <div className="flex min-h-full w-full flex-1">
      <ForgotPasswordModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="relative hidden w-1/2 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/assets/images/login_page_image.jpg"
          alt=""
        />
      </div>
      <div className="flex flex-col flex-grow justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="w-28 mx-auto"
            src={"/assets/logos/carereps_logo_colour.svg"}
            alt="CarerEPS Logo"
          />

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {t("common:login.signInToCarerEPS")}
          </h2>
        </div>

        <div className="mt-10 px-10 sm:px-0 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
            method="POST">
            {/* Phone field */}
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900">
                {t("common:form.phone")}
              </label>
              <div className="mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                  {...register("phone")}
                  required
                  className={`block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6
                  ${errors.phone && " ring-red-400 ring-2"}`}
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-400">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>
            {/* Password field */}
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  {t("common:form.password")}
                </label>
                <div
                  className="font-semibold cursor-pointer text-sm text-primary-hover hover:text-primary hover:underline"
                  onClick={handleOpenForgotPassword}>
                  {t("common:form.forgotPassword")}?
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...register("password")}
                  className={`block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                    errors.password && " ring-red-400 ring-2"
                  }`}
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-400">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            {/* Server message (type:error/success) */}
            {serverMessage.message && (
              <div
                className={`flex flex-row mx-11 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm ${
                  serverMessage.type === "success"
                    ? "bg-green-50 border-green-600"
                    : "bg-red-50 border-red-600"
                } border`}>
                {serverMessage.type === "success" ? (
                  <CheckCircleIcon
                    className="mr-2 h-7 w-7 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="mr-2 h-7 w-7 flex-none text-red-600 "
                    aria-hidden="true"
                  />
                )}
                <span
                  className={`${
                    serverMessage.type === "success"
                      ? "text-green-700"
                      : "text-red-700"
                  } flex-grow text-center`}>
                  {serverMessage.message}
                </span>
              </div>
            )}

            {/* Sign in button */}
            <div>
              <button
                type="submit"
                disabled={inProgress}
                className="flex w-full cursor-pointer justify-center rounded-md bg-primary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                {t("common:buttons.signIn")}
              </button>
            </div>
          </form>

          <p className="mt-7 text-center text-sm text-gray-500">
            {t("common:login.notMember")}

            <Link
              to={navigationURL.JOIN_CAREREPS}
              className="ml-3 font-semibold text-primary-hover hover:text-primary underline">
              {t("common:login.joinCarerEPS")}
            </Link>
          </p>
          {/* Go back button */}
          <div className="w-full justify-center flex mt-20">
            <Link
              to="/"
              onClick={handleBackNavigation}
              className="inline-flex items-center gap-x-1.5 rounded-full bg-slate-500 px-4 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
              <ArrowLeftCircleIcon
                className="-ml-2 h-6 w-6"
                aria-hidden="true"
              />
              {t("common:links.backToCarerEPS")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
