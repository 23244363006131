// src/pages/old-members-login/OldMembersLoginPage.js

import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ClearLayout from "../../layouts/ClearLayout";
import { useTranslation } from "react-i18next";
import { endPoint } from "../../constants/endPoints";
import { useAuthState } from "../../context/AuthContext";
import axiosInstance from "../../lib/axiosInstance";
import OldMembersLoginContent from "./OldMembersLoginContent";
import { manageServerMessage } from "../../utils/messageUtils";
import ReactGA from "react-ga4";

const OldMembersLoginPage = () => {
  const { t } = useTranslation();
  const { user } = useAuthState();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const navigate = useNavigate();

  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });
  const [inProgress, setInProgress] = useState(false);

  const handleRedirect = (delay = 0) => {
    setTimeout(() => {
      const destination = "/login";
      navigate(destination, { replace: true });
    }, delay);
  };

  //////
  // Logs user in
  const createAccount = async (loginData) => {
    manageServerMessage(setServerMessage, "Validating", "saving", 0);
    setInProgress(true);
    try {
      const response = await axiosInstance.post(
        endPoint.VALIDATE_NON_ACTIVE_MEMBER,
        loginData
      );

      if (response.data.status === "success") {
        manageServerMessage(setServerMessage, response.data.message, "success");
        handleRedirect(1200);
      } else {
        // Handle non-success status with proper message
        manageServerMessage(
          setServerMessage,
          response.data.message,
          "error",
          2000
        );
      }
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 2000);
    } finally {
      setInProgress(false);
    }
  };
  // Redirect user if they are already logged in
  useEffect(() => {
    if (user) {
      handleRedirect();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:login.carerEPSAccountActivation")} -{" "}
          {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <ClearLayout>
        <OldMembersLoginContent
          createAccount={createAccount}
          inProgress={inProgress}
          serverMessage={serverMessage}
        />
      </ClearLayout>
    </Fragment>
  );
};

export default OldMembersLoginPage;
