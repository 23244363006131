// src/pages/old-members-login/OldMembersLoginContent.js

import React from "react";
import { useTranslation } from "react-i18next";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { getUserValidations } from "../../utils/validations/userValidations";

const OldMembersLoginContent = ({
  createAccount,
  inProgress,
  serverMessage,
}) => {
  //

  const { t } = useTranslation();

  const { phoneValidation, passwordValidation, membershipId } =
    getUserValidations(t); // Get validations with members language

  // Construct Yup schema using individual field validations
  const loginValidationSchema = Yup.object({
    phone: phoneValidation,
    membershipId: membershipId,
    password: passwordValidation,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(loginValidationSchema) });

  const onSubmit = async (data) => {
    // Only submit data if no current request is already inProgress
    if (!inProgress) {
      await createAccount(data);
    }
  };

  return (
    <div className="flex min-h-full w-full flex-1 bg-gray-50">
      <div className="flex flex-col flex-grow justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="w-28 mx-auto"
            src={"/assets/logos/carereps_logo_colour.svg"}
            alt="CarerEPS Logo"
          />

          <h2 className="mt-10 text-center capitalize text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {t("common:login.carerEPSAccountActivation")}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-6"
              method="POST">
              {/* Phone field */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  {t("common:form.phone")}
                </label>
                <div className="mt-2">
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    {...register("phone")}
                    required
                    className={`block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6
                  ${errors.phone && " ring-red-400 ring-2"}`}
                    placeholder={t("common:form.typePhone")}
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-400">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Org membership */}
              <div>
                <label
                  htmlFor="membershipId"
                  className="block capitalize text-sm font-medium leading-6 text-gray-900">
                  {t("common:form.organizationMembership")}
                </label>
                <div className="mt-2">
                  <input
                    id="membershipId"
                    name="membershipId"
                    {...register("membershipId")}
                    required
                    className={`block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6
                  ${errors.membershipId && " ring-red-400 ring-2"}`}
                    placeholder={t("common:form.typeOrgMembership")}
                  />
                  {errors.membershipId && (
                    <p className="mt-1 text-sm text-red-400">
                      {errors.membershipId.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                {/* Password field */}
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm capitalize font-medium leading-6 text-gray-900">
                    {t("common:form.newPassword")}
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    {...register("password")}
                    className={`block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                      errors.password && " ring-red-400 ring-2"
                    }`}
                    placeholder={t("common:form.typePassword")}
                  />
                  {errors.password && (
                    <p className="mt-1 text-sm text-red-400">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Server message (type:error/success) */}
              {serverMessage.message && !inProgress && (
                <div
                  className={`flex flex-row mt-2 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm ${
                    serverMessage.type === "success"
                      ? "bg-green-50 border-green-600"
                      : "bg-red-50 border-red-600"
                  } border`}>
                  {serverMessage.type === "success" ? (
                    <CheckCircleIcon
                      className="mr-2 h-7 w-7 flex-none text-green-600 "
                      aria-hidden="true"
                    />
                  ) : (
                    <XCircleIcon
                      className="mr-2 h-7 w-7 flex-none text-red-600 "
                      aria-hidden="true"
                    />
                  )}
                  <span
                    className={`${
                      serverMessage.type === "success"
                        ? "text-green-700"
                        : "text-red-700"
                    } flex-grow text-center`}>
                    {serverMessage.message}
                  </span>
                </div>
              )}
              {/* Saving message while saving we will manually set the serverMessage */}
              {inProgress && (
                <div className="flex flex-row mt-2 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm bg-slate-50 border-slate-300 border">
                  <EllipsisHorizontalIcon
                    className="mr-2 h-7 w-7 flex-none text-slate-500 "
                    aria-hidden="true"
                  />

                  <span className="text-slate-600 flex-grow text-center">
                    {serverMessage.message}
                  </span>
                </div>
              )}

              {/* Sign in button */}
              <div>
                <button
                  type="submit"
                  disabled={inProgress}
                  className="flex w-full capitalize cursor-pointer justify-center rounded-md bg-primary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                  {t("common:form.createAccount")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldMembersLoginContent;
