// src/components/Modals/ApplicationDisclaimerModal.js

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";
import { manageServerMessage } from "../../utils/messageUtils";
import { handleRedirect } from "../../utils/navigationUtils";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { useNavigate, Link } from "react-router-dom";

const ApplicationDisclaimerModal = ({
  isOpen,
  setIsOpen,
  classes = "w-full sm:max-w-3xl",
  confirmSubmit,
  serverMessage,
  submitting,
}) => {
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language; // Get language for use in API calls
  const navigate = useNavigate();

  // Pass the user to schema to make sure organization has selection

  // Modal
  const cancelButtonRef = useRef(null);

  // Agree checkbox
  const [isAgreeCBChecked, setIsAgreeCBChecked] = useState(false);

  const handleCloseModel = () => {
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
        // onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 ${classes}`}>
                <div>
                  {/* Header */}
                  <h1 className="p-6 text-center text-primary bg-primary/10 font-medium text-lg capitalize">
                    收集個人資料聲明
                  </h1>
                  <hr className="h-px bg-slate-200/30 border-0"></hr>

                  {/* Info container */}
                  <div className="py-6 px-12 flex flex-col h-96 ">
                    <div className="flex flex-col gap-10 overflow-y-scroll">
                      <div>
                        <span className="text-slate-900 font-semibold text-xl">
                          收集個人資料聲明
                        </span>
                        <p className="text-slate-600 font-normal text-lg mt-2">
                          照顧者易達平台秘書處及所屬成員機構會根據《個人資料(私隱)條例》中所列載的規定，確保儲存的個人資料處理得到充份保護，及有妥善的儲存。為確保你能充分了解向本處收集個人資料的準則，請細閱本聲明。
                        </p>
                      </div>
                      <div>
                        <span className="text-slate-900 font-semibold text-lg">
                          收集資料目的及使用準則
                        </span>
                        <p className="text-slate-600 font-normal text-lg mt-2">
                          秘書處及所屬成員機構可能使用你的個人資料
                          (包括你的姓名、電話、電郵及郵寄地址等)，以便日後與你通訊、處理報名、研究/分析/統計、籌款、收集意見、作活動/訓練課程邀請/推廣用途，及與照顧者易達平台相關之項目事宜。
                          向秘書處及所屬成員機構提供所有個人資料純屬自願，如你未能提供足夠個人資料，可能無法有效處理申請或提供有關服務。
                        </p>
                      </div>
                      <div>
                        <span className="text-slate-900 font-semibold text-lg">
                          注意事項：
                        </span>
                        <p className="text-slate-600 font-normal text-lg mt-2">
                          此申請會交由成員機構浸信會愛羣社會服務處統一處理，有關申請一經審批會盡快通知及安排進行發咭程序。
                          如有查詢，請致電照顧者易達平台秘書處 3188-1633
                          與職員聯絡。
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="h-px bg-slate-200/30 border-0"></hr>
                  <div className="flex px-12 mt-8">
                    <div className="flex h-6 items-center">
                      <input
                        id="enableAgree"
                        onChange={(e) => setIsAgreeCBChecked(e.target.checked)}
                        checked={isAgreeCBChecked}
                        name="enableAgree"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="enableAgree"
                        className="text-base text-gray-500 leading-6">
                        本人在此同意照顧者易達平台秘書處及所屬的成員機構收集個人資料作上述用途。
                      </label>
                    </div>
                  </div>
                  <div className="px-20">
                    {/* Server message (type:error/success) */}
                    {serverMessage.message && !submitting && (
                      <div
                        className={`flex flex-row mt-8 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm ${
                          serverMessage.type === "success"
                            ? "bg-green-50 border-green-600"
                            : "bg-red-50 border-red-600"
                        } border`}>
                        {serverMessage.type === "success" ? (
                          <CheckCircleIcon
                            className="mr-2 h-7 w-7 flex-none text-green-600 "
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="mr-2 h-7 w-7 flex-none text-red-600 "
                            aria-hidden="true"
                          />
                        )}
                        <span
                          className={`${
                            serverMessage.type === "success"
                              ? "text-green-700"
                              : "text-red-700"
                          } flex-grow text-center`}>
                          {serverMessage.message}
                        </span>
                      </div>
                    )}

                    {/* Saving message while saving we will manually set the serverMessage */}
                    {submitting && (
                      <div className="flex flex-row mt-8 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm bg-slate-50 border-slate-400 border">
                        <EllipsisHorizontalIcon
                          className="mr-2 h-7 w-7 flex-none text-slate-600 "
                          aria-hidden="true"
                        />

                        <span className="text-slate-700 flex-grow text-center">
                          {serverMessage.message}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Buttons */}
                  <div className="px-24 py-8 flex gap-8">
                    <button
                      type="button"
                      onClick={handleCloseModel}
                      className="transition w-full duration-300 cursor-pointer text-base text-center py-3 px-4 rounded-full bg-slate-400 text-white">
                      取消
                    </button>

                    <button
                      type="button"
                      disabled={!isAgreeCBChecked}
                      onClick={confirmSubmit}
                      className={`transition w-full capitalize duration-300 text-base text-center py-3 px-4 rounded-full 
                        ${
                          !submitting && isAgreeCBChecked
                            ? "bg-primary hover:bg-primary-hover cursor-pointer text-white border-primary hover:border-primary-hover"
                            : "bg-slate-300 text-slate-400"
                        }
                        `}>
                      接受並提交申請
                      {/** 
                      Accept & Send Application*/}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ApplicationDisclaimerModal;
