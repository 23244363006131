// src/pages/carer-support/knowledge-base/KnowledgeBaseDetailsPage.js

import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import MainLayout from "../../../layouts/MainLayout";
// import EventDetailsContent from "./EventDetailsContent";
import axiosInstance from "../../../lib/axiosInstance";
import { useAuthState } from "../../../context/AuthContext";
import ReactGA from "react-ga4";
import { manageServerMessage } from "../../../utils/messageUtils";
import { endPoint } from "../../../constants/endPoints";
import KnowledgeBaseDetailsContent from "./KnowledgeBaseDetailsContent";
import { useCarerTypeState } from "../../../context/CarerTypeContext";

const KnowledgeBaseDetailsPage = () => {
  const { id } = useParams(); // id of the article from the URL
  const { t, i18n } = useTranslation();
  const { user } = useAuthState();
  const { selectedCarerTypes } = useCarerTypeState();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const memberLanguage = i18n.language;

  const [article, setArticle] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingRecent, setLoadingRecent] = useState(false);

  // Recent articles
  const [recent, setRecent] = useState(null);
  const [recentServerMessage, setRecentServerMessage] = useState({
    message: "",
    type: "",
  });

  const fetchArticle = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endPoint.ARTICLES}/${id}`, {
        withCredentials: true,
      });

      const eventResponse = response.data.data.article;
      // Set event state
      setArticle(eventResponse);
    } catch (error) {
      let errorMsg = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMsg = error.response.data.message || t("errors:serverError");
      } else if (error.request) {
        // The request was made but no response was received
        errorMsg = t("errors:networkError");
      }
      manageServerMessage(setErrorMessage, errorMsg, "error", 2000);
    } finally {
      setLoading(false);
    }
  };

  //////
  // Fetch recent articles for sidebar (we shall make sure this gets into a context to avoid constant calls)
  const fetchEntities = async () => {
    if (loadingRecent) return; // return if already in process and called again
    setLoadingRecent(true);
    // Convert selectedCarerTypes to a comma-separated list if it is not empty
    const carerTypeIdsParam =
      selectedCarerTypes.length > 0 ? selectedCarerTypes.join(",") : undefined;

    try {
      const response = await axiosInstance.get(endPoint.ARTICLES, {
        params: {
          limit: 6,
          page: 1,
          sort: "DESC",
          order: "publishDate",
          articleType: "knowledge",
          lang: memberLanguage,
          // Add carerTypeIds to the query parameters only if it is defined
          ...(carerTypeIdsParam && { carerTypeIds: carerTypeIdsParam }),
        },
        withCredentials: true,
      });

      // Items fetched
      let fetchedItems = response.data.data.articles;

      // Remove the article with the specified ID
      fetchedItems = fetchedItems.filter((article) => article.id !== id);

      // Limit the result to a maximum of 5 entries
      const recentArticles = fetchedItems.slice(0, 5);

      setRecent(recentArticles);
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message ||
          t("errors:fetchError", { entity: t("common:entities.articles") });
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setRecentServerMessage, errorMessage, "error", 0);
    } finally {
      setLoadingRecent(false);
    }
  };

  // fetch event on mount of event id exists
  useEffect(() => {
    if (id) {
      fetchArticle();
    }
    // eslint-disable-next-line
  }, [id]);
  // fetch event on mount of event id exists
  useEffect(() => {
    if (id) {
      fetchEntities(); // fetch recent
    }
    // eslint-disable-next-line
  }, []);

  // // First load of recent articles (on mount and when carertype changes)
  // useEffect(() => {
  //   fetchEntities();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          {article ? article.title : ""} - {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainLayout>
        <KnowledgeBaseDetailsContent
          article={article}
          loading={loading}
          errorMessage={errorMessage}
          user={user}
          recent={recent}
          recentServerMessage={recentServerMessage}
        />
      </MainLayout>
    </Fragment>
  );
};

export default KnowledgeBaseDetailsPage;
