// src/context/CarerTypeContext.js

import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { endPoint } from "../constants/endPoints";
import axiosInstance from "../lib/axiosInstance";

const CarerTypeContext = createContext();

const CarerTypeProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  // Original fetched stated
  const [rawCarerTypeData, setRawCarerTypeData] = useState([]);
  // Localized state
  const [carerTypeData, setCarerTypeData] = useState([]);
  const [lastFetched, setLastFetched] = useState(0);

  const [isLoadingCarerTypeData, setIsLoadingCarerTypeData] = useState(false);
  const [error, setError] = useState(null);

  // State for user selection/s (Multiple carer type might be selected)
  const [selectedCarerTypes, setSelectedCarerTypes] = useState([]); // probably just need array of ids?
  // [{id,name, identifier, description, image},{}]

  // Pass it or them in an array []
  const validateAndSetCarerTypes = (selectedIds) => {
    // Validate selectedIds against carerTypeData here...
    const validIds = selectedIds.filter((id) =>
      carerTypeData.some((carerType) => carerType.id === id)
    );

    // Remove duplicates
    const uniqueValidIds = [...new Set(validIds)];

    setSelectedCarerTypes(uniqueValidIds);
  };

  // Function to simplify and localize data
  const localizeData = (data) =>
    data.map((item) => ({
      id: item.id,
      name: i18n.language === "en" ? item.englishName || item.name : item.name,
      description:
        i18n.language === "en"
          ? item.englishDescription || item.description
          : item.description,
      identifier: item.identifier,
      image: item.image,
    }));

  // Fetch Carer Type Data
  const fetchCarerTypeData = async () => {
    if (!carerTypeData || Date.now() - lastFetched > 3600000) {
      // refresh data if it does not exist or it is older than 1 hour
      setIsLoadingCarerTypeData(true);
      try {
        const response = await axiosInstance.get(endPoint.CARER_TYPES);
        const carerTypes = response.data.data?.carerTypes;

        setRawCarerTypeData(carerTypes);
        setCarerTypeData(localizeData(carerTypes));

        setLastFetched(Date.now());
      } catch (error) {
        console.error("Failed to fetch data:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //  AXIOS ERROR
          setError(error.response.statusText);
        } else if (error.request) {
          // The request was made but no response was received
          setError(t("errors:networkError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(t("errors:unexpectedError"));
        }
      } finally {
        setIsLoadingCarerTypeData(false);
      }
    }
  };

  useEffect(() => {
    // Fetch carer type data when the component mounts
    fetchCarerTypeData();
    // eslint-disable-next-line
  }, []);

  // Keep language updated
  useEffect(() => {
    if (!isLoadingCarerTypeData && carerTypeData && carerTypeData.length > 0) {
      setCarerTypeData(localizeData(rawCarerTypeData));
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  return (
    <CarerTypeContext.Provider
      value={{
        carerTypeData,
        isLoadingCarerTypeData,
        error,
        selectedCarerTypes,
        validateAndSetCarerTypes,
        fetchCarerTypeData,
      }}>
      {isLoadingCarerTypeData ? (
        <div className="flex flex-col text-center justify-center -mt-10 items-center h-screen w-full text-gray-500">
          {t("common:loading")}
        </div>
      ) : (
        children
      )}
    </CarerTypeContext.Provider>
  );
};

const useCarerTypeState = () => {
  const context = useContext(CarerTypeContext);
  if (context === undefined) {
    throw new Error(
      "useCarerTypeContext must be used within a CarerTypeProvider"
    );
  }
  return context;
};

export { CarerTypeProvider, useCarerTypeState };
