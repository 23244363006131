// src/components/Blocks/HighlightsBlock.js

import React from "react";
import { useTranslation } from "react-i18next";

import ImageWithFallback from "../Image/ImageWithFallback";
import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const HighlightsBlock = ({ content, settings }) => {
  const { i18n } = useTranslation();
  // General settings
  const generalClasses = generateTailwindClasses(settings);

  // Render conditional after checking existence of parts
  const renderedContent = content.map((item, index) => {
    // Destructure content item
    const { image, title, paragraph } = item;

    // Prepare classes for each content item
    const imageClasses = generateTailwindClasses(image.settings);
    const titleClasses = generateTailwindClasses(title.settings);
    const paragraphClasses = generateTailwindClasses(paragraph.settings);

    return (
      <div key={index} className="">
        <div className="w-full h-60">
          <ImageWithFallback
            src={image.url}
            alt={title.content}
            className={`object-cover object-center ${imageClasses}`}
          />
        </div>
        <div className={`leading-tight ${titleClasses}`}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? title.contentEnglish || title.content
                  : title.content,
            }}
          />
        </div>
        <div className={paragraphClasses}>
          {i18n.language === "en"
            ? paragraph.contentEnglish || paragraph.content
            : paragraph.content}
        </div>
      </div>
    );
  });

  return (
    <div className={generalClasses}>
      <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {renderedContent}
      </div>
    </div>
  );
};

export default HighlightsBlock;
