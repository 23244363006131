// src/pages/carer-support/knowledge-base/KnowledgeBaseDetailsContent.js

import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { navigationURL } from "../../../constants/navigation";

import { useNavigate } from "react-router-dom";
import ImageWithFallback from "../../../components/Image/ImageWithFallback";
import SectionBlock from "../../../components/Blocks/SectionBlock";
import ArticleCard from "../../../components/Cards/ArticleCard";
import ArticleBookmark from "../../../components/Bookmark/ArticleBookmark";

const KnowledgeBaseDetailsContent = ({
  article,
  loading,
  errorMessage,
  user,
  recent,
  recentServerMessage,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Navigate to events on click
  const handleNavigateBack = () => {
    navigate(navigationURL.CARER_SUPPORT_RESOURCES);
  };

  if (loading) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && !article) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("errors:notFoundError", { entity: t("common:entities.events") })}
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-grow ">
      {/* Navigation */}
      <div className="flex px-1 py-2">
        <button
          type="button"
          className="uppercase text-slate-500 font-medium flex gap-3 items-center"
          onClick={handleNavigateBack}>
          <ChevronLeftIcon className="w-6 h-6 text-slate-500 " />
          {t("common:navigationMenu.knowledgeBase")}
        </button>
      </div>

      {/* Banner */}
      <div className="relative overflow-hidden rounded-2xl mt-4">
        {user && (
          <ArticleBookmark
            classes={"absolute right-4 top-4 z-20"}
            isOn={article.isBookmarked}
            entityId={article.id}
          />
        )}

        <ImageWithFallback
          src={article.image}
          alt={article.title}
          className="w-full h-52 sm:h-80 lg:h-96 object-cover object-top"
        />
      </div>

      {/* Main container (includes event info and summary column) */}
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-6 lg:mt-12">
        {/* Event Information Container */}
        <div className="px-3 lg:px-5 mb-8 flex-grow">
          {/* Category/ies */}
          <div className="flex flex-row gap-3">
            {article.categories.map((cat, index) => (
              <div
                key={index}
                className="uppercase text-slate-500 font-semibold tracking-wide">
                {i18n.language !== "en" ? cat.name : cat.englishName}
              </div>
            ))}

            <span
              className="uppercase inline-flex items-center rounded-full px-3 py-1 
            text-xs font-medium ring-1 ring-inset text-[#3EB959] bg-[#E6FAEC] ring-green-600/20">
              {article.organization ? article.organization.name : ""}
            </span>
          </div>
          {/* Event Name */}
          <div className="font-bold text-4xl mt-1">{article.title}</div>
          {/* Event Description */}
          <div className="leading-7 mt-8 text-lg text-slate-600 whitespace-pre-line prose">
            {article &&
              article.content.map((section, index) => (
                <SectionBlock key={index} section={section} />
              ))}
          </div>
        </div>
        {/* Recent Articles: 最新的文章 container */}
        <div className="w-full lg:w-96 shrink-0 mb-8 px-4 lg:px-0">
          <div className="lg:sticky lg:top-28 overflow-hidden border border-slate-100 bg-slate-100/30 rounded-2xl pb-6">
            {/* Recent articles */}
            <div className="text-left px-6 py-6 font-semibold text-lg  capitalize">
              {t("common:articles.recentResources")}
            </div>
            {/* List of articles */}

            <div className="px-6 gap-8 flex flex-col">
              {recent && recent.length !== 0 ? (
                recent.map((entity) => (
                  <ArticleCard key={entity.id} article={entity} />
                ))
              ) : (
                <span className="mx-auto py-12">
                  {t("common:articles.noRecentResources")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseDetailsContent;
