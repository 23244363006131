// src/components/Blocks/ParagraphBlock.js

import React from "react";
import { useTranslation } from "react-i18next";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const ParagraphBlock = ({ content, settings }) => {
  const { i18n } = useTranslation();

  // Classes
  // General settings
  const generalClasses = generateTailwindClasses(settings);

  // Render conditional after checking existence of parts
  const renderedContent = [];

  if (content.paragraph) {
    const paragraphClasses = generateTailwindClasses(
      content.paragraph.settings
    );
    renderedContent.push(
      <div key="paragraph" className={paragraphClasses}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "en"
                ? content.paragraph.contentEnglish || content.paragraph.content
                : content.paragraph.content,
          }}
        />
      </div>
    );
  }

  return <div className={generalClasses}>{renderedContent}</div>;
};

export default ParagraphBlock;
