// src/components/Bookmark/ArticleBookmark.js

import { BookmarkIcon as BookmarkIconActive } from "@heroicons/react/20/solid";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";
import { useTranslation } from "react-i18next";

// Self manage articlebookmark component
// Call to server to update state of the bookmark

const ArticleBookmark = ({ entityId = null, isOn = false, classes = "" }) => {
  const { t } = useTranslation();
  const [isBookmarked, setIsBookmarked] = useState(isOn);
  const [saving, setSaving] = useState(false);
  const [serverMessage, setServerMessage] = useState({ message: "", type: "" });

  const handleUpdateBookmark = async () => {
    setSaving(true);
    try {
      const response = await axiosInstance.post(
        endPoint.ARTICLES_BOOKMARK + "/" + entityId,
        {},
        {
          withCredentials: true,
        }
      );

      const updatedBookmark = response.data.data?.isBookmarked;

      setIsBookmarked(updatedBookmark);
    } catch (error) {
      let errorMessage = t("errors:unexpectedError");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage =
          error.response.data.message ||
          t("errors:fetchError", { entity: t("common:entities.articles") });
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = t("errors:networkError");
      }
      manageServerMessage(setServerMessage, errorMessage, "error", 0);
    } finally {
      setSaving(false);
    }
  };

  const handleBookmarkClick = async (event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop event propagation
    if (!saving) {
      await handleUpdateBookmark();
    }
  };

  useEffect(() => {
    // Update the isBookmarked if isOn changes
    setIsBookmarked(isOn);
  }, [isOn]);

  if (entityId) {
    return (
      <div className={classes}>
        <button
          type="button"
          onClick={handleBookmarkClick}
          className="bg-white w-9 h-9 rounded-full shadow-md items-center inline-flex justify-center
        transition duration-300 hover:scale-[118%] hover:-translate-y-[1px] ease-in-out
        ">
          {isBookmarked ? (
            <BookmarkIconActive
              className={`w-6 h-6 text-primary ${saving && "animate-pulse"}`}
            />
          ) : (
            <BookmarkIcon
              className={`w-6 h-6 text-primary ${saving && "animate-pulse"}`}
            />
          )}
        </button>
      </div>
    );
  }
};

export default ArticleBookmark;
