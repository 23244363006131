import { Fragment } from "react";
import { useCarerTypeState } from "../../context/CarerTypeContext";
import { useTranslation } from "react-i18next";

const CarerFilterBar = ({ handleCarerFilterButtonClick }) => {
  const { t } = useTranslation();
  const { selectedCarerTypes, carerTypeData } = useCarerTypeState();

  const selectedCarerTypeNames = selectedCarerTypes.map((selectedId) => {
    const carerType = carerTypeData.find(
      (carerType) => carerType.id === selectedId
    );
    return carerType ? carerType.name : "Unknown";
  });

  return (
    <Fragment>
      {/* Carer Selector */}
      <div className="bg-primary/10">
        <div className="flex flex-col w-full max-w-7xl m-auto flex-grow p-3 lg:px-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 pr-4">
              <span className="capitalize text-slate-800 shrink-0">
                {t("common:carerFilter.carerTypeFilter")}
              </span>
              <div className="overflow-auto">
                <ul className="flex gap-3 m-1">
                  {selectedCarerTypeNames.map((name, index) => (
                    <li
                      key={index}
                      className="bg-primary/20 shrink-0 ring-1 ring-primary/50 text-primary font-medium px-3 py-1.5 rounded-md">
                      {name}
                    </li>
                  ))}
                  {selectedCarerTypeNames &&
                    selectedCarerTypeNames.length === 0 && (
                      <li className="bg-primary/20 capitalize shrink-0 ring-1 ring-primary/50 text-primary font-medium px-3 py-1.5 rounded-md">
                        {t("common:carerFilter.allTypes")}
                      </li>
                    )}
                </ul>
              </div>
            </div>
            {/* Selector */}
            <button
              type="button"
              onClick={handleCarerFilterButtonClick}
              className="bg-primary shrink-0 hover:bg-primary-hover text-white focus-visible:outline-primary border-primary hover:border-primary-hover cursor-pointer
    capitalize inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border">
              {t("common:carerFilter.selectCarerType")}
            </button>
          </div>
        </div>
        {/* Separator */}
        <hr className="h-px bg-primary/20  border-0"></hr>
      </div>
    </Fragment>
  );
};

export default CarerFilterBar;
