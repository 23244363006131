// src/components/Blocks/ImageHighlightsButtonBlock.js
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import ImageWithFallback from "../Image/ImageWithFallback";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const ImageHighlightsButtonBlock = ({
  content,
  settings,
  imageLeft = true,
}) => {
  const { i18n } = useTranslation();

  // General settings
  const generalClasses = generateTailwindClasses(settings);

  // Coerce undefined, string, or array into an array
  const ensureArray = (data) => {
    if (!data) return []; // Handle undefined or null
    if (Array.isArray(data)) return data; // Handle arrays
    return [data]; // Wrap string or other values into an array
  };

  // Determine the appropriate content list based on language and the existence of contentEnglish
  const getContentList = (content, language) => {
    let selectedContent = content.content; // Default to the base content

    // Check if contentEnglish exists and is not effectively empty
    const isContentEnglishValid =
      language === "en" &&
      content.contentEnglish &&
      (content.contentEnglish.length > 1 ||
        (content.contentEnglish.length === 1 &&
          content.contentEnglish[0] !== ""));

    if (isContentEnglishValid) {
      selectedContent = content.contentEnglish;
    }

    return ensureArray(selectedContent);
  };

  const contentList = getContentList(content.list, i18n.language);

  return (
    <div className={`mx-auto max-w-2xl lg:max-w-7xl ${generalClasses}`}>
      <div className={`flex flex-col lg:flex-row lg:w-full lg:gap-x-8`}>
        <div
          className={`flex flex-col w-12/12 lg:w-5/12 ${
            !imageLeft && "order-last"
          }`}>
          <ImageWithFallback
            src={content.image.url}
            alt={content.title.content}
            className={`${generateTailwindClasses(
              content.image.settings
            )} object-cover object-center`}
          />
        </div>
        <div
          className={`flex flex-col justify-center w-12/12 lg:w-7/12 px-2 lg:px-0 mt-8 lg:mt-0 ${
            imageLeft ? "lg:pr-12 lg:pl-8" : "lg:pl-12 lg:pr-8"
          }`}>
          <div
            className={`uppercase ${generateTailwindClasses(
              content.subtitle.settings
            )}`}>
            {i18n.language === "en"
              ? content.subtitle.contentEnglish || content.subtitle.content
              : content.subtitle.content}
          </div>
          <div
            className={`leading-tight ${generateTailwindClasses(
              content.title.settings
            )}`}>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "en"
                    ? content.title.contentEnglish || content.title.content
                    : content.title.content,
              }}
            />
          </div>

          {/* Render list if exists */}
          {content.list ? (
            <ul
              className={`gap-3 flex flex-col ${generateTailwindClasses(
                content.list.settings
              )}`}>
              {contentList.map((item, index) => (
                <li key={index} className="flex items-start -ml-0.5">
                  <CheckCircleIcon className="w-6 h-6 mr-2 text-primary shrink-0" />
                  {item}
                </li>
              ))}
            </ul>
          ) : null}
          <div className="flex w-full">
            <Link
              to={content.button.link}
              className={`transition duration-300 ${generateTailwindClasses(
                content.button.settings
              )}`}>
              {i18n.language === "en"
                ? content.button.contentEnglish || content.button.content
                : content.button.content}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageHighlightsButtonBlock;
