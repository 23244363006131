// src/components/RadioButtons/SmallCard.js

import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SmallCard = ({
  data,
  selected,
  setSelected,
  label = "",
  className = "",
  isDisabled = false,
}) => {
  // Convert the initial selected value to a string
  const initialValue = JSON.stringify(selected);

  // Function to handle radio button change
  const handleRadioChange = (value) => {
    // Parse the value back into an object
    setSelected(JSON.parse(value));
  };

  return (
    <div className={className}>
      <RadioGroup value={initialValue} onChange={handleRadioChange}>
        {label ? (
          <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-500 capitalize">
            {label}
          </RadioGroup.Label>
        ) : null}
        <div className="grid gap-3 grid-flow-col mt-1">
          {data.map((option, itemIdx) => (
            <RadioGroup.Option
              key={itemIdx}
              value={JSON.stringify(option)}
              className={({ active, checked }) =>
                classNames(
                  //   active ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                  checked
                    ? "bg-primary text-white"
                    : " ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                  "flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary"
                )
              }
              disabled={isDisabled}
              as="div">
              <RadioGroup.Label as="span" className={"capitalize"}>
                {option.name}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default SmallCard;
