// src/components/Blocks/SectionBlock.js

import generateTailwindClasses from "../../utils/generateBlockTWClasses";
import BlockRenderer from "./BlockRenderer";

const SectionBlock = ({ section, carerModal = null }) => {
  const sectionClasses = generateTailwindClasses(section.settings);
  return (
    <div className={`h-auto flex flex-col ${sectionClasses}`}>
      {section.blocks.map((block, index) => (
        <BlockRenderer key={index} block={block} carerModal={carerModal} />
      ))}
    </div>
  );
};

export default SectionBlock;
