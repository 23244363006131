// src/components/RadioButtons/CarerLevelRadio.js

import React, { Fragment, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/20/solid";
import ImageWithFallback from "../Image/ImageWithFallback";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CarerLevelRadio = ({ className }) => {
  const { t } = useTranslation();
  // Carer Level Types
  const carerLevelTypes = [
    {
      id: 1,
      type: "lost",
      title: t("common:carerModal.lost"),
      image: "/assets/images/carer_lost.png",
    },
    {
      id: 2,
      type: "knowledged",
      title: t("common:carerModal.knowledged"),
      image: "/assets/images/carer_knowledged.png",
    },
  ];

  // State to track selected multisession type selected
  const [selectedLevel, setSelectedLevel] = useState(carerLevelTypes[0].id);

  const handleSelectedLevel = (newValue) => {
    setSelectedLevel(newValue);
  };

  return (
    <RadioGroup
      value={selectedLevel}
      onChange={(newValue) => handleSelectedLevel(newValue)}
      className="grid gap-5 grid-cols-2 ">
      {carerLevelTypes.map((ct) => (
        <RadioGroup.Option
          key={ct.id}
          value={ct.id}
          className={({ checked, active }) =>
            classNames(
              checked
                ? "bg-primary/20 border-primary/60"
                : "bg-white border-slate-200",
              "relative w-44 h-full rounded-xl border cursor-pointer"
            )
          }>
          {({ checked, active }) => (
            <Fragment>
              {/* Image Container */}
              <div className="p-6 flex flex-col">
                <ImageWithFallback
                  src={ct.image}
                  className="object-cover w-full"
                  alt={ct.title}
                />

                <RadioGroup.Label
                  as="span"
                  className="text-gray-900 mt-5 text-sm text-center">
                  {ct.title}
                </RadioGroup.Label>
              </div>

              <div
                className={classNames(
                  !checked ? "invisible" : "",
                  "w-7 h-7 bg-primary rounded-full flex items-center justify-center mr-2 absolute -top-3 -left-3"
                )}>
                <CheckIcon className="text-white w-5 h-5" />
              </div>
            </Fragment>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default CarerLevelRadio;
