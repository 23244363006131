// src/pages/home/EventsPage.js

import React, { useState, useEffect, useContext, Fragment } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import { endPoint } from "../../constants/endPoints";
import _ from "lodash";
// Filtering options Context
import { EventFilterDataContext } from "../../context/EventFilterDataContext";
import EventsContent from "./EventsContent";
import axiosInstance from "../../lib/axiosInstance";
import ReactGA from "react-ga4";

const EventsPage = () => {
  const { t, i18n } = useTranslation();
  const memberLanguage = i18n.language;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  //
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); // To keep track of errors when connecting to the API

  const itemsPerPage = 12; // Number of items to fetch per request

  //
  // Event Dropdown data from Context
  const {
    isLoadingEventFilterData,
    fetchEventFilterData,
    currentFilter,
    eventFilterData,
    eventFilterDataReady,
    selectedCategory,
    setSelectedCategory,
    selectedLocation,
    setSelectedLocation,
    selectedOrganization,
    setSelectedOrganization,
    selectedDate,
    setSelectedDate,
    selectedMode,
    setSelectedMode,
    clearCurrentFilter,
    prevFilter,
    setPrevFilter,
  } = useContext(EventFilterDataContext);

  // Call fetchEventFilterData when the component mounts (one time)
  useEffect(() => {
    if (!isLoadingEventFilterData) {
      fetchEventFilterData();
    }
    // eslint-disable-next-line
  }, []);

  // First load of events (on mount only)
  useEffect(() => {
    if (!loading && !loadingMore) {
      setLoading(true);
      fetchEvents(eventFilterDataReady ? currentFilter : {}, true);
    }
    // eslint-disable-next-line
  }, []);

  // Load more events
  useEffect(() => {
    if (!loadingMore && !loading && currentPage !== 1) {
      // if filter has been changed inbetween loading more, we shall perhaps search based on the new filter?
      if (!_.isEqual(currentFilter, prevFilter)) {
        setLoading(true);
        fetchEvents(currentFilter, true);
      } else {
        setLoadingMore(true);
        fetchEvents(prevFilter);
      }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  //////
  // Fetch events function
  const fetchEvents = async (filter = {}, isNewSearch = false) => {
    // If it's a new search, update the current filter and reset events

    // Important to set the currentPage for this fetch manually
    let cPage = 1;
    // Current displayed events will not disappear from screen while loading unless unset here
    if (isNewSearch) {
      setEvents([]); // Reset events for a new search
      setCurrentPage(1); // Reset to the first page for a new search
      // Current page shall be 1 but it might set after the axios call
    } else {
      cPage = currentPage;
    }

    try {
      const response = await axiosInstance.get(endPoint.EVENTS, {
        params: {
          ...filter,
          limit: itemsPerPage,
          page: cPage,
          sort: "desc",
          order: "startDate",
          lang: memberLanguage,
        },
      });

      // Items fetched
      const fetchedItems = response.data.data.events;
      const totalItems = response.data.data.pagination.filteredItemsCount;
      const totalPagesItems = response.data.data.pagination.totalPages;

      if (isNewSearch) {
        setEvents(response.data.data.events);
      } else {
        // Add unique events to the event state
        setEvents((prevEvents) => {
          const prevIds = new Set(prevEvents.map((item) => item.id));
          const uniqueItems = fetchedItems.filter(
            (item) => !prevIds.has(item.id)
          );
          // Concatenate previous items with the new, unique items
          return [...prevEvents, ...uniqueItems];
        });
      }

      // set prev filter to assess whether data needs to be fetch next time
      setPrevFilter(filter);

      // Set the total items (independent of how many have been fetched)
      setTotalItemsCount(totalItems);
      // Set the total number of pages
      setTotalPages(totalPagesItems);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setErrorMessage(
          t("errors:fetchError", { entity: t("common:entities.events") })
        );
      } else if (error.request) {
        // The request was made but no response was received
        setErrorMessage(t("errors:networkError"));
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorMessage(t("errors:unexpectedError"));
      }
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // Calculate remaining items to load based on current state
  const remainingItems = totalItemsCount - events.length;

  // Function to load more items
  const handleLoadMore = () => {
    if (currentPage < totalPages && !loadingMore) {
      setCurrentPage((prevPage) => prevPage + 1); // Increase page count to fetch more items
    }
  };

  const handleEventFilterSearch = () => {
    // Update the filter state
    if (!loading && !loadingMore && !_.isEqual(currentFilter, prevFilter)) {
      setLoading(true);
      fetchEvents(currentFilter, true);
    }
  };

  // Clears filter and fetched events again if filtering is different than last search
  const handleClearFilter = () => {
    // Check if there's anything to clear and no ongoing requests
    if (!loading && !loadingMore && Object.keys(currentFilter).length > 0) {
      // Always clear the current filter
      clearCurrentFilter();
      // Fetch events if the current filter is different from the previous filter
      // or if the previous filter was not empty (indicating a previous filter was applied)
      if (
        (!_.isEqual(currentFilter, prevFilter) &&
          Object.keys(prevFilter).length > 0) ||
        (_.isEqual(currentFilter, prevFilter) &&
          Object.keys(prevFilter).length > 0)
      ) {
        setLoading(true);
        fetchEvents({}, true);
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:navigationMenu.events")} - {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainLayout>
        {/** */}
        <EventsContent
          events={events}
          handleLoadMore={handleLoadMore}
          loading={loading}
          loadingMore={loadingMore}
          eventFilterDataReady={eventFilterDataReady}
          errorMessage={errorMessage}
          remainingItems={remainingItems}
          eventFilterData={eventFilterData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleEventFilterClick={handleEventFilterSearch}
          selectedMode={selectedMode}
          setSelectedMode={setSelectedMode}
          handleClearFilter={handleClearFilter}
          currentFilter={currentFilter}
          prevFilter={prevFilter}
        />
      </MainLayout>
    </Fragment>
  );
};

export default EventsPage;
