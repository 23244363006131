// src/components/Blocks/TextButtonBlock.js

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const TextButtonBlock = ({ content, settings }) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`flex flex-col lg:flex-row w-full items-center gap-6 ${generateTailwindClasses(
        settings
      )}`}>
      <span className={generateTailwindClasses(content.title.settings)}>
        {i18n.language === "en"
          ? content.title.contentEnglish || content.title.content
          : content.title.content}
      </span>
      <Link
        to={content.button.link}
        className={`transition duration-300 ${generateTailwindClasses(
          content.button.settings
        )}`}>
        {i18n.language === "en"
          ? content.button.contentEnglish || content.button.content
          : content.button.content}
      </Link>
    </div>
  );
};

export default TextButtonBlock;

// `bg-${style.backgroundColor} hover:bg-${style.backgroundColorHover} transition duration-300 rounded-${style.roundedCorners} px-${layout.paddingX} py-${layout.paddingY} text-${style.color} m-${layout.margin} `
