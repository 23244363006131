// src/components/Modals/CarerEPSCardModal.js

import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useTranslation } from "react-i18next";
import ImageWithFallback from "../Image/ImageWithFallback";
import { formatDateNoDayName } from "../../utils/dateFuncs";

const CarerEPSCardModal = ({
  isOpen,
  setIsOpen,
  classes = "w-full sm:max-w-3xl",
  member,
}) => {
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();

  // Pass the user to schema to make sure organization has selection

  // Modal
  const cancelButtonRef = useRef(null);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        // onClose={() => {}}
        onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 ${classes}`}>
                <div>
                  <ImageWithFallback
                    src={"/assets/images/carereps_card.jpg"}
                    className="object-cover h-full w-full"
                  />

                  {/* Info container */}
                  <div className="w-[80%] flex flex-col absolute top-[30%] sm:top-[30%]">
                    <div className="mx-auto flex flex-col sm:gap-3">
                      <span className="text-slate-900 font-semibold text-2xl sm:text-5xl">
                        {member && member.lastName + member.firstName}
                      </span>

                      <div className="flex flex-col -space-y-1 md:space-y-0">
                        {member && member.membershipStartDate && (
                          <div>
                            <span className="text-slate-900 font-semibold text-sm md:text-xl">
                              發咭日期 Issue Date:
                            </span>
                            <span className="text-slate-900 font-semibold text-sm md:text-xl">
                              {" "}
                              {formatDateNoDayName(
                                member.membershipStartDate,
                                i18n.language
                              )}
                            </span>
                          </div>
                        )}
                        {member && member.membershipId && (
                          <div>
                            <span className="text-slate-900 font-semibold text-sm md:text-xl">
                              易達咭編號 ID#:
                            </span>
                            <span className="text-slate-900 font-semibold text-sm md:text-xl">
                              {" "}
                              {member.membershipId}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CarerEPSCardModal;
