// src/pages/carer-support/discounts/DiscountsDetailsContent.js

import {
  AtSymbolIcon,
  ChevronLeftIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";

import { BuildingStorefrontIcon } from "@heroicons/react/20/solid";

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { navigationURL } from "../../../constants/navigation";

import { Link, useNavigate } from "react-router-dom";
import ImageWithFallback from "../../../components/Image/ImageWithFallback";
import SectionBlock from "../../../components/Blocks/SectionBlock";
import DiscountCard from "../../../components/Cards/DiscountCard";

const DiscountsDetailsContent = ({
  vendor,
  loading,
  errorMessage,
  user,
  recent,
  recentServerMessage,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Navigate to events on click
  const handleNavigateBack = () => {
    navigate(navigationURL.CARER_SUPPORT_DISCOUNTS);
  };

  if (loading) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && !vendor) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("errors:notFoundError", { entity: t("common:entities.events") })}
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-grow">
      {/* Navigation */}
      <div className="flex px-1 py-2">
        <button
          type="button"
          className="uppercase text-slate-500 font-medium flex gap-3 items-center"
          onClick={handleNavigateBack}>
          <ChevronLeftIcon className="w-6 h-6 text-slate-500 " />
          {t("common:navigationMenu.discounts")}
        </button>
      </div>

      {/* Banner */}
      <div className="relative overflow-hidden rounded-2xl mt-4">
        <ImageWithFallback
          src={vendor.image}
          alt={vendor.name}
          className="w-full h-52 sm:h-80 lg:h-96 object-cover object-center"
        />
      </div>

      {/* Main container (includes event info and summary column) */}
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-12 mt-6 lg:mt-12">
        {/* Event Information Container */}
        <div className="px-3 lg:px-5 mb-8 flex-grow">
          {/* Category/ies */}
          <div className="flex flex-row gap-3">
            {vendor.categories.map((cat, index) => (
              <div
                key={index}
                className="uppercase text-slate-500 font-semibold tracking-wide">
                {i18n.language !== "en" ? cat.name : cat.englishName}
              </div>
            ))}

            {/* 
              <span
              className="uppercase inline-flex items-center rounded-full px-3 py-1 
            text-xs font-medium ring-1 ring-inset text-[#3EB959] bg-[#E6FAEC] ring-green-600/20">
              {vendor.organization ? vendor.organization.name : ""}
            </span>*/}
          </div>
          {/* Event Name */}
          <div className="font-bold text-4xl mt-1">{vendor.name}</div>
          {/* Event Description */}
          <div className="leading-7 mt-8 text-lg text-slate-600 whitespace-pre-line prose">
            {/** {vendor &&
              vendor.content.map((section, index) => (
                <SectionBlock key={index} section={section} />
              ))}*/}

            {vendor.info ? (
              <div dangerouslySetInnerHTML={{ __html: vendor.info }}></div>
            ) : (
              ""
            )}
          </div>

          {/* Separator */}
          <div>
            <hr className="h-px bg-slate-200 border-0 my-8"></hr>
          </div>

          {/* About merchant */}
          <div className="flex flex-col">
            {/* Merchant info  */}
            <div className="font-semibold capitalize text-3xl mt-1">
              {t("common:discounts.merchantInfo")}
            </div>

            <div className="leading-7 mt-3 text-lg text-slate-600 whitespace-pre-line prose">
              {vendor &&
                vendor.content.map((section, index) => (
                  <SectionBlock key={index} section={section} />
                ))}
            </div>

            {vendor.website && (
              <div className="flex gap-5 mt-4 items-start">
                <GlobeAltIcon className="w-7 h-7 text-slate-600" />
                <div>
                  <span className="text-slate-700 font-semibold text-lg capitalize">
                    <Link to={vendor.website} target="_blank">
                      {t("common:discounts.visitWebsite")}
                    </Link>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Separator */}
          <div>
            <hr className="h-px bg-slate-200 border-0 my-8"></hr>
          </div>

          {/* Location */}
          <div className="flex flex-col">
            <div className="font-semibold capitalize text-3xl mt-1 mb-4">
              {t("common:events.location")}
            </div>
            {vendor.locations &&
              vendor.locations.map((loc, index) => (
                <div key={index} className="mb-10">
                  {/* Location Address */}
                  <div className="flex font-semibold text-2xl mt-4 gap-5">
                    <BuildingStorefrontIcon className="w-7 h-7 text-slate-600" />
                    <span>
                      {loc.name && loc.name}(
                      {loc.district
                        ? i18n.language !== "en"
                          ? loc.district.name
                          : loc.district.englishName
                        : ""}
                      )
                    </span>
                  </div>
                  <div className="uppercase text-lg text-slate-500 font-semibold tracking-wide"></div>
                  <span className="flex gap-5 mt-4 items-start">
                    <MapPinIcon className="w-7 h-7 text-slate-600" />
                    <div>
                      <span className="text-slate-600 font-medium text-lg">
                        {loc.address ? loc.address : ""}
                      </span>
                    </div>
                  </span>
                  {loc.phone && (
                    <span className="flex gap-5 mt-2 items-start">
                      <DevicePhoneMobileIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-600 font-medium text-lg capitalize">
                          {loc.phone ? loc.phone : ""}
                        </span>
                      </div>
                    </span>
                  )}
                  {loc.email && (
                    <span className="flex gap-5 mt-2 items-start">
                      <AtSymbolIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-600 font-medium text-lg">
                          {loc.email ? loc.email : ""}
                        </span>
                      </div>
                    </span>
                  )}

                  {loc.googleMapsEmbedURL && (
                    <iframe
                      title="map"
                      src={loc.googleMapsEmbedURL}
                      className="w-full h-[500px] rounded-2xl  mt-8"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"></iframe>
                  )}
                </div>
              ))}
          </div>
        </div>

        {/* Recent Discounts container */}

        <div className="w-full lg:w-96 shrink-0 mb-8 px-4 lg:px-0">
          <div className="lg:sticky lg:top-28 overflow-hidden border border-slate-100 bg-slate-100/30 rounded-2xl pb-6">
            {/* Recent discounts */}
            <div className="text-left px-6 py-6 font-semibold text-lg  capitalize">
              {t("common:discounts.recent")}
            </div>
            {/* List of articles */}

            <div className="px-6 gap-8 flex flex-col">
              {recent && recent.length !== 0 ? (
                recent.map((entity) => (
                  <DiscountCard key={entity.id} entity={entity} />
                ))
              ) : (
                <span className="mx-auto py-12">
                  {t("common:discounts.noRecentDiscounts")}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountsDetailsContent;
