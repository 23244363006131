// ./src/components/NavigationBar/NavigationMenu.js

import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import MenuButton from "./MenuButton";

import { useNavigate, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { navigationURL, navigationNames } from "../../constants/navigation";

import { useAuthState } from "../../context/AuthContext";
import { useNavigationState } from "../../context/NavigationContext";
import { getPageNameFromURL } from "../../utils/navigationUtils";
import MenuButtonMobile from "./MenuButtonMobile";

/**
 * NavigationMenu component renders the navigation links in the navigation menu.
 *
 * @component
 */
const NavigationMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { currentPage } = useNavigationState();

  const { user } = useAuthState();

  const pageName = getPageNameFromURL(
    currentPage,
    navigationURL,
    navigationNames,
    t,
    "common:navigationMenu."
  );

  const navLinks = [
    {
      id: 0,
      title: t("common:navigationMenu." + navigationNames.HOME),
      icon: "/assets/menuIcons/dashboard_icon.svg",
      url: navigationURL.HOME,
    },
    // {
    //   id: 1,
    //   title: t("common:navigationMenu." + navigationNames.NEWS),
    //   icon: "/assets/menuIcons/pages_icon.svg",
    //   url: navigationURL.NEWS,
    // },
    {
      id: 2,
      title: t("common:navigationMenu." + navigationNames.CARER_SUPPORT),
      icon: "/assets/menuIcons/news_icon.svg",
      url: navigationURL.CARER_SUPPORT,
    },
    {
      id: 3,
      title: t("common:navigationMenu." + navigationNames.EVENTS),
      icon: "/assets/menuIcons/events_icon.svg",
      url: navigationURL.EVENTS,
    },
    {
      id: 4,
      title: t("common:navigationMenu." + navigationNames.ABOUT),
      icon: "/assets/menuIcons/event_orders_icon.svg",
      url: navigationURL.ABOUT,
    },
    {
      id: 5,
      title: t("common:navigationMenu." + navigationNames.JOIN_CAREREPS),
      icon: "/assets/menuIcons/event_orders_icon.svg",
      url: navigationURL.JOIN_CAREREPS,
    },
  ];

  const filledBtnStyle =
    "transition duration-300 bg-[#4DAB6E] text-center hover:bg-[#388754] text-white py-[6px] px-4 rounded-full border-solid border-2 border-[#4DAB6E] hover:border-[#388754]";
  const outlinedBtnStyle =
    "transition duration-300 bg-white text-[#4DAB6E] hover:text-white hover:bg-[#388754] border-solid border-2 border-[#4DAB6E] hover:border-[#388754] py-[6px] px-4 rounded-full";

  const menuHeight = 90; // Menu Heigh in Pixels

  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleRegisterClick = () => {
    navigate("/carereps-registration-form");
  };

  return (
    <Fragment>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white">
                  {/* Top image */}
                  <div className="flex h-16 shrink-0 items-center px-6">
                    <img
                      className="h-8 w-auto"
                      src={"/assets/logos/carereps_logo_colour.svg"}
                      alt="CarerEPS Logo"
                    />
                  </div>
                  {/* Navigation */}
                  <nav className="flex flex-col gap-2">
                    {navLinks.map((link) => (
                      <MenuButtonMobile
                        key={link.id}
                        url={link.url}
                        title={link.title}
                      />
                    ))}
                  </nav>
                  <div>
                    <hr className="h-px my-2 bg-slate-200/80 border-0" />
                  </div>
                  <div className="px-5 ">
                    {!user ? (
                      <div className="flex flex-col gap-2">
                        <Link
                          to="/login"
                          className={`${filledBtnStyle}`}
                          onClick={handleLoginClick}>
                          {t("common:buttons." + navigationNames.LOGIN)}
                        </Link>
                        <button
                          className={`${outlinedBtnStyle}`}
                          onClick={handleRegisterClick}>
                          {t("common:buttons." + navigationNames.REGISTER)}
                        </button>
                      </div>
                    ) : (
                      <Link
                        to="/my-carereps"
                        className={`${filledBtnStyle} flex gap-3`}
                        aria-label="View profile page">
                        <UserCircleIcon
                          className="-ml-2 h-6 w-6"
                          aria-hidden="true"
                        />
                        {t(
                          "common:navigationMenu." + navigationNames.MY_CAREREPS
                        )}
                      </Link>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop menu (top horizontal menu) */}
      <div
        className={`hidden bg-white/90 w-full lg:flex flex-row lg:sticky top-0 overflow-x-auto border-b-2 border-[#4DAB6E] flex-shrink-0 z-50`}
        style={{ height: menuHeight + "px" }}>
        <div className="flex h-full w-full px-10 justify-between items-center">
          <div className="flex h-16 shrink-0 items-center ml-2">
            <img
              className="h-16 w-auto"
              src={"/assets/logos/carereps_logo_colour.svg"}
              alt="CarerEPS Logo"
            />
          </div>
          <nav className="flex h-full flex-shrink-0 justify-center">
            {navLinks.map((link) => (
              <MenuButton key={link.id} url={link.url} title={link.title} />
            ))}
          </nav>
          {!user ? (
            <div className="space-x-3 flex-shrink-0">
              <Link
                to="/login"
                className={`${filledBtnStyle}`}
                onClick={handleLoginClick}>
                {t("common:buttons." + navigationNames.LOGIN)}
              </Link>
              <Link
                to="/carereps-registration-form"
                className={`${outlinedBtnStyle}`}>
                {t("common:buttons." + navigationNames.REGISTER)}
              </Link>
            </div>
          ) : (
            <Link
              to="/my-carereps"
              className={`${filledBtnStyle} flex gap-3`}
              aria-label="View profile page">
              <UserCircleIcon className="-ml-2 h-6 w-6" aria-hidden="true" />
              {t("common:navigationMenu." + navigationNames.MY_CAREREPS)}
            </Link>
          )}
        </div>
      </div>
      {/** Top bar on small devices */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white/90 px-4 py-4 shadow-sm sm:px-6 lg:hidden border-b-2 border-[#4DAB6E]">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-black lg:hidden "
          onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-lg font-medium leading-6 text-gray-700">
          {pageName}
        </div>
      </div>
    </Fragment>
  );
};

export default NavigationMenu;
