// src/components/Events/ReservePopUpContentLogin.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { navigationURL } from "../../constants/navigation";

import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";
import ImageWithFallback from "../Image/ImageWithFallback";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { getUserValidations } from "../../utils/validations/userValidations";

import { useTranslation } from "react-i18next";

const ReservePopUpContentLogin = ({
  classes,
  user,
  setUser,
  setIsOpen,
  cancelButtonRef,
  userLogin,
  inProgress,
  serverMessage,
}) => {
  //
  // id would correspond to either the event id for Single and full registration
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();

  const { phoneValidation, passwordValidation } = getUserValidations(t); // Get validations with members language

  // Construct Yup schema using individual field validations
  const loginValidationSchema = Yup.object({
    phone: phoneValidation,
    password: passwordValidation,
  });

  const [disableButton, setDisableButton] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", resolver: yupResolver(loginValidationSchema) });

  const onSubmit = async (data) => {
    // Only submit data if no current request is already inProgress
    if (!inProgress) {
      setDisableButton(true);
      const result = await userLogin(data);
      // We will set the user here so a login success message can be shown
      if (result.success) {
        setTimeout(() => {
          setDisableButton(false);
          setUser(result.user);
        }, 1500); // 2 seconds
      } else {
        // Parent will already be triggering the error message
        // But we might want to delay clickability until error leaves
        setTimeout(() => {
          setDisableButton(false);
        }, 2000); // 2 seconds
      }
    }
  };

  return (
    <Fragment>
      {/* Banner */}

      <ImageWithFallback
        src={"/assets/images/placeholder.jpg"}
        alt={"Login image"}
        className="w-full h-52 sm:h-64 lg:h-72 object-cover object-top"
      />

      <div className="bg-primary/10 p-6 text-slate-700 text-center">
        {t("common:events.carerEPSMembersOnly")}
      </div>

      {/* Separator */}
      <hr className="h-px bg-primary/20 border-0"></hr>

      {/* Form container */}
      <div className="flex flex-col flex-grow justify-center px-10 py-12 sm:px-6 lg:px-20 xl:px-24">
        <h2 className="text-center text-2xl font-bold text-slate-800 capitalize">
          {t("common:login.memberLogin")}
        </h2>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-8 mb-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
            action="#"
            method="POST">
            {/* Phone field */}
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium leading-6 text-gray-900">
                {t("common:form.phone")}
              </label>
              <div className="mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                  {...register("phone")}
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6
                  ${errors.phone && " ring-red-400 ring-2"}`}
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-400">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>
            {/* Password field */}
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  {t("common:form.password")}
                </label>
                <div className="text-sm">
                  <Link
                    to={navigationURL.FORGOT_PASSWORD}
                    className="font-semibold text-primary-hover hover:text-primary hover:underline">
                    {t("common:form.forgotPassword")}
                  </Link>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...register("password")}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                    errors.password && " ring-red-400 ring-2"
                  }`}
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-400">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            {/* Server message (type:error/success) */}
            {serverMessage.message && (
              <div
                className={`flex flex-row mx-11 justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm ${
                  serverMessage.type === "success"
                    ? "bg-green-50 border-green-600"
                    : "bg-red-50 border-red-600"
                } border`}>
                {serverMessage.type === "success" ? (
                  <CheckCircleIcon
                    className="mr-2 h-7 w-7 flex-none text-green-600 "
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="mr-2 h-7 w-7 flex-none text-red-600 "
                    aria-hidden="true"
                  />
                )}
                <span
                  className={`${
                    serverMessage.type === "success"
                      ? "text-green-700"
                      : "text-red-700"
                  } flex-grow text-center`}>
                  {serverMessage.message}
                </span>
              </div>
            )}

            {/* Sign in button */}
            <div>
              <button
                type="submit"
                disabled={inProgress || disableButton}
                className={`flex transition w-full duration-300 justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold 
                leading-6  shadow-sm focus-visible:outline 
                focus-visible:outline-2 focus-visible:outline-offset-2 
                
                ${
                  inProgress || disableButton
                    ? "bg-slate-300 text-slate-500"
                    : "bg-primary hover:bg-primary-hover text-white border-primary focus-visible:outline-primary hover:border-primary-hover cursor-pointer"
                }
                
                `}>
                {t("common:buttons.signIn")}
              </button>
            </div>
          </form>

          <p className="mt-7 text-center text-sm text-gray-500">
            {t("common:login.notMember")}

            <Link
              to={navigationURL.JOIN_CAREREPS}
              className="ml-3 font-semibold text-primary-hover hover:text-primary underline">
              {t("common:login.joinCarerEPS")}
            </Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default ReservePopUpContentLogin;
