// src/lib/axiosInstance.js

import axios from "axios";
import i18n from "i18next"; // Assuming i18n is configured and exported

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  //baseURL: "http://localhost:3005/api/",
  timeout: 10000,
});

// Automatically attach the Accept-Language header to every request
axiosInstance.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18n.language;
  return config;
});

export default axiosInstance;
