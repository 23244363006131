// src/context/AuthContext.js

import { createContext, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { endPoint } from "../constants/endPoints";
import axiosInstance from "../lib/axiosInstance";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const isLoggedIn =
          localStorage.getItem("isLoggedInCarerEPS") === "true";
        if (isLoggedIn) {
          const res = await axiosInstance.get(endPoint.CHECK_AUTH, {
            withCredentials: true,
          });
          setUser(res.data?.data.user || setUser(null));
        }
      } catch (error) {
        setUser(null);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            // UNAUTHORIZED
            setError(error.response.data?.message);
          } else {
            // ANOTHER AXIOS ERROR
            setError(error.response.statusText);
          }
        } else if (error.request) {
          // The request was made but no response was received
          setError(t("errors:networkError"));
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(t("errors:unexpectedError"));
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, isLoading, error }}>
      {isLoading ? (
        <div className="flex flex-col text-center justify-center -mt-10 items-center h-screen w-full text-gray-500">
          {t("common:loading")}
        </div>
      ) : (
        children
      )}
      {/* Render children only when isUserReady is true <LoadingSpinner />*/}
    </AuthContext.Provider>
  );
};

const useAuthState = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};
export { AuthProvider, useAuthState };
