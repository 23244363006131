// ./src/components/NavigationBar/MenuButtonMobile.js

import { NavLink } from "react-router-dom";
import React from "react";

const MenuButtonMobile = ({ url, title }) => {
  const commonStyles =
    "transition duration-300 px-7 py-3 rounded-lg h-full w-full flex items-center capitalize flex-grow hover:bg-[#EDF2EE] text-slate-900";
  const selectedStyles = "bg-primary text-white hover:bg-primary";

  return (
    <>
      <span className="flex flex-col items-center px-5">
        <NavLink
          to={url}
          className={({ isActive }) =>
            isActive ? `${selectedStyles} ${commonStyles}` : commonStyles
          }>
          {title}
        </NavLink>
      </span>
    </>
  );
};

export default MenuButtonMobile;
