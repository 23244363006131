// ./src/components/Cards/EventCard.js

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatEventDate, formatEventTime } from "../../utils/dateFuncs";

import ImageWithFallback from "../Image/ImageWithFallback";
import { getBubblePosition } from "./Functions";
import CardBubble from "./CardBubble";

import CalendarOutlinedIcon from "../Icons/CalendarOutlinedIcon";
import ClockOutlinedIcon from "../Icons/ClockOutlinedIcon";
import LocationOutlinedIcon from "../Icons/LocationOutlinedIcon";

import { ComputerDesktopIcon } from "@heroicons/react/24/outline";

// event -> contains the event data
// location -> where the eventcard is located (Home page, Events page, User Profile page, ...)

const EventCard = ({ event, location, options = {} }) => {
  //
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const clockIcon = "/assets/icons/clock_outlined_icon.svg";
  const locationIcon = "/assets/icons/location_outlined_icon.svg";

  const defaultOptions = {
    layout: "horizontal", // Default layout
    showOnlineEventBtn: false, // Default visibility for online event button
  };
  const finalOptions = { ...defaultOptions, ...options };

  const [isMultisession, setIsMultisession] = useState(false); // Track if event is multisession

  useEffect(() => {
    if (event) {
      // Check if event is a multisession event
      event.isMultisession ? setIsMultisession(true) : setIsMultisession(false);
    }
  }, [event]);

  const onCardClick = (event, eventId) => {
    event.preventDefault();
    // clickEventTracker("event", location, eventId);
    // Navigate to the new route
    navigate(`/events/${eventId}`);
  };

  const isDarkMode = document.body.classList.contains("dark");

  return (
    <Fragment>
      <article className="shadow-sm border rounded-2xl duration-300 hover:shadow-none overflow-hidden dark:bg-gray-900">
        <Link
          className="flex flex-col h-full"
          to={`/events/${event.id}`}
          onClick={(e) => onCardClick(e, event.id)}>
          {/* Image and Bubbles */}
          <div className="relative h-48 overflow-hidden flex-shrink-0 -z-10">
            {event.bubbles &&
              event.bubbles.length > 0 &&
              event.bubbles.map((bubble, index) => (
                <div
                  key={index}
                  className={`absolute z-10 ${getBubblePosition(
                    bubble.position
                  )}`}>
                  <CardBubble
                    bgColor={bubble.bgColor}
                    text={bubble.text}
                    icon={bubble.icon}
                  />
                </div>
              ))}

            <ImageWithFallback
              src={event.image}
              alt={event.name}
              className="object-cover object-center h-full w-full"
            />
          </div>

          {/* Card content */}
          <div className="p-4 flex flex-col h-full justify-between">
            {/* Title and Description */}
            <div className="flex flex-col">
              <h3 className="text-xl font-medium text-slate-800 dark:text-white line-clamp-2">
                {event.name}
              </h3>
              <p className="text-gray-400 text-base mt-1 dark:text-gray-400 line-clamp-2">
                {event.shortDescription}
              </p>
            </div>

            {/* Details */}
            <div className="flex flex-col">
              <div className="flex items-center mb-1 mt-3">
                <CalendarOutlinedIcon
                  color={isDarkMode ? "#868b93" : "#5d6674"}
                  size="22"
                  className="text-black"
                  alt="Calendar Icon"
                />

                <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                  {event.startDate &&
                    formatEventDate(event.startDate, i18n.language)}
                </p>
              </div>
              <div className="flex items-center mb-1">
                <ClockOutlinedIcon
                  color={isDarkMode ? "#868b93" : "#5d6674"}
                  size="22"
                  className="text-black"
                  alt="Clock Icon"
                />
                <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                  {formatEventTime(event.startDate, i18n.language)} -
                  {formatEventTime(event.endDate, i18n.language)}
                </p>
              </div>
              {event.district && (
                <div className="flex items-center">
                  <LocationOutlinedIcon
                    color={isDarkMode ? "#868b93" : "#5d6674"}
                    size="22"
                    className="text-black"
                    alt="Location Icon"
                  />

                  <p className="ml-3 text-base text-gray-700 line-clamp-1 dark:text-gray-300">
                    {i18n.language !== "en"
                      ? event.district.name
                      : event.district.englishName || ""}
                  </p>
                </div>
              )}
              {event.isOnline && (
                <div className="flex items-center">
                  <ComputerDesktopIcon className="w-[22px] h-[22px] text-[#5d6674]" />

                  <p className="ml-3 text-base text-gray-700 capitalize line-clamp-1 dark:text-gray-300">
                    {t("common:events.onlineEvent")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
      </article>
    </Fragment>
  );
};

export default EventCard;
