// src/components/Cards/CardBubble.js

import React from "react";

// A mapping from icon names to actual icon components
const icons = {
  userIcon: "/assets/icons/user_outlined_icon.svg",
};

const CardBubble = ({ bgColor, text, icon, isSmall = false }) => {
  const hasMultipleText = Array.isArray(text) && text.length > 1;

  return (
    <div
      className={`rounded-full ${
        isSmall ? "px-3" : "px-4"
      } py-1 flex items-center justify-center ${
        bgColor ? `bg-${bgColor}` : "bg-white"
      }`}>
      {icon && (
        <span className="mr-3">
          <img
            className="h-[16px] w-[16px]"
            src={icons[icon]}
            alt="Event Users icon"
          />
        </span>
      )}

      {hasMultipleText ? (
        <div className={`font-normal ${isSmall ? "text-sm" : "text-base"}`}>
          {text.map((textItem, index) => (
            <span
              key={index}
              className={`text-${textItem.color || "gray-500"}`}>
              {textItem.text}
            </span>
          ))}
        </div>
      ) : (
        <span
          className={`font-normal ${isSmall ? "text-sm" : "text-base"} text-${
            text[0].color
          }`}>
          {text[0].text}
        </span>
      )}
    </div>
  );
};

export default CardBubble;

//       bubbles: [
//         {
//           position: "tr",
//           bgColor: "white",
//           icon: EventUsersIcon,
//           text: [
//             { text: "12", color: "gray-800" },
//             { text: " / 30", color: "gray-400" },
//           ],
//         },
//         {
//           position: "tl",
//           bgColor: "eflex-blue",
//           text: [{ text: "NEW", color: "white" }],
//         },
//         {
//           position: "bl",
//           bgColor: "white",
//           text: [{ text: "Multi-session" }],
//         },
//       ],
