// src/pages/registration/RegistrationSuccessPage.js

import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import RegistrationSuccessContent from "./RegistrationSuccessContent";
import MainLayout from "../../layouts/MainLayout";

const RegistrationSuccessPage = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>
          {t("common:navigationMenu.myCarereps")} -{" "}
          {process.env.REACT_APP_TITLE}
        </title>
      </Helmet>
      <MainLayout>
        <RegistrationSuccessContent data={data} />
      </MainLayout>
    </Fragment>
  );
};

export default RegistrationSuccessPage;
