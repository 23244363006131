// src/components/Cards/Functions.js

const getBubblePosition = (position) => {
  switch (position) {
    case "tl":
      return "top-3 left-3 pr-3";
    case "tr":
      return "top-3 right-3 pl-3";
    case "bl":
      return "bottom-3 left-3 pr-3";
    case "br":
      return "bottom-3 right-3 pl-3";
    default:
      return "";
  }
};

export { getBubblePosition };
