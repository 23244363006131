// src/components/Events/ReservePopUpContentSummary.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../lib/axiosInstance";
import { endPoint } from "../../constants/endPoints";
import { manageServerMessage } from "../../utils/messageUtils";
import ImageWithFallback from "../Image/ImageWithFallback";
import {
  BellAlertIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  MapPinIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { formatEventDate, formatEventTime } from "../../utils/dateFuncs";

import { useTranslation } from "react-i18next";

const formatTextWithLineBreaksAndParagraphs = (text) => {
  return text.split("\n").map((paragraph, index) => (
    <p key={index} className="mb-3">
      {paragraph}
    </p>
  ));
};

const ReservePopUpContentSummary = ({
  classes,
  setIsOpen,
  cancelButtonRef,
  event,
  eventType,
  inProgress,
  memberRegistration,
  fallbackImage,
}) => {
  console.log(event);
  //
  // id would correspond to either the event id for Single and full registration
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();

  const [disableButton, setDisableButton] = useState(false);

  // const onSubmit = async (data) => {
  //   // Only submit data if no current request is already inProgress
  //   if (!inProgress) {
  //     setDisableButton(true);
  //     const result = await memberRegistration(data);
  //     // We will set the user here so a login success message can be shown
  //     if (result.success) {
  //       setTimeout(() => {
  //         setDisableButton(false);
  //       }, 1500); // 2 seconds
  //     } else {
  //       // Parent will already be triggering the error message
  //       // But we might want to delay clickability until error leaves
  //       setTimeout(() => {
  //         setDisableButton(false);
  //       }, 2000); // 2 seconds
  //     }
  //   }
  // };

  return (
    <Fragment>
      {/* Banner */}

      <ImageWithFallback
        src={
          event && event.image ? event.image : fallbackImage && fallbackImage
        }
        alt={"Login image"}
        className="w-full h-52 sm:h-64 lg:h-72 object-cover object-center"
      />

      <div className="bg-primary/10 p-6 text-slate-800 text-center uppercase font-semibold">
        {t("common:events.eventSummary")}
      </div>

      <div className="px-4 pb-4 pt-5 sm:p-6">
        <div>
          <div className="mt-3 text-center sm:mt-2">
            <Dialog.Title
              as="h3"
              className="text-xl font-semibold leading-6 text-gray-900">
              {event && event.name}
            </Dialog.Title>
            <div className="mt-2">
              <Fragment>
                {/* Section Registration sections breakdown */}
                {eventType === "full" && (
                  <div className="grid md:grid-cols-2 gap-4 p-10 auto-rows-fr items-start space-y-3 sm:space-y-0">
                    <div className="flex flex-col gap-11">
                      {event.sessions.map((session, index) => (
                        <div
                          key={index}
                          className="items-start inline-flex gap-5">
                          <span className="min-w-11 mt-1 text-center bg-primary/10 text-primary ring-1 ring-inset ring-primary/50 rounded-full py-0.5 px-2.5 text-xs font-medium">
                            S{index + 1}
                          </span>
                          <div className="flex flex-col gap-2">
                            <div className="flex gap-3 items-center">
                              <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
                              <span>
                                {formatEventDate(
                                  session.startDate,
                                  i18n.language
                                )}
                              </span>
                            </div>

                            <div className="flex gap-3 items-center">
                              <ClockIcon className="w-7 h-7 text-slate-600" />
                              <span className="text-slate-600">
                                {formatEventTime(
                                  session.startDate,
                                  i18n.language
                                ) +
                                  " - " +
                                  formatEventTime(
                                    session.endDate,
                                    i18n.language
                                  )}
                              </span>
                            </div>

                            {/* District */}
                            {!session.isOnline ? (
                              (session.district || session.address) && (
                                <div className="flex gap-3 items-center">
                                  <MapPinIcon className="w-7 h-7 text-slate-600" />
                                  <span className="text-slate-800 font-semibold">
                                    {session.district
                                      ? i18n.language !== "en"
                                        ? session.district.name
                                        : session.district.englishName
                                      : ""}
                                  </span>
                                </div>
                              )
                            ) : (
                              <div className="flex gap-5 items-start">
                                <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
                                <div>
                                  <span className="text-slate-700 font-semibold text-lg capitalize">
                                    {t("common:events.onlineEvent")}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Participants */}
                    <div className="flex gap-5 items-center mt-8">
                      <UserGroupIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-700 font-semibold">
                          {event.maxSeats - event.remainingSeats}
                        </span>
                        <span className="text-slate-500">
                          /{event.maxSeats}
                        </span>
                        <span className="text-slate-600 capitalize">
                          {" "}
                          {t("common:generic.seats")}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/****************** */}
                {/* Single event main details */}
                {eventType !== "full" && (
                  <div className="grid md:grid-cols-2 gap-4 p-10 auto-rows-fr items-start space-y-3 sm:space-y-0">
                    {/** <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3"> */}
                    {/* Day */}
                    <div className="flex gap-5 items-center">
                      <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {formatEventDate(event.startDate, i18n.language)}
                      </span>
                    </div>

                    {/* Time */}
                    <div className="flex gap-5 items-center">
                      <ClockIcon className="w-7 h-7 text-slate-600" />
                      <span className="text-slate-600">
                        {formatEventTime(event.startDate, i18n.language) +
                          " - " +
                          formatEventTime(event.endDate, i18n.language)}
                      </span>
                    </div>

                    {/* Participants */}
                    <div className="flex gap-5 items-center">
                      <UserGroupIcon className="w-7 h-7 text-slate-600" />
                      <div>
                        <span className="text-slate-700 font-semibold">
                          {event.maxSeats - event.remainingSeats}
                        </span>
                        <span className="text-slate-500">
                          /{event.maxSeats}
                        </span>
                        <span className="text-slate-600 capitalize">
                          {" "}
                          {t("common:generic.seats")}
                        </span>
                      </div>
                    </div>

                    {/* Location */}
                    {!event.isOnline ? (
                      event.district && (
                        <div className="flex gap-5 items-center">
                          <MapPinIcon className="w-7 h-7 text-slate-600" />
                          <span className="text-slate-600">
                            {event.district
                              ? i18n.language !== "en"
                                ? event.district.name
                                : event.district.englishName
                              : ""}
                          </span>
                        </div>
                      )
                    ) : (
                      <div className="flex gap-5 items-center">
                        <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
                        <span className="text-slate-600 capitalize">
                          {t("common:events.onlineEvent")}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {/* Separator 
                  <hr className="h-px bg-slate-200 border-0"></hr>*/}
                {/* Event Fee Header
                  <div className="flex gap-3 text-center justify-center py-6 font-semibold text-lg bg-white">
                    <CurrencyDollarIcon className="w-7 h-7  text-slate-600" />
                    <span className="capitalize">
                      {t("common:events.eventFee")}
                    </span>
                  </div> */}
                {/* Payment information */}

                <div className="py-6 px-8 mx-8 mb-2 bg-slate-200 ring-1 ring-slate-300 text-lg flex flex-col gap-3 rounded-2xl">
                  <div className="flex gap-5 items-center justify-between">
                    <span className="text-slate-800 font-semibold capitalize">
                      {t("common:events.carerEPSMembers")}
                    </span>
                    <div>
                      {event.isPaid && event.price ? (
                        <Fragment>
                          <span className="text-slate-600">{event.price}</span>
                          <span className="text-slate-600"> HKD</span>
                        </Fragment>
                      ) : (
                        <span className="text-slate-600 capitalize">
                          {t("common:generic.free")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {event.isPaid &&
                (event.paymentNote || event.offlinePaymentTimeLimit) ? (
                  <div className="px-8">
                    {/* Payment Instructions Header */}
                    {!event.paymentNote && !event.offlinePaymentTimeLimit}
                    <div className="flex gap-3 text-center justify-center py-6 font-semibold text-lg bg-white capitalize">
                      <BellAlertIcon className="w-7 h-7 text-slate-600 shrink-0" />
                      {t("common:events.paymentInstructions")}
                    </div>

                    {/* Single event main details */}
                    <div className="p-6 bg-slate-100/80 border border-slate-200 text-lg flex flex-col gap-3 rounded-2xl">
                      {/* Day */}
                      <div className="flex flex-col gap-5 items-center justify-between">
                        {event.paymentNote && (
                          <div className="text-slate-600 font-normal">
                            {formatTextWithLineBreaksAndParagraphs(
                              event.paymentNote
                            )}
                          </div>
                        )}

                        {event.offlinePaymentTimeLimit && (
                          <p className="text-slate-800 font-bold">
                            {t("common:events.paymentDeadline", {
                              hours: event.offlinePaymentTimeLimit,
                            })}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Fragment>
            </div>
          </div>
        </div>

        {/* Reserve button */}
        <div className="px-16 pt-8 pb-2  gap-8 flex flex-row justify-evenly">
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="transition flex-grow flex-shrink-0 duration-300 cursor-pointer text-base text-center py-2.5 px-12  rounded-full bg-slate-400 text-white">
            {t("common:buttons.cancel")}
          </button>
          <button
            type="button"
            disabled={inProgress || disableButton}
            onClick={memberRegistration}
            className={`transition flex-shrink-0 flex-grow duration-300 text-lg text-center py-2.5 px-12 rounded-full 
          ${
            !inProgress || disableButton
              ? "bg-primary hover:bg-primary-hover text-white border-primary hover:border-primary-hover"
              : "bg-slate-300 text-slate-500"
          }
          `}>
            {t("common:events.reserveSeat")}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ReservePopUpContentSummary;

// <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
//           <button
//             type="button"
//             className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
//             onClick={() => setIsOpen(false)}>
//             Deactivate
//           </button>
//           <button
//             type="button"
//             className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
//             onClick={() => setIsOpen(false)}
//             ref={cancelButtonRef}>
//             Cancel
//           </button>
//         </div>

// {eventType && eventType !== "sections" ? (
// ) : (
//   <Fragment>
//     {/* Section Registration sections breakdown */}
//     <div className="p-6 bg-slate-100/40 text-lg flex flex-col gap-3">
//       <div className="flex flex-col gap-11">
//         {event &&
//           event.sessions &&
//           event.sessions.map((session, index) => (
//             <div
//               key={index}
//               className="items-start inline-flex gap-5">
//               <span className="min-w-11 mt-1 text-center bg-primary/10 text-primary ring-1 ring-inset ring-primary/50 rounded-full py-0.5 px-2.5 text-xs font-medium">
//                 S{index + 1}
//               </span>
//               <div className="flex flex-col gap-2">
//                 <div className="flex gap-3 items-center">
//                   <CalendarDaysIcon className="w-7 h-7 text-slate-600" />
//                   <span>
//                     {formatEventDate(
//                       session.startDate,
//                       i18n.language
//                     )}
//                   </span>
//                 </div>

//                 <div className="flex gap-3 items-center">
//                   <ClockIcon className="w-7 h-7 text-slate-600" />
//                   <span className="text-slate-600">
//                     {formatEventTime(
//                       session.startDate,
//                       i18n.language
//                     ) +
//                       " - " +
//                       formatEventTime(
//                         session.endDate,
//                         i18n.language
//                       )}
//                   </span>
//                 </div>

//                 {/* District */}
//                 {!session.isOnline ? (
//                   (session.district || session.address) && (
//                     <div className="flex gap-3 items-center">
//                       <MapPinIcon className="w-7 h-7 text-slate-600" />
//                       <span className="text-slate-800 font-semibold">
//                         {session.district
//                           ? i18n.language !== "en"
//                             ? session.district.name
//                             : session.district.englishName
//                           : ""}
//                       </span>
//                     </div>
//                   )
//                 ) : (
//                   <div className="flex gap-5 items-start">
//                     <ComputerDesktopIcon className="w-7 h-7 text-slate-600" />
//                     <div>
//                       <span className="text-slate-700 font-semibold text-lg capitalize">
//                         {t("common:events.onlineEvent")}
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           ))}
//       </div>
//     </div>
//   </Fragment>
// )}
