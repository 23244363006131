// src/utils/navigationUtils.js

/**
 * Redirects the user to a specified page after an optional delay.
 * If no page is specified or if a null value is passed, redirects to the home page ("/").
 *
 * @param {Function} navigate - The navigate function from useNavigate() hook of react-router-dom.
 * @param {number} [delay=0] - The delay in milliseconds before the redirection is performed. Defaults to 0 for immediate redirection.
 * @param {string|null} [page="/"] - The path to redirect to. If null or undefined, defaults to the home page ("/").
 */
export const handleRedirect = (navigate, delay = 0, page = "/") => {
  setTimeout(() => {
    navigate(page || "/", { replace: true });
  }, delay);
};

/**
 * Retrieves the localized page name corresponding to the given URL.
 * @param {string} url - The URL of the page.
 * @param {Object} navigationURL - Object containing navigation URLs.
 * @param {Object} navigationNames - Object containing navigation names.
 * @param {function} t - The translation function from react-i18next.
 * @param {string} translationPrefix - The prefix for translation keys.
 * @returns {string|null} The localized page name if found, otherwise null.
 */
export const getPageNameFromURL = (
  url,
  navigationURL,
  navigationNames,
  t,
  translationPrefix
) => {
  // Iterate over navigationURL object
  for (const key in navigationURL) {
    if (navigationURL[key] === url) {
      // If the URL matches, return the corresponding page name from navigationNames
      return t(translationPrefix + navigationNames[key]);
    }
  }
  // If URL doesn't match any in navigationURL, return null or handle appropriately
  return null;
};
