// src/utils/messageUtils.js

/**
 * Sets a server message with a specified type and clears it after a delay.
 *
 * This function is designed to manage server messages for user feedback. It accepts a setter function (from useState),
 * a message to display, the type of the message (e.g., 'error', 'success'), and an optional duration after which
 * the message will automatically clear. If no duration is specified, the default is 5000 milliseconds (5 seconds).
 *
 * @param {Function} setter - The setState function from useState hook for setting the server message state.
 * @param {string} message - The message content to be displayed to the user.
 * @param {string} type - The type of the message (commonly 'error' or 'success').
 * @param {number} [duration=5000] - The duration in milliseconds after which the message should clear automatically. Defaults to 5000ms.
 */
export const manageServerMessage = (setter, message, type, duration = 5000) => {
  setter({ message, type });
  // Clear the message after `duration` milliseconds if a duration is provided
  if (duration > 0) {
    setTimeout(() => {
      setter({ message: "", type: "" });
    }, duration);
  }
};
