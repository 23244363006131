// src/components/Blocks/ButtonBlock.js

import { Link } from "react-router-dom";

const ButtonBlock = ({ link, content, settings, carerModal }) => {
  const { style, layout } = settings;

  // Check if link contains "carerModal"
  const isCarerModal = link.includes("carerModal");

  if (isCarerModal && carerModal) {
    return (
      <div className={`flex w-full justify-${layout.align}`}>
        <button
          className={`bg-${style.backgroundColor} hover:bg-${style.backgroundColorHover} transition duration-300 rounded-${style.roundedCorners} px-${layout.paddingX} py-${layout.paddingY} text-${style.color} m-${layout.margin}`}
          onClick={() => carerModal(true)}>
          {content}
        </button>
      </div>
    );
  } else {
    return (
      <div className={`flex w-full justify-${layout.align}`}>
        <Link
          to={link}
          className={`bg-${style.backgroundColor} hover:bg-${style.backgroundColorHover} transition duration-300 rounded-${style.roundedCorners} px-${layout.paddingX} py-${layout.paddingY} text-${style.color} m-${layout.margin}`}>
          {content}
        </Link>
      </div>
    );
  }
};

export default ButtonBlock;
