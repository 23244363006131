// ./src/components/Cards/DiscountCard.js

import { Link, useNavigate } from "react-router-dom";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import ImageWithFallback from "../Image/ImageWithFallback";

import { useAuthState } from "../../context/AuthContext";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { navigationURL } from "../../constants/navigation";

// event -> contains the event data
// location -> where the eventcard is located (Home page, Events page, User Profile page, ...)

const DiscountCard = ({
  entity,
  location,
  options = {},
  to = "/carer-support/discounts",
}) => {
  //
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = useAuthState();

  const clockIcon = "/assets/icons/clock_outlined_icon.svg";
  const locationIcon = "/assets/icons/location_outlined_icon.svg";

  const defaultOptions = {
    layout: "horizontal", // Default layout
    showOnlineEventBtn: false, // Default visibility for online event button
  };
  const finalOptions = { ...defaultOptions, ...options };

  const onCardClick = (event, articleId) => {
    event.preventDefault();
    // clickEventTracker("event", location, eventId);
    // Navigate to the new route

    navigate(`${navigationURL.CARER_SUPPORT_DISCOUNTS}/${articleId}`);
  };

  const isDarkMode = document.body.classList.contains("dark");

  return (
    <Fragment>
      <article className="shadow-sm border rounded-2xl hover:shadow-none bg-white overflow-hidden dark:bg-gray-900">
        <Link
          className="flex flex-col h-full"
          to={`${to}/${entity.id}`}
          //onClick={(e) => onCardClick(e, entity.id)}
        >
          <div className="relative h-48 overflow-hidden flex-shrink-0">
            <span className="absolute capitalize left-4 top-4 z-[5] px-5 py-1 text-white bg-primary rounded-full">
              {entity.discountType === "percentage"
                ? t("common:discounts.percentage", { amount: entity.discount })
                : t("common:discounts.fix", { amount: entity.discount })}
            </span>
            <ImageWithFallback
              src={entity.image}
              alt={entity.name}
              className="object-cover h-full w-full absolute top-0 left-0"
            />
          </div>

          {/* Card content */}
          <div className="p-6 flex flex-col h-full justify-between">
            {/* Title and Description */}
            <div className="flex flex-col">
              <h3 className="text-xl font-medium text-slate-800 dark:text-white line-clamp-2">
                {entity.name}
              </h3>
              <p className="text-gray-600 text-base mt-1 dark:text-gray-400 line-clamp-2">
                {entity.shortDescription}
              </p>
            </div>

            {/* Details */}
            <div className="flex flex-col mt-4">
              {entity.categories && (
                <div className="flex items-center">
                  {entity.categories.map((category, index) => (
                    <p
                      key={index}
                      className="text-base font-medium text-slate-400 line-clamp-1 dark:text-slate-300">
                      {i18n.language === "en"
                        ? category.englishName || category.name
                        : category.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Link>
      </article>
    </Fragment>
  );
};

export default DiscountCard;
