// ./src/components/Cards/ArticleCard.js

import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatEventDate, formatEventTime } from "../../utils/dateFuncs";

import ImageWithFallback from "../Image/ImageWithFallback";
import { getBubblePosition } from "./Functions";
import CardBubble from "./CardBubble";

import CalendarOutlinedIcon from "../Icons/CalendarOutlinedIcon";
import ClockOutlinedIcon from "../Icons/ClockOutlinedIcon";
import LocationOutlinedIcon from "../Icons/LocationOutlinedIcon";
import ArticleBookmark from "../Bookmark/ArticleBookmark";
import { useAuthState } from "../../context/AuthContext";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { navigationURL } from "../../constants/navigation";

// event -> contains the event data
// location -> where the eventcard is located (Home page, Events page, User Profile page, ...)

const ArticleCard = ({
  article,
  location,
  options = {},
  to = "/carer-support/knowledge-base",
}) => {
  //
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = useAuthState();

  const clockIcon = "/assets/icons/clock_outlined_icon.svg";
  const locationIcon = "/assets/icons/location_outlined_icon.svg";

  const defaultOptions = {
    layout: "horizontal", // Default layout
    showOnlineEventBtn: false, // Default visibility for online event button
  };
  const finalOptions = { ...defaultOptions, ...options };

  const onCardClick = (event, articleId) => {
    event.preventDefault();
    // clickEventTracker("event", location, eventId);
    // Navigate to the new route

    navigate(`${navigationURL.CARER_SUPPORT_KB_ARTICLE}/${articleId}`);
  };

  const isDarkMode = document.body.classList.contains("dark");

  return (
    <Fragment>
      <article className="shadow-sm border rounded-2xl hover:shadow-none bg-white overflow-hidden dark:bg-gray-900">
        <Link
          className="flex flex-col h-full"
          to={`${to}/${article.id}`}
          //onClick={(e) => onCardClick(e, article.id)}
        >
          <div className="relative h-48 overflow-hidden flex-shrink-0">
            {user && (
              <ArticleBookmark
                classes={"absolute right-4 top-4 z-20"}
                isOn={article.isBookmarked}
                entityId={article.id}
              />
            )}

            <ImageWithFallback
              src={article.image}
              alt={article.title}
              className="object-cover h-full w-full absolute top-0 left-0"
            />
          </div>

          {/* Card content */}
          <div className="p-4 flex flex-col h-full justify-between">
            {/* Title and Description */}
            <div className="flex flex-col">
              <h3 className="text-xl font-medium text-slate-800 dark:text-white line-clamp-2">
                {article.title}
              </h3>
              <p className="text-gray-400 text-base mt-1 dark:text-gray-400 line-clamp-2">
                {article.shortDescription}
              </p>
            </div>

            {/* Details */}
            <div className="flex flex-col mt-4">
              {article.organization && (
                <div className="flex items-center">
                  <BuildingOfficeIcon className="w-6 h-6 text-slate-700" />

                  <p className="ml-3 text-base font-medium text-slate-600 line-clamp-1 dark:text-slate-300">
                    {article.organization.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
      </article>
    </Fragment>
  );
};

export default ArticleCard;
