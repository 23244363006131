// src/components/Blocks/DividerBlock.js

import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const DividerBlock = ({ content, settings }) => {
  const classes = generateTailwindClasses(settings);
  return <div className={`${classes}`}>{content}</div>;
};

export default DividerBlock;
