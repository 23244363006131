// src/pages/home/AboutContent.js

import React from "react";
import { useTranslation } from "react-i18next";
import SectionBlock from "../../components/Blocks/SectionBlock";

const AboutContent = ({ page, errorMessage, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && errorMessage) {
    return (
      <div className="flex text-center justify-center items-center h-full -mt-10 text-gray-500">
        {errorMessage.message}
      </div>
    );
  }

  return (
    <>
      <div>
        {page &&
          page.content.map((section, index) => (
            <SectionBlock key={index} section={section} />
          ))}
      </div>
    </>
  );
};

export default AboutContent;
