// src/components/Blocks/TextBlock.js

import generateTailwindClasses from "../../utils/generateBlockTWClasses";

const TextBlock = ({ content, settings }) => {
  const classes = generateTailwindClasses(settings);

  return <div className={`${classes}`}>{content}</div>;
};

export default TextBlock;
