// src/context/NavigationContext.js

import { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using react-router-dom for routing

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const location = useLocation(); // Hook from react-router-dom to access the current location

  // Update currentPage and lastPage based on location changes
  useEffect(() => {
    // Check if the current location is different from the current page state
    // to avoid unnecessary updates
    if (location.pathname !== currentPage) {
      setLastPage(currentPage); // Previous currentPage becomes lastPage
      setCurrentPage(location.pathname); // Update currentPage with the new location
    }
    // eslint-disable-next-line
  }, [location]); // Depend on location object, which changes on navigation

  return (
    <NavigationContext.Provider
      value={{ currentPage, lastPage, setCurrentPage, setLastPage }}>
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigationState = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationState must be used within a NavigationProvider"
    );
  }
  return context;
};

export { NavigationProvider, useNavigationState };
