// src/pages/carer-support/knowledge-base/KnowledgeBaseContent.js

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ArticleCard from "../../../components/Cards/ArticleCard";

const KnowledgeBaseContent = ({
  entities,
  loading,
  loadingMore,
  remainingItems,
  serverMessage,
  handleLoadMore,
}) => {
  const { t, i18n } = useTranslation();
  const memberLanguage = i18n.language;
  //

  if (loading) {
    return (
      <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
        {t("common:loading")}
      </div>
    );
  }

  if (!loading && serverMessage && serverMessage.type === "error") {
    return (
      <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
        {serverMessage.message}
      </div>
    );
  }

  if (!loading && entities && entities.length === 0) {
    return (
      <div className="flex text-center justify-center items-center h-full py-10 text-gray-500">
        {t("errors:notFoundError", { entity: t("common:entities.articles") })}
      </div>
    );
  }

  if (!loading && entities && entities.length > 0) {
    return (
      <Fragment>
        <div className="grid gap-5 sm:grid-cols-2 lg:grid-cols-3 mt-2">
          {entities.map((entity) => (
            <ArticleCard key={entity.id} article={entity} />
          ))}
        </div>

        {remainingItems > 0 && !loading && (
          <div className="text-center mt-8">
            <button
              type="buton"
              disabled={loadingMore}
              onClick={handleLoadMore}
              className="rounded-md cursor-pointer bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-primary">
              {t("common:buttons.loadMore")}
            </button>
          </div>
        )}
      </Fragment>
    );
  }
};

export default KnowledgeBaseContent;
