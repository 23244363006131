// src/layouts/MainLayout.js

import React, { Fragment } from "react";
import NavigationMenu from "../components/NavigationBar/NavigationMenu";
import Footer from "../components/Footer/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton/ScrollToTopButton";

const MainLayout = ({ children }) => {
  return (
    <Fragment>
      <div className="flex flex-col h-screen justify-stretch">
        {/* Navigation Menu */}
        <NavigationMenu />
        {/* Main content area */}
        <main className="flex flex-col w-full max-w-7xl m-auto flex-grow p-3 lg:p-5">
          {children}
        </main>
        <div>
          {/* Footer */}
          <Footer />
          {/* Scroll to top button */}
          <ScrollToTopButton />
        </div>
      </div>
    </Fragment>
  );
};

export default MainLayout;
