// src/components/Modals/ChangePasswordModal.js

import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { getUserValidations } from "../../utils/validations/userValidations";

const ChangePasswordModal = ({
  isOpen,
  setIsOpen,
  serverMessage,
  submitting,
  handleOnSubmit,
  classes = "w-full sm:max-w-3xl",
}) => {
  // or the id of the session if eventType (registrationType) is section
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { passwordValidation } = getUserValidations(t); // Get validations with users language

  // Construct Yup schema using individual field validations
  const loginValidationSchema = Yup.object({
    oldPassword: passwordValidation,
    password: passwordValidation,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async (data) => {
    if (!submitting) {
      handleOnSubmit(data);
    }
    // Call parent function to submit the password change
    // Only submit data if no current request is already inProgress
    // if (!inProgress) {
    //   await userLogin(data);
    //   // Login logic here
    // }
  };

  // Modal
  const cancelButtonRef = useRef(null);

  const handleCloseModal = () => {
    reset();
    setIsOpen(false);
  };

  useEffect(() => {
    let timeoutId;
    if (serverMessage.type === "success") {
      // Close modal after 2 seconds
      timeoutId = setTimeout(() => {
        handleCloseModal();
      }, 2000);
    }
    // Cleanup function to clear the timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [serverMessage]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
        // onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 ${classes}`}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className=""
                  action="#"
                  method="POST">
                  {/* Header */}
                  <h1 className="p-6 text-center text-slate-800 font-medium text-xl capitalize">
                    {t("common:form.changePassword")}
                  </h1>

                  <hr className="h-px bg-slate-200/30 border-0"></hr>

                  {/* Info container */}
                  <div className="w-full py-10 flex flex-col justify-center max-w-md mx-auto gap-8">
                    {/* Old Password field */}
                    <div className="flex flex-col">
                      <div>
                        <label
                          htmlFor="oldPassword"
                          className="block text-sm capitalize font-medium leading-6 text-slate-700">
                          {t("common:form.currentPassword")}
                        </label>
                      </div>
                      <div className="mt-1">
                        <input
                          id="oldPassword"
                          name="oldPassword"
                          type="password"
                          autoComplete="current-password"
                          required
                          {...register("oldPassword")}
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.password && " ring-red-400 ring-2"
                          }`}
                        />
                        {errors.oldPassword && (
                          <p className="mt-1 text-sm text-red-400">
                            {errors.oldPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* New Password field */}
                    <div className="flex flex-col">
                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm capitalize font-medium leading-6 text-slate-700">
                          {t("common:form.newPassword")}
                        </label>
                      </div>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="password"
                          required
                          {...register("password")}
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 ${
                            errors.password && " ring-red-400 ring-2"
                          }`}
                        />
                        {errors.password && (
                          <p className="mt-1 text-sm text-red-400">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Server message (type:error/success) */}
                  {serverMessage.message && !submitting && (
                    <div className="px-20 mb-8">
                      <div
                        className={`flex flex-row justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm ${
                          serverMessage.type === "success"
                            ? "bg-green-50 border-green-600"
                            : "bg-red-50 border-red-600"
                        } border`}>
                        {serverMessage.type === "success" ? (
                          <CheckCircleIcon
                            className="mr-2 h-7 w-7 flex-none text-green-600 "
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="mr-2 h-7 w-7 flex-none text-red-600 "
                            aria-hidden="true"
                          />
                        )}
                        <span
                          className={`${
                            serverMessage.type === "success"
                              ? "text-green-700"
                              : "text-red-700"
                          } flex-grow text-center`}>
                          {serverMessage.message}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* Saving message while saving we will manually set the serverMessage */}
                  {submitting && (
                    <div className="px-20 mb-8">
                      <div className="flex flex-row justify-between items-center rounded-3xl pl-2 pr-5 py-1.5 shadow-sm bg-slate-50 border-slate-400 border">
                        <EllipsisHorizontalIcon
                          className="mr-2 h-7 w-7 flex-none text-slate-600 "
                          aria-hidden="true"
                        />

                        <span className="text-slate-700 flex-grow text-center">
                          {serverMessage.message}
                        </span>
                      </div>
                    </div>
                  )}

                  <hr className="h-px bg-slate-200/30 border-0"></hr>

                  {/* Buttons */}
                  <div className="px-24 py-8 flex gap-8">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="transition capitalize w-full duration-300 cursor-pointer text-base text-center py-3 px-4 rounded-full bg-slate-400 text-white">
                      {t("common:buttons.cancel")}
                    </button>

                    <button
                      type="submit"
                      disabled={submitting}
                      //   onClick={() => handleSubmit()}
                      className={`transition w-full capitalize duration-300 text-base text-center py-3 px-4 rounded-full 
                        ${
                          !submitting
                            ? "bg-primary hover:bg-primary-hover cursor-pointer text-white border-primary hover:border-primary-hover"
                            : "bg-slate-300 text-slate-400"
                        }
                        `}>
                      {t("common:buttons.confirm")}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChangePasswordModal;
