// src/components/Checkboxes/GraphicCheckboxes.js

import { CheckIcon } from "@heroicons/react/20/solid";
import ImageWithFallback from "../Image/ImageWithFallback";

const GraphicCheckbox = ({ id, label, checked, onChange, imageSrc }) => {
  return (
    <label htmlFor={id} className="flex flex-col items-center cursor-pointer">
      <div
        className={`relative w-44 h-full rounded-xl border
      ${
        checked
          ? "bg-primary/20 border-primary/60"
          : "bg-white border-slate-200"
      }`}>
        <input
          id={id}
          type="checkbox"
          className="hidden"
          checked={checked}
          onChange={onChange}
        />
        {/* Checked mark */}

        {checked && (
          <div className="w-7 h-7 bg-primary rounded-full flex items-center justify-center mr-2 absolute -top-3 -left-3">
            <CheckIcon className="text-white w-5 h-5" />
          </div>
        )}

        {/* Image Container */}
        <div className="p-6 flex flex-col">
          {imageSrc && (
            <ImageWithFallback
              src={imageSrc}
              className="object-cover w-full"
              alt={label}
            />
          )}
          <span className="text-gray-900 mt-5 text-sm text-center">
            {label}
          </span>
        </div>
      </div>
    </label>
  );
};

export default GraphicCheckbox;

// export default function GraphicCheckboxes() {
//     const [checkedItems, setCheckedItems] = useState([]);

//     const handleCheckboxChange = (label) => {
//       if (checkedItems.includes(label)) {
//         setCheckedItems(checkedItems.filter((item) => item !== label));
//       } else {
//         setCheckedItems([...checkedItems, label]);
//       }
//     };

//     return (
//       <div className="flex flex-col space-y-2">
//         <Checkbox
//           label="Option 1"
//           checked={checkedItems.includes("Option 1")}
//           onChange={() => handleCheckboxChange("Option 1")}
//           imageSrc="https://via.placeholder.com/50"
//         />
//         <Checkbox
//           label="Option 2"
//           checked={checkedItems.includes("Option 2")}
//           onChange={() => handleCheckboxChange("Option 2")}
//           imageSrc="https://via.placeholder.com/50"
//         />
//         <Checkbox
//           label="Option 3"
//           checked={checkedItems.includes("Option 3")}
//           onChange={() => handleCheckboxChange("Option 3")}
//           imageSrc="https://via.placeholder.com/50"
//         />
//       </div>
//     );
//   }
