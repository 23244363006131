// Navigation URLS
const navigationURL = {
  HOME: "/",
  EVENTS: "/events",
  EVENT: "/events/:id",
  CARER_SUPPORT: "/carer-support",
  KNOWLEDGE_BASE: "knowledge-base",
  RESOURCES: "resources",
  DISCOUNTS: "discounts",
  NEWS: "/news",

  CAREREPS_REGISTRATION: "/carereps-registration-form",
  CAREREPS_REGISTRATION_SUCCESS: "/carereps-registration-form/success",

  CARER_SUPPORT_KB: "/carer-support/knowledge-base",
  CARER_SUPPORT_RESOURCES: "/carer-support/resources",
  CARER_SUPPORT_DISCOUNTS: "/carer-support/discounts",

  SUPPORTING_SERVICES: "/carer-support/supporting-services",
  ABOUT: "/about-carereps",
  DISCLAIMER: "/privacy-policy",
  LOGIN: "/login",
  OLD_MEMBERS_LOGIN: "/old-members-login",
  FORGOT_PASSWORD: "/forgot-password",
  JOIN_CAREREPS: "/join-carereps",
  MY_CAREREPS: "/my-carereps",
  MY_CAREREPS_EVENTS: "/my-carereps/events",
  MY_CAREREPS_BOOKMARKS: "/my-carereps/bookmarks",
};
// Navigation English Names
const navigationNames = {
  HOME: "home",
  EVENTS: "events",
  CARER_SUPPORT_KB: "knowledgeBase",
  CARER_SUPPORT_RESOURCES: "supportingServices",
  CARER_SUPPORT_DISCOUNTS: "discounts",
  CARER_SUPPORT: "carerSupport",
  KNOWLEDGE_BASE: "knowledgeBase",
  NEWS: "news",
  DISCOUNTS: "discounts",
  SUPPORTING_SERVICES: "supportingServices",
  ABOUT: "about",
  JOIN_CAREREPS: "joinCarerEPS",
  LOGIN: "login",
  REGISTER: "register",
  MY_CAREREPS: "myCarereps",
  MY_CAREREPS_EVENTS: "myCarereps",
  MY_CAREREPS_BOOKMARKS: "myCarereps",
  CAREREPS_REGISTRATION: "register",
};

export { navigationURL, navigationNames };
